import { createSlice } from "@reduxjs/toolkit";
import Helper from "../helper/axiosHelper";
const baseUrl = Helper.baseUrl();

const initialState = {
  //   firstSignUp: {},
};

export const coursesSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
});

export const { firstSignUp } = coursesSlice.actions;

export default coursesSlice.reducer;

export const categoryList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "categories").then(
      (response) => response.data
    );
    callback(result);
  };

export const listOfRelatedCourses =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + `courses/list_of_related_courses/${data}`
    ).then((response) => response.data);
    callback(result);
  };
export const viewActiveCourses =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + `courses/view_active_course/${data}`
    ).then((response) => response.data);
    callback(result);
  };


  
  export const searchBarCourseList =
  (data, callback = () => {}) =>
  async (dispatch) => {
    console.log(data, "fata")
    var result = await Helper.postData(
      baseUrl + `courses/search_courses`, data
    ).then((response) => response.data);
    callback(result);
  };
export const listOfActiveCourses =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "courses/list_of_active_courses",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const listOfItemsInCart =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "cart/cartItemList").then(
      (response) => response.data
    );
    callback(result);
  };

export const removeItemFromCart =
  (data, callback = () => {}) =>
  async () => {
    let res = await Helper.patchData(baseUrl + "cart/remove", data).then(
      (e) => e.data
    );
    callback(res);
  };
  export const removeInstallFromCart =
  (data, callback = () => {}) =>
  async () => {
    let res = await Helper.patchData(baseUrl + "direct_training/remove_selected_installments", data).then(
      (e) => e.data
    );
    callback(res);
  };

export const BillingSummary =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "cart/billingSummary", data).then(
      (response) => response.data
    );
    callback(result);
  };

  export const CheckoutForDirecTraining =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "direct_training/checkout", data).then(
      (response) => response.data
    );
    callback(result);
  };

  export const TrainingBillingSummary =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "direct_training/billing_summary", data).then(
      (response) => response.data
    );
    callback(result);
  };
export const PlaceOrder =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "order/place_order",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const OrderHistory =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "order/user_order_list").then(
      (response) => response.data
    );
    callback(result);
  };
  export const TrainingOrderHistory =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "direct_training/orders").then(
      (response) => response.data
    );
    callback(result);
  };
  export const directTrainingPayNow =
  (data ,callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "direct_training/pay_now",data).then(
      (response) => response.data
    );
    callback(result);
  };

export const addToCart =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "cart/add", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const buyNow =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "order/buy_now", data).then(
      (response) => response.data
    );
    callback(result);
  };
export const addOrRemoveFromWishList =
  (data, callback = () => {}) =>
  async (dispatch) => {
    let result = await Helper.patchData(
      baseUrl + "user/wishlist_actions",
      data
    ).then((response) => response.data);
    callback(result);
  };
export const listOfItemsInWishlist =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "user/wishlist").then(
      (response) => response.data
    );
    callback(result);
  };

export const listOfCourses =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "user/list_of_my_courses").then(
      (response) => response.data
    );
    callback(result);
  };

export const DownloadCertificate =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "user/exam/download_certificate",
      data
    ).then((response) => response.data);
    callback(result);
  };
export const Active_assessment_questionsForUser =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "course/assessment/active_assessment_questions",
      data
    ).then((response) => response.data);
    callback(result);
  };
export const Take_assessment =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "user/exam/take_assessment",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const User_Assessment_Score =
  (sessionId, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + `user/exam/your_score/${sessionId}`
    ).then((response) => response.data);
    callback(result);
  };

export const User_my_course_Info =
  (purchasedCourseId, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + `user/my_course_Info/${purchasedCourseId}`
    ).then((response) => response.data);
    callback(result);
  };

export const Update_watched_history_of_my_course =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + 'user/update_watched_history_of_my_course',data
    ).then((response) => response.data);
    callback(result);
  };
  export const activeRatingsandReviews =
  (data, callback = () => {}) =>
  async (dispatch) => {
    console.log(data, "fata")
    var result = await Helper.postData(
      baseUrl + `courses/view_active_course_ratings_and_reviews`, data
    ).then((response) => response.data);
    callback(result);
  };
  export const listOfPlansInCart =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "subscription_plan/active_subscription_plan_list").then(
      (response) => response.data
    );
    callback(result);
  };
  export const activePlanListwithBundle =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + `subscription_plan/subscription_plan_info`, data
    ).then((response) => response.data);
    callback(result);
  };
  export const subscribedPlanInfo =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "my_subscription_plan/subscription_plan_info").then(
      (response) => response.data
    );
    callback(result);
  };
  export const cancelRenewal =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + `my_subscription_plan/cancel_renewal`, data
    ).then((response) => response.data);
    callback(result);
  };
  export const subscribeAPlan =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + `my_subscription_plan/subscribe_a_plan`, data
    ).then((response) => response.data);
    callback(result);
  };
