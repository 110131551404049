import React from 'react';
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";

const TermsnConditions = () => {
    return (
        <div className='AbouTPAge'>

            <div className='PageHEaders'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='PageTi pt-5'>

                                <h2>Terms & Conditions</h2>
                                <p><Link to="/">Home</Link> <i><FaAngleRight /></i><Link to="/termncondt">Terms & Conditions</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='policysect pt-5 pb-5'>
                <div className='container'>
                <h3>Welcome! </h3>
                        <p>These Terms of Service (the “ToS”) applies with respect to the usage of the Platform (defined hereinafter) and the services offered by KareerSity (“we”, or “us”), a Private limited company incorporated under the laws of India and having its registered office at Block Sector NR, Shop 21, VII Manapada, Brahmand Road, Shree Ji Vraj Bhoomi, Thane West, Thane, Thane, Maharashtra, 400607</p>
                        <p>These Terms of Service shall mutatis mutandis apply to mobile apps, WhatsApp groups, Facebook groups, Instagram pages, Facebook pages, email/SMS/phone communications and other communication forums/media hosted by KareerSity, which shall be deemed to be part of the platform (defined hereinafter). Your usage of the KareerSity (defined hereinafter) is subject to your explicit acceptance of these ToS. The terms contained in our privacy policy available at (“PrivacyPolicy”), or any other policy of KareerSity related to the KareerSity are incorporated into these ToS by this reference. Your acceptance of the same will irrevocably and unconditionally bind you to comply with and abide by all the obligations and conditions stipulated herein (unless otherwise explicitly made optional). After your initial confirmation/consent, you will be assumed to continue consenting to these ToS until you explicitly withdraw your consent by notifying KareerSity of the same in writing. If an executed agreement exists between you and KareerSity in relation to the KareerSity, any deviations from these ToS shall only apply when explicitly agreed in writing between the parties to such an agreement. IF YOU DO NOT AGREE TO BE BOUND BY ALL CONDITIONS/CLAUSES CAPTURED IN THESE TOS, PLEASE DO NOT USE THE PLATFORM OR SERVICES.</p>
                        <h3>Updation of Terms</h3>
                        <p>These ToS may be updated, amended, modified or revised by us from time to time. Each such updation or modification to the ToS will be communicated to you via email, through the Website or any other medium. Your continued use of the KareerSity after any such changes shall constitute your consent to such changes. We encourage you to refer to these ToS from time to time to ensure that you are aware of any additions, revisions, amendments or modifications that we may have made.</p>
                        <h3>Service(s) provided to Users</h3>
                        <p>KareerSity allows its users to access various training programs for students to make them job ready which is designed by KareerSity for undergraduates and professionals or application that KareerSity introduces from time to time. Each of these training programs shall be referred to as a “Cohort”. KareerSity reserves the right, in its sole discretion, to add, change, suspend, or discontinue all or any part of the KareerSity at any time. Your continued use of KareerSity following the posting of any changes to the same (including the addition or removal of features) constitutes your acceptance of those changes. Any information you provide to us for the purposes of registering and setting up your account (as specified below) shall be considered as accurate, complete and updated. You are solely responsible for the activity that occurs on your account (including those of others authorised to access and operate your account) and for maintaining the security of your account and any information you input. We are not responsible for the accuracy or legitimacy of any information, data, or User Data uploaded or posted on the Platform. For the purposes of clarity, as long as your account is accessed with the correct login credentials and unless you notify us of any unauthorised access, you shall be solely responsible for all actions/defaults related to your account.</p>
                        <p>In consideration of your acceptance of these ToS and if applicable, any subscription agreement (or any other similar agreement) executed between you and KareerSity, KareerSity grants the User a personal, non-exclusive, non-transferable, non-sublicensable, revocable limited license to access and use the Platform and Services, in accordance with the ToS.</p>
                        <h3>Registration and Account Integrity</h3>
                        <p>As part of the registration process, you will need to accept our ToS, and also provide us with your name, your phone number, your email ID and in certain scenarios, contact information of your parents/guardian. We would not at any time request you to provide us with access to your password, unless you have given us explicit written permission to access your account pursuant to a request for assistance or support.</p>
                        <p>It is your responsibility to ensure that the information you provide is accurate, secure, and not misleading. You cannot create an account username and password using (i) the names and information of another person; or (ii) using words that are the trademarks or the property of another party (including ours); or (iii) words that are vulgar, obscene or in any other way inappropriate. You agree and confirm that you will never use another User’s account nor provide access to your account to any third-party. You agree and acknowledge that you are solely responsible for the activities that occur on your account, and you shall keep your account password secure and not share the same with anyone. You must notify KareerSity immediately of any breach of security or unauthorized use of your account. At no point in time will KareerSity be liable for any losses caused by any unauthorized use of your account, and you shall solely be liable for the losses caused to KareerSity or others due to such unauthorized use, if any.</p>
                        <p>You shall be solely responsible for obtaining and maintaining any equipment or ancillary services necessary to connect to, access, receive the KareerSity Services or use the Platform, including, without limitation, hardware devices, software, and other internet, wireless, broadband, phone or other mobile communication device connection services. You shall be solely responsible for ensuring that such equipment or ancillary services are compatible with the platform, and you shall be responsible for all charges incurred in connection with the use of KareerSity in connection with all such equipment and ancillary services.</p>
                        <h3>Use of your Information and User Data</h3>
                        <p>All User Data that you create, transmit, transfer to us, submit, display or otherwise make available while using the KareerSity, should only be information that you own or have the right to use or share (as may be applicable). If you transfer any personally identifiable information to us, we will assume that you have informed the person concerned and procured the necessary consent for such transfer, sharing, processing and/or using the information on KareerSity. We may use the above information as per our Privacy Policy and in accordance with applicable laws. While we make commercially reasonable efforts to ensure that the data stored on our servers is persistent and always available to our Users, we will not be responsible in the event of failure of the third-party servers or any other factors outside our reasonable control, that may cause the User Data to be permanently deleted, irretrievable, or temporarily inaccessible.</p>
                        <h3>Third Party Links and Services</h3>
                        <p>We use multiple third-party service providers in order to power and provide you with the KareerSity. In this process, we share the User Data with some of the third-party service providers, as is necessary to enable them to provide the requisite services. We will attempt to ensure that all our third-party service providers are bound by and adhere to the same obligations of confidentiality and data privacy as we guarantee to you. KareerSity may include links to any third-party websites. KareerSity has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party website. By using the KareerSity, you expressly release KareerSity from any liability arising from your use of any third-party website or services.</p>
                        <h3>Rules and Acceptable Usage Policy</h3>
                        <p>As a condition of use, you promise not to use the KareerSity for any purpose that is prohibited by these ToS, by law, or other rules or policies implemented by us from time to time. KareerSity’s Content is provided only for your own personal, non-commercial use. You agree to notify us immediately in case you observe any other User breaching the terms contained herein or any policy associated with the Programs.</p>
                        <p>While using the KareerSity, you shall not:</p>
                        <ul>
                            <li>Harass any third party including any Users or Customers.</li>
                            <li>Create an atmosphere of disharmony, hostility within student groups.</li>
                            <li>Use unethical methods during any assignment, test/exam conducted on the Platform or otherwise in relation to any Program.</li>
                            <li>Share any KareerSity Content (including any study materials or test materials) with anybody through any media; share solutions to assignments/test materials pertaining to the Platform.</li>
                        </ul>
                        <p>Furthermore, you shall not (directly or indirectly): (i) take any action that imposes or may impose an unreasonable or disproportionately large load on KareerSity’s (or its third party providers’) infrastructure; (ii) interfere or attempt to interfere with the proper working of the Platform; (iii) bypass any measures we may use to prevent or restrict access to the KareerSity (or parts thereof); (iv) modify, translate, or otherwise create derivative works of any part of the KareerSity or the Platform; or (v) copy, rent, download, extract, lease, distribute, or transfer any part of the Platform, KareerSity Content or any third party User Data, unless KareerSity specifically allows you to do so.</p>
                        <h3>Content Ownership and Copyright Conditions of Access</h3>
                        <p>KareerSity Content including but not limited to all text, graphics, user interfaces, visual interfaces, sounds, artwork and computer code are owned and controlled by KareerSity and/or its licensors and the design, structure, selection, expression and arrangement of such content mentioned hereinabove is protected by copyright, patent and trademark laws, and other applicable laws on intellectual property rights. We reserve all rights, title, and ownership in and to the KareerSity Content.</p>
                        <p>KareerSity’s logos, trademarks and service marks that may appear on the Platform, including but not limited to “KareerSity” are the property of KareerSity and are protected under applicable laws on intellectual property rights.</p>
                        <p>Your copying, distributing, displaying, reproducing, modifying, or in any other way using of the KareerSity Content in any manner not specified in these ToS, will be a violation of copyright and other intellectual property rights and KareerSity reserves its rights under applicable law accordingly.</p>
                        <p>You shall, always, remain the complete owner and retain all rights and title in and to the User Data. This includes assuming liability and responsibility in cases where the User Data breaches any conditions of confidentiality, any intellectual property rights, or any other third-party rights. By posting any User Data through the KareerSity Services, you grant KareerSity and its affiliates, a worldwide, non-exclusive, royalty-free, irrevocable, sub-licensable and transferable license to use, copy, reformat, modify, translate, display, sell, create derivative works and distribute such User Data for or in connection with the use of the KareerSity Services.</p>
                        <h3>Reviews and Feedback</h3>
                        <p>You agree that we may contact you through telephone, email, SMS, or any other electronic means of communication for the purpose of:</p>
                        <p>Obtaining feedback in relation to KareerSity; and/or</p>
                        <p>Resolving any complaints, information, or queries received from you regarding the same.</p>
                        <p>Any feedback provided by you may be used by KareerSity without any restriction or obligation to compensate you, and we are under no obligation to keep such feedback confidential.</p>
                        <h3>Rights and Obligations relating to the Content</h3>
                        <p>You are prohibited from:</p>
                        <ul>
                            <li>Violating or attempting to violate the integrity or security of the Platform or any content therein;</li>
                            <li>transmitting any information (including messages and hyperlinks) on or through the KareerSity that is disruptive or competitive to the provision of our Services;</li>
                            <li>distributing in any medium, any part of KareerSity or KareerSity’s content without KareerSity’s prior written authorisation;</li>
                            <li>reproducing, duplicating, copying, selling, reselling or exploiting for any commercial purpose any KareerSity Content or any portion thereof, other than as expressly allowed under this ToS;</li>
                            <li>using the KareerSity marks in connection with, or to transmit, any unsolicited communications or emails or for any other unauthorised purpose;</li>
                            <li>intentionally submitting on the platform any incomplete, false or inaccurate information;</li>
                            <li>using any engine, software, tool, agent or other device or mechanism (such as spiders, robots etc.) to navigate or search the Platform;</li>
                            <li>attempting to decipher, decompile, disassemble, alter, modify or reverse engineer any part of the Platform;</li>
                            <li>copying or duplicating in any manner any of the KareerSity content or other information available from the platform;</li>
                            <li>posting or transmitting, or cause to be posted or transmitted, any communication or solicitation or other “phishing”, “pharming” or “whaling” message designed or intended to obtain password, account, personal information, or confidential information from any other User or any other third party whatsoever;</li>
                            <li>using or launching any automated system, including without limitation, “robots,” “spiders,” or “offline readers,” that accesses the platform in a manner that sends more request messages to KareerSity’s servers in a given period of time than a human can reasonably produce in the same period by using a conventional on-line web browser;</li>
                            <li>circumventing or disabling any digital rights management, usage rules, or other security features of the platform; or</li>
                            <li>allowing any third party to access the platform through your account unless KareerSity specifically allows you to do so.</li>
                        </ul>
                        <p>You shall not host, display, upload, modify, publish, transmit, update or share any information/content that:</p>
                        <ul>
                            <li>belongs to another person and to which the User does not have any right;</li>
                            <li>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful or inappropriate in any manner whatsoever;</li>
                            <li>harm minors in any manner;</li>
                            <li>infringes any patent, trademark, copyright or other proprietary rights;</li>
                            <li>violates any law for the time being in force;</li>
                            <li>deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</li>
                            <li>impersonates another person;</li>
                            <li>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;</li>
                            <li>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting any other nation.</li>
                        </ul>
                        <p>You understand that while using the platform, you may be exposed to User Data pertaining to other Users, and that KareerSity is not responsible for such User Data. You further understand and acknowledge that you may be exposed to User Data that is inaccurate, offensive, indecent, or objectionable, and you agree to waive, and hereby do waive, any legal or equitable rights or remedies you have or may have against KareerSity in relation thereto.</p>
                        <p>KareerSity shall, upon obtaining knowledge by itself or been brought to actual knowledge in writing or through email about any breach of the above conditions, may temporarily suspend/delete your account, impose penalties, or take any other actions that KareerSity may deem fit. We shall also be entitled to preserve such information and produce such records before governmental and/or judicial and/or regulatory authorities for investigation purposes as may be required by applicable law.</p>
                        <p>In your use of the KareerSity, you will always comply with all applicable laws and regulations. In case of non-compliance with any applicable laws, rules or regulations, or this ToS by a User, we shall have the right to immediately terminate your access or usage rights to the Platform and to remove non-compliant User Data from the Platform. KareerSity shall not be liable to refund any fees/consideration collected from a Customer in case such Customer’s access to the KareerSity is terminated by KareerSity for any reason detailed herein.</p>
                        <p>In the course of your usage of the KareerSity Services, you may post reviews, comments, suggestions, ideas, questions and other content, or send any other communications as long as the content is not illegal, obscene, threatening, defamatory, invasive of privacy, infringing any third party’s intellectual property rights, or otherwise injurious to third parties or objectionable and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any other form of spam. Further, you hereby grant KareerSity a limited, royalty-free, worldwide, non-exclusive license to use the User Data (including the contents specified herein) in further developing the Platform and for the purpose of its marketing or promotional activities.</p>
                        <h3>Support</h3>
                        <p>KareerSity offers online support tools. You may access support resources or contact our support team by emailing us at <a href="mailto:askus@Kareersity.com">askus@Kareersity.com</a></p>
                        <p>KareerSity shall use commercially reasonable efforts to respond to your request for support, or to fix any problems you may be having, as is applicable. Any suggestions by KareerSity to you regarding use of the Platform shall not be construed as a warranty.</p>
                        <h3>Termination</h3>
                        <p>KareerSity reserves the right to suspend or terminate your access to the Platform and the services with or without notice and to exercise any other remedy available under law, in cases where:</p>
                        <ul>
                            <li>you are in breach of any terms and conditions of this ToS;</li>
                            <li>KareerSity is unable to verify or authenticate any information provided to KareerSity by you; or</li>
                            <li>KareerSity has reasonable grounds for suspecting any illegal, fraudulent or abusive activity on your part.</li>
                        </ul>
                        <p>Once temporarily suspended, indefinitely suspended or terminated, you may not continue to use the Platform under the same account, a different account or re-register under a new account. KareerSity may destroy and/or delete all the data and other information related to your account as per its discretion. All provisions of the ToS, which by their nature should survive termination, shall survive termination, including without limitation, warranty disclaimers, indemnity and limitations of liability.</p>
                        <h3>Disclaimer</h3>
                        <p>THE KareerSity SERVICES AND THE KareerSity CONTENT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND ARE WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. KareerSity AND ITS DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, SPONSORS AND PARTNERS DO NOT WARRANT THAT: (A) THE KareerSity SERVICES AND THE PLATFORM WILL BE SECURE, ERROR-FREE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (B) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (C) ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE PLATFORM IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (D) THE RESULTS OF USING THE PLATFORM WILL MEET YOUR REQUIREMENTS. THE KareerSity SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS. KareerSity IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE RESULTING FROM SUCH PROBLEMS. YOUR USE OF THE PLATFORM AND THE KareerSity SERVICES IS SOLELY AT YOUR OWN RISK.</p>
                        <p>You will be awarded a certificate of successful completion of a Cohort (“Cohort Certificate”) upon fulfilling the criteria and requirements of KareerSity, which KareerSity may update from time to time. The decision to award any such Cohort Certificate or other acknowledgement of successful completion of a Cohort, would be solely at KareerSity’s discretion. KareerSity may choose not to offer any such Cohort Certificate or other acknowledgement of successful completion, for some Programs. KareerSity may decide at its sole discretion whether to provide a record concerning a Customer’s performance in a Program. The format of any certificate, credential or other acknowledgement, and of any performance, provided by KareerSity relating to a Program will be determined by KareerSity at its sole discretion and may vary as per the Program.</p>
                        <p>KareerSity has no obligation to have the KareerSity, KareerSity Content, Programs or Program Certificates recognized by or affiliated to any educational institution or accreditation organization. KareerSity provides education related services through the Platform. KareerSity MUST NOT be construed as a university. The Users understand and acknowledge that KareerSity cannot award certificates which are recognised by any regulatory body such as the Pharmacy Council of India or University Grants Commission.</p>
                        <p>It is the sole responsibility of the Customer enrolling into a Cohort to check the accuracy of, and evaluate the suitability and relevance of, the Cohort elected. The enrolment into a Cohort is non-transferable.</p>
                        <p>You acknowledge that certain parts of the Platform may be enabled by third-party service providers, and you agree to abide by their terms and conditions to the extent such terms are applicable to you. KareerSity shall not be responsible for any disruption of KareerSity Services caused by any such third-party service provider.</p>
                        <h3>Indemnification</h3>
                        <p>You shall defend, indemnify, and hold harmless KareerSity, its affiliates, subsidiaries and each of its, and its affiliates’ and subsidiaries’ employees, contractors, directors, suppliers and representatives from all liabilities, losses, claims, and expenses, including reasonable attorneys’ fees, that arise from or relate to (i) your use or misuse of, or access to, the Platform and/or KareerSity; (ii) your violation of the ToS or any applicable law; (iii) your violation of any rights of a third party in relation to the usage of the Platform. We reserve the right to assume the exclusive defence and control of any matter otherwise subject to indemnification by you, in which event you will assist and cooperate with us in connection therewith.</p>
                        <h3>Limitation of Liability</h3>
                        <p>TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL KareerSity (NOR ITS DIRECTORS, EMPLOYEES, AGENTS, SPONSORS, PARTNERS, SUPPLIERS, CONTENT PROVIDERS, LICENSORS OR RESELLERS,) BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SERVICE (I) FOR ANY LOST PROFITS, DATA LOSS, LOSS OF GOODWILL OR OPPORTUNITY, OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER; OR (II) FOR YOUR RELIANCE ON THE KareerSity; OR (III) FOR ANY DIRECT DAMAGES IN EXCESS (IN THE AGGREGATE) OF ONE THOUSAND RUPEES (INR 1,000/-); OR (IV) FOR ANY MATTER BEYOND ITS REASONABLE CONTROL, EVEN IF KAREERSITY HAS BEEN ADVISED OF THE POSSIBILITY OF ANY OF THE AFOREMENTIONED DAMAGES.</p>
                        <h3>Governing Law</h3>
                        <p>The ToS shall be governed by and construed in accordance with the laws of India without regard to the conflict of laws provisions thereof. All claims, differences and disputes arising under or in connection with or in relation here to the Platform, the ToS or any transactions entered into on or through the KareerSity shall be subject to the exclusive jurisdiction of the courts at Mumbai, India and you hereby accede to and accept the jurisdiction of such courts.</p>
                        <h3>Payments</h3>
                        <p>To make payment for any Cohort or to purchase any services or products offered by KareerSity through the Platform, you must have internet access and a current valid accepted payment method as indicated on the Platform (“Payment Method”). KareerSity does not store any of your credit card information or such other information restricted by the Reserve Bank of India (RBI) for processing payment and has partnered with payment gateways for the payment towards the services. By using a third-party payment provider, you agree to abide by the terms/policies of such third-party payment provider. You agree that in case KareerSity’s third-party payment provider stores any such information, KareerSity will not be responsible for such storage, and it will be solely at your discretion to allow the third party to store such information as per its terms/policies. KareerSity shall in no way be liable for loss of such information or any loss incurred by you due to the misuse of such information and KareerSity shall not be obligated to reimburse you for any such loss or to hold you harmless in any manner. You also agree to pay all applicable fees for the payments made through the Platform.</p>
                        <p>KareerSity does not support all payment methods, currencies or locations for payment. All applicable taxes are calculated based on the billing information you provide to us at the time of enrollment/purchase. Your transactions on the Platform may be subject to foreign exchange fees or exchange rates.</p>
                        <h4>Miscellaneous</h4>
                        <p>If any provision of the ToS is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the ToS will otherwise remain in full force and effect and enforceable.</p>
                        <p>Any failure by KareerSity to enforce any right or failure to act with respect to any breach by you under the ToS shall not be construed as, or constitute, a continuing waiver of such provision, or a waiver of any other breach of or failure to comply with any other provision of the ToS, unless any such waiver has been consented to by us in writing.</p>
                        <p>These ToS is personal to you and is not assignable or transferable by you except with KareerSity’s prior written consent. KareerSity may assign, transfer or delegate any of its rights and obligations hereunder without your consent.</p>
                        <p>No agency, partnership, joint venture, or employment relationship is created as a result of the ToS and neither party has any authority of any kind to bind the other in any respect in relation to any third party.</p>
                        <p>All notices under the ToS will be in writing and will be deemed to have been duly given when received, if personally delivered or sent by certified or registered mail, return receipt requested; when receipt is electronically confirmed, if transmitted by facsimile or e-mail; or two days after it is sent, if sent for next day delivery by recognized overnight delivery service.</p>
                        <p>KareerSity shall not be liable for failure to perform, or the delay in performance of, any of its obligations if, and to the extent that, such failure or delay is caused by events substantially beyond its control, including but not limited to acts of God, acts of the public enemy or governmental body in its sovereign or contractual capacity, war, terrorism, floods, fire, strikes, pandemics, epidemics, civil unrest or riots, power outage, and/or unusually severe weather.</p>
                        <p>By accepting the ToS through your use of the Platform, you certify and acknowledge that you are 18 years of age or older. If you are under the age of 18 or under the legal age in your jurisdiction to enter into a binding contract, you must use the Platform only under the supervision of a parent or legal guardian who agrees to be bound by the ToS. If you are a parent or legal guardian agreeing to this ToS for the benefit of an individual below 18 year of age, please be advised that you are fully responsible for his/her use of the Platform, including all financial charges and legal liability that he/she may incur. By using the Platform, you represent and warrant that you have the right, authority, and capacity to enter into this ToS and to abide by all of the terms and conditions set forth herein.</p>
                </div>
            </div>

        </div>
    )
}
export default TermsnConditions