import { createSlice } from "@reduxjs/toolkit";
import Helper from "../helper/axiosHelper";
const baseUrl = Helper.baseUrl();

const initialState = {
  //   firstSignUp: {},
  //   secSignUp: {},
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
});

export const { firstSignUp, secSignUp, getReasonList } =
  subscriptionSlice.actions;

export default subscriptionSlice.reducer;

export const bannerList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + "banner/active_banner_list"
    ).then((response) => response.data);
    callback(result);
  };
  export const planList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + "subscription_plan/active_subscription_plan_list"
    ).then((response) => response.data);
    callback(result);
  };
  export const SubBillingSummary =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "my_subscription_plan/billing_summary", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const activeJobList =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "job/active_job_list", data
    ).then((response) => response.data);
    callback(result);
  };
  export const directTrainingPayment =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "direct_training/proceed_to_pay", data
    ).then((response) => response.data);
    callback(result);
  };
  export const applyNow =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.formData(baseUrl + "job/apply_now", data).then(
      (response) => response.data
    );
    callback(result);
  };
  export const statesList =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + "job/list_of_states"
    ).then((response) => response.data);
    callback(result);
  };


export const subscriptionPurHis =
  (callback = () => { }) =>
    async (dispatch) => {
      var result = await Helper.getData(
        baseUrl + "my_subscription_plan/subscribedPlans"
      ).then((response) => response.data);
      callback(result);
    };

