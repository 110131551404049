import React, { useEffect, useState } from "react";
import "./Blog.css";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { activeBlogListCb} from "../../redux/user";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loader } from "../../redux/common";

const Blog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [allBlogData, setAllBlogData] = useState([]);
  useEffect(() => {
    dispatch(
      activeBlogListCb((resp) => {
        if (resp.status) {
          setAllBlogData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
  }, []);

  return (
    <div className="BlogLIst">
      <div className="PageHEaders">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PageTi pt-5">
                <p>
                  <Link to="/">Home</Link>{" "}
                  <i>
                    <FaAngleRight />
                  </i>
                  <Link to="/blog">Blogs</Link>
                </p>
                <h2>Blogs</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="BloGSestion pt-5 pb-5">
        <div className="container">
          <div className="row pt-3 pb-3">
            {allBlogData.map((item) => {
              return (
                <>
                  <div
                    className="col-lg-6 col-md-12"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      navigate("/blogdetail/" + item?._id);  window.scrollTo(0, 0)
                    }}
                  >
                    <div className="row blogs-page-box">
                      <div className="col-lg-5">
                        <div className="blogIMG">
                          <img src={item?.thumbnail} className="img-fluid" style={{width: "100%"}} />
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="BLogContent">
                          <h6 className="bdateheading">
                            {new Date(item?.updatedAt).toLocaleDateString(
                              "en-US",
                              {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </h6>
                          <h5 className="bcontentheading">{item?.title}</h5>
                          <p>{item?.sDesc}</p>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Blog;
