import React, { useEffect } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

const StartPayment = () => {
  const [searchParams] = useSearchParams();

  const paymentInitiate = async () => {
    var data = JSON.parse(searchParams.get("data"));

    var config = {
      method: "post",
      url: "https://www.backend.kareersity.com/eob/start-payment",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios(config);
      console.log(JSON.stringify(response.data));
      window.location.href = response?.data?.data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    paymentInitiate();
  }, []);

  return (
    <div>
      <p>Payment initiated...</p>
    </div>
  );
};

export default StartPayment;
