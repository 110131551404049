import React, { useState, useRef } from "react";
const baseUrl = "https://backend.kareersity.com";
const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB chunk size
const FileUpload = () => {
  const fileInputRef = useRef(null);
  const uploadBtnRef = useRef(null);
  const progressBarRef = useRef(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [totalChunks, setTotalChunks] = useState(null);

  const handleFileChange = () => {
    const selectedFile = fileInputRef.current.files[0];
    setFile(selectedFile);
    setFileName(`${Date.now()}_${selectedFile.name}`);
    setTotalChunks(Math.ceil(selectedFile.size / CHUNK_SIZE));
  };
  const handleUploadClick = async () => {
    if (!file) {
      return alert("Please select a file");
    }
    uploadBtnRef.current.disabled = true;
    try {
      const startTime = new Date();
      const requestBody = { fileName };
      const res = await fetch(`${baseUrl}/course/initiateUpload`, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { uploadId } = await res.json();
      const uploadPromises = [];
      let uploadedChunks = 0;
      let start = 0,
        end;
      for (let i = 0; i < totalChunks; i++) {
        end = start + CHUNK_SIZE;
        const chunk = file.slice(start, end);
        const formData = new FormData();
        formData.append("index", i);
        formData.append("totalChunks", totalChunks);
        formData.append("fileName", fileName);
        formData.append("file", chunk);
        const uploadPromise = fetch(
          `${baseUrl}/course/upload?uploadId=${uploadId}`,
          {
            method: "POST",
            body: formData,
          }
        ).then(() => {
          uploadedChunks++;
          const progress = Math.floor((uploadedChunks / totalChunks) * 100);
          updateProgressBar(progress);
        });
        uploadPromises.push(uploadPromise);
        start = end;
      }
      await Promise.all(uploadPromises);
      const completeRes = await fetch(
        `${baseUrl}/course/completeUpload?fileName=${fileName}&uploadId=${uploadId}`,
        { method: "POST" }
      );
      const { success, data } = await completeRes.json();
      if (!success) {
        throw new Error("Error completing upload");
      }
      const endTime = new Date();
      const timeElapsed = (endTime - startTime) / 1000;
      alert("File uploaded successfully");
      resetProgressBar();
    } catch (err) {
      console.log(err);
      alert("Error uploading file");
    }
    uploadBtnRef.current.disabled = false;
  };
  const updateProgressBar = (progress) => {
    progressBarRef.current.style.width = progress + "%";
    progressBarRef.current.textContent = progress + "%";
  };
  const resetProgressBar = () => {
    progressBarRef.current.style.width = "0%";
    progressBarRef.current.textContent = "";
    fileInputRef.current.value = "";
  };
  return (
    <div>
      <input
        type="file"
        id="fileInput"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <button id="uploadBtn" ref={uploadBtnRef} onClick={handleUploadClick}>
        Upload
      </button>
      <div className="progress-bar" ref={progressBarRef}></div>
    </div>
  );
};
export default FileUpload;
