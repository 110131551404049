import React, { useEffect, useState } from "react";
import "./Profile.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import Nav from "react-bootstrap/Nav";
import { useDispatch } from "react-redux";
import {
  Active_assessment_questionsForUser,
  Take_assessment,
} from "../../redux/courses";
import { toast } from "react-toastify";

const Question = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sessionId = useParams();
  const [qList, setQList] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  useEffect(() => {
    dispatch(
      Active_assessment_questionsForUser(sessionId, (response) => {
        if (response.status) {
          setQList(response.data);
        } else {
          toast.error(response.message);
        }
      })
    );
  }, [dispatch, sessionId]);

  const handleOptionChange = (questionId, option) => {
    console.log(typeof questionId, option, "questionId, option");
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: option,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Prepare the response object
    const response = {
      qList: qList.map((question) => ({
        questionId: question._id,
        yourAnswer: selectedAnswers[question._id] || "", // Use the selected answer, or an empty string if not selected
      })),
    };
    let newres = { ...sessionId, ...response };

    dispatch(
      Take_assessment(newres, (response) => {
        if (response.status) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      })
    );
  };

  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    // Get the current URL path
    const currentPath = window.location.pathname;
    setActiveTab(currentPath);
  }, []);
  return (
    <div className="ProFILE">
      <div className="PageHEaders">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PageTi pt-5">
                <h2>Question</h2>
                <p>
                  <Link to="/">Home</Link>{" "}
                  <i>
                    <FaAngleRight />
                  </i>
                  <Link to="/question">Question</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ProfileTabs pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="SIdeBar">
                <Nav defaultActiveKey="/dashboard" className="flex-column">
                  <Nav.Link
                    className={activeTab === "/dashboard" ? "Active_tab" : ""}
                  >
                    {" "}
                    <Link to="/dashboard">Dashboard</Link>{" "}
                  </Nav.Link>
                  <Nav.Link
                    className={activeTab === "/subscribe" ? "Active_tab" : ""}
                  >
                    <Link to="/subscribe">My Subscription</Link>
                  </Nav.Link>
                  <Nav.Link
                    className={activeTab === "/mycourse" ? "Active_tab" : ""}
                  >
                    {" "}
                    <Link to="/mycourse">My Course</Link>
                  </Nav.Link>
                  <Nav.Link
                    className={
                      activeTab === "/orderhistory" ? "Active_tab" : ""
                    }
                  >
                    <Link to="/orderhistory">Order History</Link>{" "}
                  </Nav.Link>
                  <Nav.Link
                    className={
                      activeTab === "/training_history" ? "Active_tab" : ""
                    }
                  >
                    <Link to="/training_history">Direct Training Orders</Link>{" "}
                  </Nav.Link>
                  <Nav.Link
                    className={activeTab === "/wishlist" ? "Active_tab" : ""}
                  >
                    <Link to="/wishlist">Wishlist</Link>
                  </Nav.Link>
                  <Nav.Link
                    className={activeTab === "/profile" ? "Active_tab" : ""}
                  >
                    {" "}
                    <Link to="/profile">My Profile</Link>
                  </Nav.Link>
                  <Nav.Link className={activeTab === "/" ? "Active_tab" : ""}>
                    {" "}
                    <Link to="/">Log Out</Link>
                  </Nav.Link>
                </Nav>
              </div>
            </div>
            <div className="col-lg-9 col-md-8">
              <h4>
                7 Reasons Why You Should Get A the certificate In Pharmacy -
                Assessment
              </h4>
              <div className="QUEstion mt-3">
                <form onSubmit={handleSubmit}>
                  {qList.map((x, i) => (
                    <div className="row">
                      <div className="col-12" key={x._id}>
                        <p>
                          {i + 1}. {x.question}
                        </p>
                      </div>
                      {x.options.map((op) => (
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <input
                            type="radio"
                            name={x._id} // Give each question a unique name
                            value={op}
                            checked={selectedAnswers[x._id] === op}
                            onChange={() => handleOptionChange(x._id, op)}
                          />
                          <label value={op}> {op}</label>
                          <br />
                        </div>
                      ))}
                    </div>
                  ))}

                  <div className="col-12">
                    <div className="Btn-Grn">
                      <button>Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Question;
