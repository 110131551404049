import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const PaymentFailed = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    window.location.href = `https://expertonboard.org/payment/?status=FAILED&order_id=${searchParams.get(
      "order_id"
    )}`;
  }, []);
  return (
    <div>
      <p>Redirecting...</p>
    </div>
  );
};

export default PaymentFailed;
