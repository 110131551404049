import React, { useEffect, useState } from "react";
import "./Cart.css";
import { Link } from "react-router-dom";
import { FaAngleRight, FaTimes } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import courseimg from "../../assets/images/coursebanner.png";
import { loader, isLogin, cartCount } from "../../redux/common";
import {
  BillingSummary,
  listOfItemsInCart,
  removeItemFromCart,
} from "../../redux/courses";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { userAcademicDetail } from "../../redux/profile";
import moment from 'moment'

const Cart = () => {
  const dispatch = useDispatch();
  const [itemsInCart, setItemsInCart] = useState([]);
  const [totalPrice, setTotalPrice] = useState([{}]);
  const [showData, setShowData] = useState();
  const [refreher, setRefreher] = useState(true);
  const [subTotal, setSubTotal] = useState([]);
  const [billingData, setBillingData] = useState({});
  const cartCountval = useSelector((state) => state.apiReducer.cartCount);
  const [academicData, setAcademicData] = useState({})

  useEffect(() => {
    if (
      localStorage.getItem("isLogin") == null ||
      localStorage.getItem("isLogin") == false
    ) {
      dispatch(isLogin(false));
    } else {
      dispatch(isLogin(true));
    }
    dispatch(loader(true));
    dispatch(
      listOfItemsInCart((resp) => {
        if (resp.status) {
          setShowData(resp.data[0].items.length);
          if (resp.data[0].items.length > 0) {
            console.log(resp.data)
            setItemsInCart(resp.data[0].items);
            setTotalPrice(resp.data[0].items[0].courseDetail.discountedPrice);
          }
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
    dispatch(
      userAcademicDetail((resp) => {
        dispatch(loader(true))
        if (resp.status) {
          console.log(resp.data)
          setAcademicData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
    let data = {};
    data["couponCode"] = "";
    dispatch(loader(true));
    dispatch(
      BillingSummary(data, (resp) => {
        if (resp.status) {
          setBillingData(resp.data);
          const inputString = resp.data.subTotal;
          const parts = inputString.split(/\s*\([^)]*\)\s*/);
          const extractedPart = parts[0].trim();
          setSubTotal(extractedPart);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          // toast.error(resp.message);
        }
      })
    );
  }, [refreher]);

  const removeItem = (id, index) => {
    let data = {};
    data["courseId"] = id;

    dispatch(loader(true));
    dispatch(
      removeItemFromCart(data, (resp) => {
        if (resp.status) {
          setRefreher(!refreher);
          dispatch(loader(false));
          dispatch(cartCount(!cartCountval));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  function convertTimestampToDays(timestamp) {
    const currentTime = Math.floor(Date.now() / 1000); // Current Unix time in seconds
    const targetTime = timestamp; // Provided Unix timestamp in seconds
    console.log(currentTime, timestamp)
    return currentTime < targetTime;
  }
  function convertExpiryToDate(expiryTimestamp) {
    const expiryDate = new Date(expiryTimestamp);
    return expiryDate;
  }
  function isCourseFree(item) {
    const currentTime = Math.floor(Date.now() / 1000);
    const userCreationDate = localStorage.getItem("UserCreatedDate");
    const expiryDate = convertExpiryToDate(userCreationDate);
    const collegeId = academicData?.collegeId

    const thirtyDaysInSeconds = 30 * 24 * 60 * 60; // 30 days in seconds

    if (item.freeForEveryone) {
      return true; // Course is free for everyone
    } else if (
      item.freeForEnInLast30Days &&
      currentTime - expiryDate.getTime() <= thirtyDaysInSeconds
    ) {
      return true; // Course is free for users who registered in the last 30 days
    } else if (
      academicData &&
      item.freeForbasedOnColleges && item.freeColleges &&
      item.freeColleges.includes(collegeId)
    ) {
      return true; // Course is free for users based on their college
    } else {
      return false; // Course is not free and discount has expired or not applicable
    }
  }
  return (
    <div className="CArtPAge">
      <div className="PageHEaders">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PageTi pt-5">
                <h2>Cart</h2>
                <p>
                  <Link to="/">Home</Link>{" "}
                  <i>
                    <FaAngleRight />
                  </i>
                  <Link to="/about">Cart</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="CarTBOX pt-5 pb-5">
        <div className="container">
          <div className="row">
            {itemsInCart && itemsInCart.length > 0 ? (
              <>
                <div
                  className="col-xl-9 col-lg-9 col-md-12"
                  style={{
                    display: showData == 0 ? "none" : "flex",
                  }}
                >
                  <div className="CartTablE">
                    <Table striped bordered hover>
                      <thead className="cardhEAD">
                        <tr>
                          <th> </th>
                          <th
                            style={{
                              display: showData == 0 ? "none" : "flex",
                            }}
                          >
                            Course
                          </th>
                          <th
                            style={{
                              display: showData == 0 ? "none" : "",
                            }}
                          >
                            Price
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {itemsInCart &&
                          itemsInCart.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <button
                                    className="BtnSub"
                                    onClick={() =>
                                      removeItem(item?.courseDetail?._id, index)
                                    }
                                  >
                                    <i>
                                      <FaTimes />
                                    </i>
                                  </button>
                                </td>
                                <td className="MainCartBox">
                                  <div className="CartBOxMain">
                                    <div className="row">
                                      <div className="col-lg-3">
                                        <div className="BoXimG">
                                          <img
                                            src={item.courseDetail.thumbnail}
                                            className="img-fluid imGBox"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-9">
                                        <div className="ConteNt crt">
                                          <h4>{item?.courseDetail?.title}</h4>
                                          <div className="d-flex contntCART">
                                            <p style={{ padding: "0px 10px 0px 0px" }}>
                                              {item?.courseDetail?.educators}
                                            </p>
                                            <p>{item?.courseDetail?.level}</p>
                                            <p>English</p>
                                            <p>
                                              {item?.courseDetail?.duration}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="Rate chk">
                                    {isCourseFree(item?.courseDetail) ? (
                                      <h4>FREE</h4>
                                    ) : (
                                      <>
                                        {item?.courseDetail?.discountedPrice >
                                          0 &&
                                          convertTimestampToDays(
                                            item?.courseDetail
                                              ?.discountedPriceExpiry
                                          ) ? (
                                          <>
                                            <h3>
                                              ₹
                                              {
                                                item?.courseDetail
                                                  ?.discountedPrice
                                              }
                                            </h3>
                                            <h5>
                                              <s>
                                                ₹{item?.courseDetail?.price}
                                              </s>
                                            </h5>
                                          </>
                                        ) : (
                                          <>
                                            <h3>
                                              ₹
                                              {item?.courseDetail?.regularPrice}
                                            </h3>
                                            <h5>
                                              <s>
                                                ₹{item?.courseDetail?.price}
                                              </s>
                                            </h5>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-12 pt-5">
                  <div className="SidePURBTn d-flex flex-column text-align-center ">
                    <button
                      className="BluEBtn justify-content-center"
                      style={{
                        display: showData == 0 ? "none" : "flex",
                      }}
                    >
                      Total <span>{subTotal}</span>{" "}
                    </button>

                    <br></br>
                    <Link
                      to="/checkout"
                      className="grnBtn text-center"
                      style={{
                        display: showData == 0 ? "none" : "block",
                      }}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      Check Out
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <div className=" text-center continueshopbut">
                <h3 className="mb-3">No items in cart</h3>
                <Link to="/course" className="grnBtn text-center ">
                  Continue Shopping
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* image Section */}
      <div className="IMAgeSession  pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 pt-3 pb-3">
              <Link to="/">
                <img src={courseimg} className="img-fluid" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* image section end */}
    </div>
  );
};
export default Cart;
