import React, { useEffect, useRef, useState } from "react";
import "./Profile.css";
import moment from 'moment';
import Logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { AiOutlineDownload } from "react-icons/ai";
import Nav from "react-bootstrap/Nav";
import Table from "react-bootstrap/Table";
import { TrainingOrderHistory, directTrainingPayNow } from "../../redux/courses";
import { loader, isLogin } from "../../redux/common";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { userPersonalDetail } from "../../redux/profile";

const Traininghistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [historyData, setHistoryData] = useState();
  const [existingUser, setExistingUser] = useState({});
  const [personalData, setPersonalData] = useState({})
  const pdfRef = useRef();

  useEffect(() => {
    if (
      localStorage.getItem("isLogin") == null ||
      localStorage.getItem("isLogin") == false
    ) {
      dispatch(isLogin(false));
    } else {
      dispatch(isLogin(true));
    }
    dispatch(loader(true));
    dispatch(
      TrainingOrderHistory((resp) => {
        if (resp.status) {
          console.log("historydata", resp.data)
          setHistoryData(resp.data);
          
          // const installmentData = resp.data[0].installments.map(
          //   (item) => item.installmentId
          // );
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
    dispatch(
      userPersonalDetail((resp) => {
        if (resp.status) {
          setExistingUser(resp.data.isExUser);
          setPersonalData(resp.data)
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, []);
  function addSuffix(num) {
    const j = num % 10;
    const k = num % 100;
    if (j === 1 && k !== 11) {
      return num + "st";
    }
    if (j === 2 && k !== 12) {
      return num + "nd";
    }
    if (j === 3 && k !== 13) {
      return num + "rd";
    }
    return num + "th";
  }
  let selectedIndex = 0;
  const generatePDF = (historyDatas) => {
    const doc = new jsPDF();

    // Calculate the center position for the header logo
    const pageWidth = doc.internal.pageSize.width;
    const headerLogoWidth = 30;
    const headerLogoHeight = 30;
    const headerLogoX = 10;
    const headerLogoY = 10;

    // Add the invoice logo to the center of the header
    doc.addImage(
      Logo,
      "JPEG",
      headerLogoX,
      headerLogoY
      // headerLogoWidth,
      // headerLogoHeight
    );

    // Add company information to the header
    // Static company information
    const companyInfo = {
      phone: "+91 90042 14077",
      email: "askus@kareersity.com",
      address: "Corporate Office, Thane, Mumbai",
    };

    const headerLineHeight = 7;
    const headerRightMargin = 10; // Adjust this to your desired right margin

    // Add company information to the header with adjusted line heights and right margin
    let headerY = 20;
    doc.setFontSize(12);
    doc.setTextColor(82, 86, 89);

    // Calculate the X-coordinate for the right-aligned text
    const rightAlignedX = pageWidth - headerRightMargin;

    doc.text(`Phone: ${companyInfo.phone}`, rightAlignedX, headerY, {
      align: "right",
    });
    headerY += headerLineHeight;
    doc.text(`Email: ${companyInfo.email}`, rightAlignedX, headerY, {
      align: "right",
    });
    headerY += headerLineHeight;
    doc.text(companyInfo.address, rightAlignedX, headerY, {
      align: "right",
    });

    const lineHeight = 7;


    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    const inputDate = new Date(historyDatas?.paidForMonth);
    const formatDate = (date) => {
      // Parse the date string using Moment.js
      const parsedDate = moment(date, 'DD/MM/YYYY HH:mm:ss');

      // Format the parsed date to the "dd-mm-yyyy" format
      const formattedDate = parsedDate.format('DD/MM/YYYY');

      return formattedDate;
    };
    let transDate = ""
    if (historyDatas.paymentDetails) {
      transDate = formatDate(historyDatas.paymentDetails.trans_date);
    } else {
      transDate = "-"
    }


    let currentY = 50;

    doc.text("Bill To:", 10, currentY);
    currentY += lineHeight;
    doc.text(
      `Name: ${historyDatas?.paymentDetails?.billing_name}`,
      10,
      currentY
    );
    currentY += lineHeight;
    doc.text(
      `MobileNumber: ${historyDatas?.paymentDetails?.billing_tel}`,
      10,
      currentY
    );
    currentY += lineHeight;
    doc.text(
      `Email: ${historyDatas?.paymentDetails?.billing_email}`,
      10,
      currentY
    );
    currentY += lineHeight;
    doc.text(
      `City: ${historyDatas?.paymentDetails?.billing_city}`,
      10,
      currentY
    );
    currentY += lineHeight;
    doc.text(
      `State: ${historyDatas?.paymentDetails?.billing_state}`,
      10,
      currentY
    );
    currentY += lineHeight;
    doc.text(
      `Country: ${historyDatas?.paymentDetails?.billing_country}`,
      10,
      currentY
    );
    currentY += lineHeight;

    const gstAmount = Number(historyDatas?.installmentInfo.amount) * 0.18

    const orderData = {
      orderid: historyDatas?.paymentDetails?.order_id.slice(-7).toUpperCase(),
      installments: `${addSuffix(historyDatas?.installmentInfo?.title)} Month`,
      formattedDate: inputDate.toLocaleDateString("en-IN", options),
      transDate: transDate,
      amount: `INR ${(historyDatas?.installmentInfo.amount + gstAmount).toFixed(2)}`,
      status: historyDatas?.status,
    };
    const tableData = [
      [
        orderData.orderid,
        orderData.installments,
        orderData.formattedDate,
        orderData.transDate,
        orderData.amount,
        orderData.status,
      ],
    ];
    doc.autoTable({
      head: [["Order ID", "Installments", "Due Date", "Transaction Date", "Amount", "Status"]],
      body: tableData,
      startY: currentY + lineHeight,
    });
    doc.save("invoice.pdf");
  };

  setInterval(() => {
    autoLogOut();
  }, 1000);

  const autoLogOut = () => {
    if (localStorage.getItem("oAuth")) {
      const jwt_Token_decoded = jwt_decode(localStorage.getItem("oAuth"));
      if (jwt_Token_decoded.exp * 1000 < Date.now()) {
        localStorage.removeItem("oAuth");
        localStorage.clear();
        navigate("/");
        dispatch(isLogin(false));
        toast.error("Session expired.Please login to proceed");
      }
    }
  };
  const handleLogOut = () => {
    localStorage.removeItem("oAuth");
    localStorage.removeItem("isLogin");
    localStorage.removeItem("UserCreatedDate");
    localStorage.removeItem("collegeId");
    navigate("/");
    dispatch(isLogin(false));
    toast.success("You have logged out successfully");
  };

  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    // Get the current URL path
    const currentPath = window.location.pathname;
    setActiveTab(currentPath);
  }, []);


  const payInstallment = (id) => {
    let data = {};
    data["orderId"] = id;
    console.log("payInstallment", data)
    dispatch(
      directTrainingPayNow(data, (response) => {
        if (response.status) {
          window.location.href = response.data;

        } else {
          toast.error(response.message);
        }
      })
    );
  };
  return (
    <div className="ProFILE">
      <div className="PageHEaders">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PageTi pt-5">
                <h2>Profile</h2>
                <p>
                  <Link to="/">Home</Link>{" "}
                  <i>
                    <FaAngleRight />
                  </i>
                  <Link to="/profile">Profile</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ProfileTabs pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12">
            {!personalData.isExUser && personalData.isEnrolledInDirectTraining ?
                <div className="SIdeBar">
                  <Nav defaultActiveKey="/dashboard" className="flex-column">
                    <Nav.Link
                      className={activeTab === "/profile" ? "Active_tab" : ""} as={Link}
                    >
                      <Link to="/profile">My Profile</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/wishlist" ? "Active_tab" : ""}
                    >
                      <Link to="/wishlist">Wishlist</Link>
                    </Nav.Link>
                    {personalData.isEnrolledInDirectTraining
                      &&
                      <Nav.Link
                        className={
                          activeTab === "/training_history" ? "Active_tab" : ""
                        }
                      >
                        <Link to="/training_history">Direct Training Orders</Link>{" "}
                      </Nav.Link>}
                    <Nav.Link className={activeTab === "/" ? "Active_tab" : ""}>
                      {" "}
                      <Link to="/" onClick={handleLogOut}>
                        Log Out
                      </Link>
                    </Nav.Link>
                  </Nav>
                </div> :
                <div className="SIdeBar">
                  <Nav defaultActiveKey="/dashboard" className="flex-column">
                    <Nav.Link
                      className={activeTab === "/dashboard" ? "Active_tab" : ""}
                    >
                      {" "}
                      <Link to="/dashboard">Dashboard</Link>{" "}
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/subscribe" ? "Active_tab" : ""}
                    >
                      <Link to="/subscribe">My Subscription</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/mycourse" ? "Active_tab" : ""}
                    >
                      {" "}
                      <Link to="/mycourse">My Course</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={
                        activeTab === "/orderhistory" ? "Active_tab" : ""
                      }
                    >
                      <Link to="/orderhistory">Order History</Link>{" "}
                    </Nav.Link>
                    {personalData.isEnrolledInDirectTraining
                      &&
                      <Nav.Link
                        className={
                          activeTab === "/training_history" ? "Active_tab" : ""
                        }
                      >
                        <Link to="/training_history">Direct Training Orders</Link>{" "}
                      </Nav.Link>}
                    <Nav.Link
                      className={activeTab === "/wishlist" ? "Active_tab" : ""}
                    >
                      <Link to="/wishlist">Wishlist</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/profile" ? "Active_tab" : ""}
                    >
                      {" "}
                      <Link style={{ width: "100%" }} to="/profile">My Profile</Link>
                    </Nav.Link>
                    <Nav.Link className={activeTab === "/" ? "Active_tab" : ""}>
                      {" "}
                      <Link to="/" onClick={handleLogOut}>
                        Log Out
                      </Link>
                    </Nav.Link>
                  </Nav>
                </div>
              }
            </div>
            <div className="col-lg-9 col-md-12">
              <div className="OrderTABle drctTrng">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Installments</th>
                      <th>Due Date</th>
                      <th>Transaction Date</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Invoice</th>
                    </tr>
                  </thead>
                  {/* <tbody>
                    {historyData &&
                      historyData.map((item, index) => {
                        const transDateParts = item?.paymentDetails?.trans_date ?  item?.paymentDetails?.trans_date.split(" ")[0].split("/") : 
                        item?.createdAt.split(" ")[0].split("/")  ;

                        const day = parseInt(transDateParts[0], 10);
                        const month = parseInt(transDateParts[1], 10) - 1;
                        const year = parseInt(transDateParts[2], 10);

                        item.installmentInfo.sort((a, b) => {
                          return a.title.localeCompare(
                            b.title
                          );
                        });
                        const options = {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        };

                        const inputDate = new Date(year, month, day);
                        if (isNaN(inputDate.getTime())) {
                          console.error("Invalid Date:", transDateParts);
                        }

                   
                     
                            const dueDate = new Date(inputDate);
                            dueDate.setMonth(
                              dueDate.getMonth() + installmentIndex
                            );

                            const formattedDueDate = dueDate.toLocaleDateString(
                              "en-IN",
                              options
                            );

                            return (
                              <tr key={index} ref={pdfRef}>
                                {item.status == "success" ? (
                                  <td>
                                    {" "}
                                    #{item._id.slice(0, 7).toUpperCase()}{" "}
                                  </td>
                                ) : (
                                  <td> {""}</td>
                                )}

                                <td>{item.installmentInfo.installmentId.title} Month</td>
                                <td>{formattedDueDate}</td>
                                <td>₹ {installmentInfo.amount}</td>
                                <td>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</td>
                                {item.status == "success" ? (
                                  <td>
                                    <button
                                      className="DOwnLoaDBtN"
                                      onClick={() =>
                                        generatePDF(item, selectedIndex)
                                      }
                                    >
                                      <i>
                                        <AiOutlineDownload />
                                      </i>
                                    </button>
                                  </td>
                                ) : (
                                  <td>
                                    <button className="GrnActn">Pay Now</button>
                                  </td>
                                )}
                              </tr>
                            );
                          
                        
                      })}
                  </tbody> */}

                  <tbody>
                    {historyData &&
                      historyData.map((item, index) => {


                        const options = {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        };

                        const formatDate = (dateString) => {
                          // Parse the date string using Moment.js
                          const parsedDate = moment(dateString, 'DD/MM/YYYY HH:mm:ss');

                          // Format the parsed date to the "dd-mm-yyyy" format
                          const formattedDate = parsedDate.format('DD/MM/YYYY');

                          return formattedDate;
                        };

                        const inputDate = new Date(item.paidForMonth);
                        let transDate = ""
                        if (item.paymentDetails) {
                          transDate = formatDate(item.paymentDetails.trans_date);
                        } else {
                          transDate = "-"
                        }
                        let gstAmount = Number(item?.installmentInfo.amount) * 0.18

                        return (
                          <tr key={item._id} ref={pdfRef}>
                            {item.status == "success" ?
                              <td>{item.paymentDetails?.order_id ? `#${item?.paymentDetails?.order_id.slice(-7).toUpperCase()}` : "-"}</td>
                              :
                              <td>{" "}</td>
                            }

                            <td>{addSuffix(item.installmentInfo.title)} Month</td>
                            <td>{inputDate.toLocaleDateString("en-IN", options)}</td>
                            <td>{transDate}</td>
                            <td>₹ {(item.installmentInfo.amount + gstAmount).toFixed(2)}</td>
                            <td>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</td>
                            {item.status === "success" ? (
                              <td>
                                <button className="DOwnLoaDBtN" onClick={() => generatePDF(item)}>
                                  <i><AiOutlineDownload /></i>
                                </button>
                              </td>
                            ) : (
                              <td>
                                <button onClick={() => payInstallment(item._id)} className="GrnActn">Pay Now</button>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>






                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Traininghistory;
