import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./JobListing.css";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { loader } from "../../redux/common";
import { useDispatch } from "react-redux";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { activeJobList, applyNow } from "../../redux/subscription";
import { useLocation } from "react-router-dom";
const ApplyJob = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const jobId = location.state.jobId;
  const title = location.state.title;
  const {
    register: register,
    formState: { errors: errors },
    reset: reset,
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });

  const signUpFn = (data) => {
    const formData = new FormData();
    const fullAddress = `${data.addressLine1}, ${data.addressLine2}`;
    // Append required fields to formData
    formData.append("jobId", jobId);
    formData.append("fullName", data.fullName);
    formData.append("emailId", data.emailId);
    formData.append("phoneNumber", data.phoneNumber);
    formData.append("gender", data.gender);
    formData.append("address", fullAddress);
    formData.append("city", data.city);
    formData.append("pincode", data.pincode);
    formData.append("positionAppliedFor", data.positionAppliedFor);
    formData.append("totalWorkExperience", data.totalWorkExperience);
    formData.append("lastEmployer", data.lastEmployer);
    formData.append("source", data.source);
    formData.append("cv", data.cv[0]);

    dispatch(loader(true));
    dispatch(
      applyNow(formData, (result) => {
        dispatch(loader(false));
        if (result.status) {
          toast.success(result.message);
          reset();
          dispatch(loader(false));
        } else {
          toast.error(result.message);
          dispatch(loader(false));
        }
      })
    );
  };

  const handleBack = () => {
    navigate("/jobs");
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(
      activeJobList((resp) => {
        if (resp.status) {
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, []);
  const source = [
    { value: "news_paper", label: "News Paper" },
    { value: "linkedin", label: "LinkedIn" },
    { value: "facebook", label: "FaceBook" },
    { value: "twitter", label: "Twitter" },
  ];
  const gender = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "Other", label: "Other" },
  ];
  return (
    <div className="EDucaTorPage">
      <div className="job"></div>
      <div className="PageHEaders applyJobHdr pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 applyTitle">
              <h4>Apply For</h4>
              <h5>{title}</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="EduCatorFrom pt-5 pb-5">
        <div className="container">
          <div className="backIcon" onClick={handleBack}>
            <MdOutlineKeyboardDoubleArrowLeft /> Back
          </div>
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="eDUcAfo">
                <form onSubmit={handleSubmit(signUpFn)}>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="EduCf">
                        <label>Position Applied For</label>
                        <input
                          type="text"
                          className="inputFormF"
                          placeholder="Position"
                          name="position"
                          id="position"
                          readOnly
                          defaultValue={title}
                          {...register("positionAppliedFor", {
                            required: "positionAppliedFor is required",
                            pattern: {
                              value: /^[A-Za-z\s]+$/, // Accepts letters and spaces
                              message:
                                "Invalid format. User name accepts only characters and spaces",
                            },
                          })}
                        />
                        {errors.positionAppliedFor && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.positionAppliedFor.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Full Name</label>
                        <input
                          type="text"
                          className="inputFormF"
                          placeholder="Full Name"
                          name="name"
                          id="name"
                          {...register("fullName", {
                            required: "Name is required",
                            pattern: {
                              value: /^[A-Za-z\s]+$/, // Accepts letters and spaces
                              message:
                                "Invalid format. User name accepts only characters and spaces",
                            },
                          })}
                        />
                        {errors.fullName && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.fullName.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Gender</label>

                        <select
                          name="gender"
                          {...register("gender", {
                            required: "gender is required",
                            pattern: {
                              value: /^(?!.*test).*$/,
                              message: "select one option",
                            },
                          })}
                        >
                          <option value="test">Select option</option>
                          {gender.map((item) => (
                            <option value={item.value}>{item.label}</option>
                          ))}
                        </select>
                        {errors.gender && (
                          <p style={{ color: "red" }}>
                            {" "}
                            {errors.gender.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Email Id</label>
                        <input
                          type="email"
                          className="inputFormF"
                          placeholder="Email Address"
                          name="email"
                          id="email"
                          {...register("emailId", {
                            required: "Email is required",
                            pattern: {
                              value:
                                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
                              message: "Invalid Format",
                            },
                          })}
                        />
                        {errors.emailId && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.emailId.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Phone Number</label>
                        <input
                          type="number"
                          className="inputFormF"
                          placeholder="Phone Number"
                          name="phoneNumber"
                          id="phoneNumber"
                          {...register("phoneNumber", {
                            required: "Mobile Number is required",
                            pattern: {
                              value:
                                /^(?:\(\d{3}\)\s?|\d{3}[-.\s]?)?\d{3}[-.\s]?\d{4}$/,
                              message:
                                "Invalid Format.Please enter 10 digit Mobile number",
                            },
                          })}
                        />
                        {errors.phoneNumber && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.phoneNumber.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="EduCf">
                        <label>Address Line 1</label>
                        <input
                          style={{ margin: "10px 0px" }}
                          type="text"
                          className="inputFormF namecl"
                          placeholder="Address Line 1"
                          name="addressLine1" // Changed name to addressLine1
                          id="addressLine1"
                          {...register("addressLine1", {
                            required: "Address Line 1 is required",
                          })}
                        />
                        <br />
                        <label>Address Line 2</label>
                        <input
                          style={{ margin: "10px 0px" }}
                          type="text"
                          className="inputFormF namecl"
                          placeholder="Address Line 2"
                          name="addressLine2" // Changed name to addressLine2
                          id="addressLine2"
                          {...register("addressLine2", {
                            required: "Address Line 2 is required",
                          })}
                        />
                        {errors.addressLine1 && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.addressLine1.message}
                          </small>
                        )}
                        {errors.addressLine2 && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.addressLine2.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>City/Town</label>
                        <input
                          type="text"
                          className="inputFormF"
                          placeholder="City/Town"
                          name="city"
                          id="city"
                          {...register("city", {
                            required: "City/Town is required",
                            pattern: {
                              value: /^[A-Za-z\s]+$/, // Accepts letters and spaces
                              message:
                                "Invalid format. User name accepts only characters and spaces",
                            },
                          })}
                        />

                        {errors.city && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.city.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Postal Code</label>
                        <input
                          type="text"
                          className="inputFormF"
                          placeholder="Postal Code"
                          name="pincode"
                          id="pincode"
                          {...register("pincode", {
                            required: "pincode is required",
                          })}
                        />
                        {errors.pincode && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.pincode.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Total Work Experience</label>
                        <input
                          type="text"
                          className="inputFormF"
                          placeholder="Total Work Experience"
                          name="totalWorkExperience"
                          id="totalWorkExperience"
                          {...register("totalWorkExperience", {
                            required: "Total Work Experience is required",
                          })}
                        />
                        {errors.totalWorkExperience && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.totalWorkExperience.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Previous Employer / Current Employer</label>
                        <input
                          type="text"
                          className="inputFormF"
                          placeholder="Previous Employer / Current Employer"
                          name="lastEmployer"
                          id="lastEmployer"
                          {...register("lastEmployer", {
                            required: "lastEmployer is required",
                          })}
                        />
                        {errors.lastEmployer && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.lastEmployer.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>How did you hear about us</label>

                        <select
                          name="source"
                          {...register("source", {
                            required: "source is required",
                            pattern: {
                              value: /^(?!.*test).*$/,
                              message: "select one option",
                            },
                          })}
                        >
                          <option value="test">Select option</option>
                          {source.map((item) => (
                            <option value={item.value}>{item.label}</option>
                          ))}
                        </select>
                        {errors.source && (
                          <p style={{ color: "red" }}>
                            {" "}
                            {errors.source.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf dotted">
                        <label>Upload CV</label>
                        <Form.Group controlId="formFile" className="mb-3">
                          <Form.Control
                            placeholder="Choose"
                            type="file"
                            name="cv"
                            id="cv"
                            {...register("cv", {
                              required: "Resume required",
                              validate: {
                                isPDF: (file) => {
                                  const allowedFormats = ["application/pdf"];
                                  return (
                                    allowedFormats.includes(file[0]?.type) ||
                                    "Invalid file format. Please upload a PDF file."
                                  );
                                },
                                lessThan10MB: (files) =>
                                  files[0]?.size < 10485760 ||
                                  "File size should be Max 10MB", // 10MB in bytes (10 * 1024 * 1024)
                              },
                            })}
                          />
                          {errors.cv && (
                            <p style={{ color: "red" }}> {errors.cv.message}</p>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-12">
                      <div className="SIGBtn">
                        <div className="EduCf">
                          <button type="submit">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ApplyJob;
