import React, { useEffect, useRef, useState } from "react";
import "./Profile.css";
import Logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { AiOutlineDownload } from "react-icons/ai";
import Nav from "react-bootstrap/Nav";
import Table from "react-bootstrap/Table";
import { OrderHistory } from "../../redux/courses";
import { loader, isLogin } from "../../redux/common";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { userPersonalDetail } from "../../redux/profile";
import { subscriptionPurHis } from "../../redux/subscription";
import moment from "moment";

const Orderhistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [historyData, setHistoryData] = useState();
  const [subsHisData, setSubsHisData] = useState();
  const pdfRef = useRef();
  const [personalData, setPersonalData] = useState([])

  useEffect(() => {
    if (
      localStorage.getItem("isLogin") == null ||
      localStorage.getItem("isLogin") == false
    ) {
      dispatch(isLogin(false));
    } else {
      dispatch(isLogin(true));
    }
    dispatch(loader(true));
    dispatch(
      OrderHistory((resp) => {
        if (resp.status) {
          setHistoryData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );

    dispatch(
      subscriptionPurHis((resp) => {
        if (resp.status) {
          console.log(resp)
          setSubsHisData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );

    dispatch(
      userPersonalDetail((resp) => {
        if (resp.status) {
          setPersonalData(resp.data)
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, []);

  const generatePDF = (item, name) => {
    const doc = new jsPDF();

    // Calculate the center position for the header logo
    const pageWidth = doc.internal.pageSize.width;
    const headerLogoWidth = 30;
    const headerLogoHeight = 30;
    const headerLogoX = 10;
    const headerLogoY = 10;

    // Add the invoice logo to the center of the header
    doc.addImage(Logo, "JPEG", headerLogoX, headerLogoY);

    // Add company information to the header
    // Static company information
    const companyInfo = {
      phone: "+91 90042 14077",
      email: "askus@kareersity.com",
      address: "Corporate Office, Thane, Mumbai",
    };

    const headerLineHeight = 7;
    const headerRightMargin = 10; // Adjust this to your desired right margin

    // Add company information to the header with adjusted line heights and right margin
    let headerY = 20;
    doc.setFontSize(12);
    doc.setTextColor(82, 86, 89);

    // Calculate the X-coordinate for the right-aligned text
    const rightAlignedX = pageWidth - headerRightMargin;

    doc.text(`Phone: ${companyInfo.phone}`, rightAlignedX, headerY, {
      align: "right",
    });
    headerY += headerLineHeight;
    doc.text(`Email: ${companyInfo.email}`, rightAlignedX, headerY, {
      align: "right",
    });
    headerY += headerLineHeight;
    doc.text(companyInfo.address, rightAlignedX, headerY, {
      align: "right",
    });

    const lineHeight = 7;



    if (name === "orders") {
      let currentY = 50;
      doc.text("Bill To:", 10, currentY);
      currentY += lineHeight;
      doc.text(`Name: ${item.billingDetails.name}`, 10, currentY);
      currentY += lineHeight;
      doc.text(`MobileNumber: ${item.billingDetails.mobileNumber}`, 10, currentY);
      currentY += lineHeight;
      doc.text(`Email: ${item.billingDetails.email}`, 10, currentY);
      currentY += lineHeight;
      doc.text(`City: ${item.billingDetails.city}`, 10, currentY);
      currentY += lineHeight;
      doc.text(`State: ${item.billingDetails.state}`, 10, currentY);
      currentY += lineHeight;
      doc.text(`Country: ${item.billingDetails.country}`, 10, currentY);

      const inputDate = new Date(item?.createdAt);
      const options = {
        day: "2-digit",
        month: "short",
        year: "numeric",
      };
      const formattedDate = inputDate.toLocaleDateString("en-IN", options);

      // Add bill-to information with adjusted line heights
      const gstAmount = Number(item?.paymentDetails?.paidAmount) * 0.18

      // Prepare table data
      const orderData = {
        orderid: item._id.slice(0, 7).toUpperCase(),
        categories: item.items.map((v) => `• ${v.category}`).join("\n"), // Concatenate categories
        titles: item.items.map((v) => `• ${v.title}`).join("\n"), // Concatenate titles
        formattedDate,
        totalMrp: `${item.totalMrp}`,
        paidAmount: `${(item?.paymentDetails?.paidAmount).toFixed(2)}`, // Assuming you want to display the totalMrp from the entire order
      };

      const tableData = [
        [
          orderData.orderid,
          orderData.categories,
          orderData.titles,
          orderData.formattedDate,
          orderData.totalMrp,
          orderData.paidAmount,
        ],
      ];

      // Create the table
      doc.autoTable({
        head: [
          ["Order ID", "Category", "Course", "Date", "MRP", "DiscountedPrice"],
        ],
        body: tableData,
        startY: 120, // Adjust the starting position as needed
      });

      // Save the PDF
      doc.save("invoice.pdf");
    }

    if (name === "subscription") {
      let currentY = 50;
      doc.text("Bill To:", 10, currentY);
      currentY += lineHeight;
      doc.text(`Name: ${item?.paymentInfo?.billing_name}`, 10, currentY);
      currentY += lineHeight;
      doc.text(`MobileNumber: ${item?.paymentInfo?.billing_tel}`, 10, currentY);
      currentY += lineHeight;
      doc.text(`Email: ${item?.paymentInfo?.billing_email}`, 10, currentY);
      currentY += lineHeight;
      doc.text(`City: ${item?.paymentInfo?.billing_city}`, 10, currentY);
      currentY += lineHeight;
      doc.text(`State: ${item?.paymentInfo?.billing_state}`, 10, currentY);
      currentY += lineHeight;
      doc.text(`Country: ${item?.paymentInfo?.billing_country}`, 10, currentY);

      const inputDate = new Date(item?.paymentInfo?.createdAt);
      const options = {
        day: "2-digit",
        month: "short",
        year: "numeric",
      };
      const formattedDate = inputDate.toLocaleDateString("en-IN", options);
      const gstAmount = Number(item?.paymentInfo?.amount) * 0.18
      // Prepare table data
      const orderData = {
        orderid: item._id.slice(0, 7).toUpperCase(),
        Subscription: item?.planName,
        CourseIncluded: item?.planId?.courseBundles.map((v) => `${v.title}\n${v.selectedCourses.map((selectedItem) => `    • ${selectedItem.title}`).join("\n")}`).join("\n"), // Concatenate categories and titles
        formattedDate: moment(item?.paymentInfo?.trans_date, 'DD/MM/YYYY HH:mm:ss').format("DD/MM/YYYY"),
        Rate: `${item?.paymentInfo?.amount ? `Rs. ${item?.paymentInfo?.amount}`: "-"}`,
      };

      const tableData = [
        [
          orderData.orderid,
          orderData.Subscription,
          orderData.CourseIncluded,
          orderData.formattedDate,
          orderData.Rate,
        ],
      ];

      // Create the table
      doc.autoTable({
        head: [
          ["Order ID", "Subscription", "Courses Included", "Date", "MRP"],
        ],
        body: tableData,
        startY: 120, // Adjust the starting position as needed
      });

      // Save the PDF
      doc.save("subscriptionInvoice.pdf");
    }
  };

  setInterval(() => {
    autoLogOut();
  }, 1000);

  const autoLogOut = () => {
    if (localStorage.getItem("oAuth")) {
      const jwt_Token_decoded = jwt_decode(localStorage.getItem("oAuth"));
      if (jwt_Token_decoded.exp * 1000 < Date.now()) {
        localStorage.removeItem("oAuth");
        localStorage.clear();
        navigate("/");
        dispatch(isLogin(false));
        toast.error("Session expired.Please login to proceed");
      }
    }
  };
  const handleLogOut = () => {
    localStorage.removeItem("oAuth");
    localStorage.removeItem("isLogin");
    localStorage.removeItem("UserCreatedDate");
    localStorage.removeItem("collegeId");
    navigate("/");
    dispatch(isLogin(false));
    toast.success("You have logged out successfully");
  };

  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    // Get the current URL path
    const currentPath = window.location.pathname;
    setActiveTab(currentPath);
  }, []);

  return (
    <div className="ProFILE">
      <div className="PageHEaders">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PageTi pt-5">
                <h2>Profile</h2>
                <p>
                  <Link to="/">Home</Link>{" "}
                  <i>
                    <FaAngleRight />
                  </i>
                  <Link to="/profile">Profile</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ProfileTabs pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12">
              {!personalData.isExUser && personalData.isEnrolledInDirectTraining ?
                <div className="SIdeBar">
                  <Nav defaultActiveKey="/dashboard" className="flex-column">
                    <Nav.Link
                      className={activeTab === "/profile" ? "Active_tab" : ""} as={Link}
                    >
                      <Link to="/profile">My Profile</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/wishlist" ? "Active_tab" : ""}
                    >
                      <Link to="/wishlist">Wishlist</Link>
                    </Nav.Link>
                    {personalData.isEnrolledInDirectTraining
                      &&
                      <Nav.Link
                        className={
                          activeTab === "/training_history" ? "Active_tab" : ""
                        }
                      >
                        <Link to="/training_history">Direct Training Orders</Link>{" "}
                      </Nav.Link>}
                    <Nav.Link className={activeTab === "/" ? "Active_tab" : ""}>
                      {" "}
                      <Link to="/" onClick={handleLogOut}>
                        Log Out
                      </Link>
                    </Nav.Link>
                  </Nav>
                </div> :
                <div className="SIdeBar">
                  <Nav defaultActiveKey="/dashboard" className="flex-column">
                    <Nav.Link
                      className={activeTab === "/dashboard" ? "Active_tab" : ""}
                    >
                      {" "}
                      <Link to="/dashboard">Dashboard</Link>{" "}
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/subscribe" ? "Active_tab" : ""}
                    >
                      <Link to="/subscribe">My Subscription</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/mycourse" ? "Active_tab" : ""}
                    >
                      {" "}
                      <Link to="/mycourse">My Course</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={
                        activeTab === "/orderhistory" ? "Active_tab" : ""
                      }
                    >
                      <Link to="/orderhistory">Order History</Link>{" "}
                    </Nav.Link>
                    {personalData.isEnrolledInDirectTraining
                      &&
                      <Nav.Link
                        className={
                          activeTab === "/training_history" ? "Active_tab" : ""
                        }
                      >
                        <Link to="/training_history">Direct Training Orders</Link>{" "}
                      </Nav.Link>}
                    <Nav.Link
                      className={activeTab === "/wishlist" ? "Active_tab" : ""}
                    >
                      <Link to="/wishlist">Wishlist</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/profile" ? "Active_tab" : ""}
                    >
                      {" "}
                      <Link style={{ width: "100%" }} to="/profile">My Profile</Link>
                    </Nav.Link>
                    <Nav.Link className={activeTab === "/" ? "Active_tab" : ""}>
                      {" "}
                      <Link to="/" onClick={handleLogOut}>
                        Log Out
                      </Link>
                    </Nav.Link>
                  </Nav>
                </div>
              }
            </div>
            <div className="col-lg-9 col-md-12">
              {subsHisData && subsHisData.length > 0 &&
                <>
                  <h4>Subscription Orders</h4>
                  <div className="OrderTABle">
                    <Table responsive className="align-middle">
                      <thead>
                        <tr>
                          <th>Order ID</th>
                          <th>Subscription</th>
                          <th>Course Included</th>
                          <th>Date</th>
                          <th>Rate</th>
                          <th>Invoice</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subsHisData && subsHisData.map((item, index) => {
                          let gstAmount = parseInt(item?.paymentInfo?.amount) * 0.18
                          return (
                            <tr key={index} >
                              <td >#{item?._id.slice(0, 7).toUpperCase()}</td>
                              <td className="text-center">{item?.planName}</td>
                              <td>
                                {item?.planId?.courseBundles.map((v, i) => (
                                  <div key={i}>
                                    <strong style={{ marginLeft: "20%" }}>{v?.title}</strong>
                                    <ul style={{ listStyleType: "none", marginLeft: "25%" }}>
                                      {v?.selectedCourses.map((selectedItem, selectedIndex) => (
                                        <li key={selectedIndex}>• {selectedItem?.title}</li>
                                      ))}
                                    </ul>
                                  </div>
                                ))}
                              </td>
                              <td className="text-center">{moment(item?.paymentInfo?.trans_date, 'DD/MM/YYYY HH:mm:ss').format("DD/MM/YYYY")}</td>
                              <td className="text-center">{item?.paymentInfo?.amount ? `₹ ${item?.paymentInfo?.amount}`: "-"}</td>
                              <td className="text-center">
                                <button
                                  className="DOwnLoaDBtN"
                                  onClick={() => generatePDF(item, "subscription")}
                                >
                                  <i>
                                    <AiOutlineDownload />
                                  </i>
                                </button>
                              </td>
                            </tr>
                          )
                        })}

                      </tbody>
                    </Table>
                  </div></>}
              {historyData && historyData.length > 0 &&
                <>
                  <h4>Orders</h4>
                  <div className="OrderTABle">
                    <Table responsive className="align-middle">
                      <thead >
                        <tr>
                          <th>Order ID</th>
                          <th>Category</th>
                          <th>Course</th>
                          <th>Date</th>
                          <th>Rate</th>
                          <th>Invoice</th>
                        </tr>
                      </thead>
                      <tbody>
                        {historyData &&
                          historyData.map((item, index) => {
                            const inputDate = new Date(item?.createdAt);
                            const options = {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            };
                            const formattedDate = inputDate.toLocaleDateString(
                              "en-IN",
                              options
                            );

                            return (
                              <tr key={item._id} ref={pdfRef}>
                                <td>#{item?._id.slice(0, 7).toUpperCase()}</td>
                                <td>
                                  {" "}
                                  {item?.items?.map((v, i) => {
                                    return <li>{v?.category}</li>;
                                  })}
                                </td>

                                <td>
                                  {item?.items?.map((v, i) => {
                                    return <li>{v?.title}</li>;
                                  })}
                                </td>
                                <td>{formattedDate}</td>
                                <td>₹ {item?.paymentDetails?.paidAmount.toFixed(2)}</td>
                                <td>
                                  <button
                                    className="DOwnLoaDBtN"
                                    onClick={() => generatePDF(item, "orders")}
                                  >
                                    <i>
                                      <AiOutlineDownload />
                                    </i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div> </>}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Orderhistory;
