import React, { useEffect } from "react";
import "./Activation.css";
import like from "../../assets/images/like.png";
import { Link } from "react-router-dom";

const PaymentSuccess = () => {
  return (
    <div className="ProFILE">
      <div className="ProfileTabs pt-5 pb-5 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="SuceSSBoxCouRs activation mb-3">
                <div className="SuccImg">
                  <img src={like} className="img-fluid" />
                </div>
                <h4>CONGRATULATIONS</h4>
                <h5>Your order has been placed successfully</h5>
              </div>
            </div>

            <div className="col-12 mt-5">
              <div className="SuceeLink">
                <Link to="/training_history">VIEW ORDER DETAILS</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentSuccess;
