import React, { useEffect, useState } from "react";
import "./Profile.css";
import { Link, useNavigate, useParams } from "react-router-dom";

import { FaAngleRight } from "react-icons/fa";
import Nav from "react-bootstrap/Nav";
import { AiFillStar } from "react-icons/ai";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { isLogin, loader } from "../../redux/common";
import { toast } from "react-toastify";
import {
  activePlanList,
  cancelRenewal,
  subscribedPlanInfo,
} from "../../redux/courses";
import { userPersonalDetail } from "../../redux/profile";

const Subscribe = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscriptionPlanId = useParams();
  const [subscrbdPlan, setSubscrbdPlan] = useState({});
  const [personalData, setPersonalData] = useState([])
  let [refresher, setRefresher] = useState(true);
  setInterval(() => {
    autoLogOut();
  }, 1000);

  const autoLogOut = () => {
    if (localStorage.getItem("oAuth")) {
      const jwt_Token_decoded = jwt_decode(localStorage.getItem("oAuth"));
      if (jwt_Token_decoded.exp * 1000 < Date.now()) {
        localStorage.removeItem("oAuth");
        localStorage.clear();
        navigate("/");
        dispatch(isLogin(false));
        toast.error("Session expired.Please login to proceed");
      }
    }
  };
  const handleLogOut = () => {
    localStorage.removeItem("oAuth");
    localStorage.removeItem("isLogin");
    localStorage.removeItem("UserCreatedDate");
    localStorage.removeItem("collegeId");
    navigate("/");
    dispatch(isLogin(false));
    toast.success("You have logged out successfully");
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };
  const cancelPlan = (id) => {
    let data = {};
    data["_id"] = id;

    dispatch(
      cancelRenewal(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          navigate("/");
          setRefresher(!refresher);
        } else {
          toast.error(response.message);
        }
      })
    );
  };

  useEffect(() => {
    if (
      localStorage.getItem("isLogin") == null ||
      localStorage.getItem("isLogin") == false
    ) {
      dispatch(isLogin(false));
    } else {
      dispatch(isLogin(true));
    }

    dispatch(loader(true));

    dispatch(
      subscribedPlanInfo((resp) => {
        if (resp.status) {
          const formattedDate = formatDate(resp.data.renewalDate); // Format renewal date
          resp.data.renewalDate = formattedDate;
          setSubscrbdPlan(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          // toast.error(resp.message);
        }
      })
    );
    dispatch(
      userPersonalDetail((resp) => {
        if (resp.status) {
          setPersonalData(resp.data)
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, []);

  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    // Get the current URL path
    const currentPath = window.location.pathname;
    setActiveTab(currentPath);
  }, []);

  const handleClick = () => {
    navigate('/', { state: { scrollTo: 'subsDivNew' } });
  };


  return (
    <div className="ProFILE">
      <div className="PageHEaders">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PageTi pt-5">
                <h2>Profile</h2>
                <p>
                  <Link to="/">Home</Link>{" "}
                  <i>
                    <FaAngleRight />
                  </i>
                  <Link to="/profile">Profile</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ProfileTabs pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-5">
              {!personalData.isExUser && personalData.isEnrolledInDirectTraining ?
                <div className="SIdeBar">
                  <Nav defaultActiveKey="/dashboard" className="flex-column">
                    <Nav.Link
                      className={activeTab === "/profile" ? "Active_tab" : ""} as={Link}
                    >
                      <Link to="/profile">My Profile</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/wishlist" ? "Active_tab" : ""}
                    >
                      <Link to="/wishlist">Wishlist</Link>
                    </Nav.Link>
                    {personalData.isEnrolledInDirectTraining
                      &&
                      <Nav.Link
                        className={
                          activeTab === "/training_history" ? "Active_tab" : ""
                        }
                      >
                        <Link to="/training_history">Direct Training Orders</Link>{" "}
                      </Nav.Link>}
                    <Nav.Link className={activeTab === "/" ? "Active_tab" : ""}>
                      {" "}
                      <Link to="/" onClick={handleLogOut}>
                        Log Out
                      </Link>
                    </Nav.Link>
                  </Nav>
                </div> :
                <div className="SIdeBar">
                  <Nav defaultActiveKey="/dashboard" className="flex-column">
                    <Nav.Link
                      className={activeTab === "/dashboard" ? "Active_tab" : ""}
                    >
                      {" "}
                      <Link to="/dashboard">Dashboard</Link>{" "}
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/subscribe" ? "Active_tab" : ""}
                    >
                      <Link to="/subscribe">My Subscription</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/mycourse" ? "Active_tab" : ""}
                    >
                      {" "}
                      <Link to="/mycourse">My Course</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={
                        activeTab === "/orderhistory" ? "Active_tab" : ""
                      }
                    >
                      <Link to="/orderhistory">Order History</Link>{" "}
                    </Nav.Link>
                    {personalData.isEnrolledInDirectTraining
                      &&
                      <Nav.Link
                        className={
                          activeTab === "/training_history" ? "Active_tab" : ""
                        }
                      >
                        <Link to="/training_history">Direct Training Orders</Link>{" "}
                      </Nav.Link>}
                    <Nav.Link
                      className={activeTab === "/wishlist" ? "Active_tab" : ""}
                    >
                      <Link to="/wishlist">Wishlist</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/profile" ? "Active_tab" : ""}
                    >
                      {" "}
                      <Link style={{ width: "100%" }} to="/profile">My Profile</Link>
                    </Nav.Link>
                    <Nav.Link className={activeTab === "/" ? "Active_tab" : ""}>
                      {" "}
                      <Link to="/" onClick={handleLogOut}>
                        Log Out
                      </Link>
                    </Nav.Link>
                  </Nav>
                </div>
              }
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12">
              {Object.keys(subscrbdPlan).length !== 0 &&
                subscrbdPlan.isActive == true ? (
                <div className="SideContEnt subsNew">
                  <h6>Current Plan</h6>
                  <div className="BoxSideCon">
                    <h4>
                      KAREERSITY{" "}
                      <i>
                        <AiFillStar />
                      </i>{" "}
                      <span>{subscrbdPlan.planName.toUpperCase()} PLAN</span>
                    </h4>
                    <div className="d-flex SuB">
                      {/* <p>
                        Next payment {subscrbdPlan.price} INR on{" "}
                        {subscrbdPlan.renewalDate}.
                      </p> */}
                      {/* <Link
                        to="/subscribe"
                        onClick={() => cancelPlan(subscrbdPlan._id)}
                      >
                        Cancel Renewal
                      </Link> */}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="SideContEnt sub text-center">
                  <h6 className="mb-5">Please subscribe to a plan.</h6>

                  <button onClick={handleClick}>Subscriptions Plan</button>

                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Subscribe;
