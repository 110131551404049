import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    window.location.href = `https://expertonboard.org/payment/?status=SUCCESS&order_id=${searchParams.get(
      "order_id"
    )}`;
  }, []);

  return (
    <div>
      <p>Redirecting...</p>
    </div>
  );
};

export default PaymentSuccess;
