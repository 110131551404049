import React, { useEffect } from "react";
import "./Activation.css";
import pswd from "../../assets/images/pswd.png";
import { Link } from "react-router-dom";
import { accountPasswordCb } from "../../redux/user";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loader } from "../../redux/common";
import { toast } from "react-toastify";
const Passwordreseted = () => {
  const { id } = useParams();
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      accountPasswordCb(id, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, []);
  return (
    <div className="ProFILE">
      <div className="ProfileTabs pt-5 pb-5 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="SuceSSBoxCouRs activation mb-3">
                <div className="SuccImg">
                  <img src={pswd} className="img-fluid" />
                </div>
                <h4>Your Password Reset Succesfully</h4>
              </div>
            </div>

            <div className="col-12 mt-5">
              <div className="SuceeLink">
                <Link to="/">Back to Home</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Passwordreseted;
