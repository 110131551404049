import React, { useEffect, useState } from "react";
import './Profile.css';
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { HiThumbUp } from "react-icons/hi";
import Nav from 'react-bootstrap/Nav';

const Assessmentsucess = () => {
    const [activeTab, setActiveTab] = useState("");

    useEffect(() => {
      // Get the current URL path
      const currentPath = window.location.pathname;
      setActiveTab(currentPath);
    }, []);  
    return (
        <div className='ProFILE'>
            
            <div className='PageHEaders'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='PageTi pt-5'>

                                <h2>Profile</h2>
                                <p><Link to="/">Home</Link> <i><FaAngleRight /></i><Link to="/profile">Profile</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ProfileTabs pt-5 pb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-3 col-sm-5'>
                        <div className="SIdeBar">
                <Nav defaultActiveKey="/dashboard" className="flex-column">
                  <Nav.Link  className={activeTab === "/dashboard" ? "Active_tab" : ""}>
                    {" "}
                    <Link to="/dashboard">Dashboard</Link>{" "}
                  </Nav.Link>
                  <Nav.Link  className={activeTab === "/subscribe" ? "Active_tab" : ""}>
                    <Link to="/subscribe">My Subscription</Link>
                  </Nav.Link>
                  <Nav.Link  className={activeTab === "/mycourse" ? "Active_tab" : ""}>
                    {" "}
                    <Link to="/mycourse">My Course</Link>
                  </Nav.Link>
                  <Nav.Link  className={activeTab === "/orderhistory" ? "Active_tab" : ""}>
                    <Link to="/orderhistory">Order History</Link>{" "}
                  </Nav.Link>
                  <Nav.Link  className={activeTab === "/training_history" ? "Active_tab" : ""}>
                    <Link to="/training_history">Direct Training Orders</Link>{" "}
                  </Nav.Link>
                  <Nav.Link  className={activeTab === "/wishlist" ? "Active_tab" : ""}>
                    <Link to="/wishlist">Wishlist</Link>
                  </Nav.Link>
                  <Nav.Link  className={activeTab === "/profile" ? "Active_tab" : ""}>
                    {" "}
                    <Link to="/profile">My Profile</Link>
                  </Nav.Link>
                  <Nav.Link  className={activeTab === "/" ? "Active_tab" : ""}>
                    {" "}
                    <Link to="/">
                      Log Out
                    </Link>
                  </Nav.Link>
                </Nav>
              </div>
                        </div>
                        <div className='col-lg-9 col-md-9'>
                            <div className='SuceSSBox'>
                                <i><HiThumbUp/></i>
                                <h4>Thanks for attending assessment</h4>
                                <h5>YOUR SCORE IS 14 / 15</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}
export default Assessmentsucess