import React from "react";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";

const Refund = () => {
  return (
    <div className="AbouTPAge">
      <div className="PageHEaders">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PageTi pt-5">
                <h2>Cancellation / Refund policy</h2>
                <p>
                  <Link to="/">Home</Link>{" "}
                  <i>
                    <FaAngleRight />
                  </i>
                  <Link to="/refund">Cancellation / Refund policy</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="policysect pt-5 pb-5">
        <div className="container">
          <p>
            You may request for a cancellation and refund request by writing to
            us at <a href="mailto:askus@kareersity.com">askus@kareersity.com</a>{" "}
            after purchasing any cohort courses
          </p>
          <p>
            KareerSity will evaluate the reason provided by you and take a
            decision on the request
          </p>
          <p>
            KareerSity may contact you if it needs any additional information
            with regards to your request
          </p>
          <p>
            Refunds can be processed only if your cohort courses have not
            started, and your request reaches us at least before 48 hours of any
            scheduled courses.
          </p>
          <p>
            No refund will be processed once you start pursuing any cohort
            courses with KareerSity.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Refund;
