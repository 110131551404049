import React, { useState, useEffect } from "react";
import "./Course.css";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { FaStarHalf, FaStar } from "react-icons/fa";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { HiChevronDoubleRight } from "react-icons/hi";
import { BsSuitHeart } from "react-icons/bs";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addOrRemoveFromWishList,
  addToCart,
  categoryList,
  listOfActiveCourses,
} from "../../redux/courses";
import { toast } from "react-toastify";
import { loader, isLogin, cartCount } from "../../redux/common";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import {
  collegeList,
  userAcademicDetail,
  userCartWishNameInfo,
  userPersonalDetail,
} from "../../redux/profile";

const Course = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let [refresher, setRefresher] = useState(true);
  const [courseList, setCourseList] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [ratingsData, setRatingsData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedSort, setSelectedSort] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [isAddedToWishlist, setIsAddedToWishlist] = useState([]);
  const [collegeData, setCollegeData] = useState([]);
  const cartCountval = useSelector((state) => state.apiReducer.cartCount);
  const isLoginStatus = useSelector((state) => state.apiReducer.isLogin);

  const location = useLocation();
  const homeCatId = location.state && location.state.categoryId;

  useEffect(() => {
    const matchingCategory = categoryData.find(
      (category) => category._id === homeCatId
    );
    if (matchingCategory) {
      setSelectedCategory([matchingCategory._id]);

      const data = {
        selectedCategories: matchingCategory._id,
        userType: localStorage.getItem("userType") || "student",
        corporate: localStorage.getItem("corporateId"),
      };

      dispatch(
        listOfActiveCourses(data, (resp) => {
          dispatch(loader(true));
          if (resp.status) {
            console.log(resp, "courselist");
            setCourseList(resp.data);
            setTotalPages(resp.totalPages);
            dispatch(loader(false));
          } else {
            setCourseList([]);
            dispatch(loader(false));
          }
        })
      );
    } else {
      setSelectedCategory([]);

      let data = {
        userType: localStorage.getItem("userType") || "student",
        corporate: localStorage.getItem("corporateId"),
      };
      dispatch(
        listOfActiveCourses(data, (resp) => {
          dispatch(loader(true));
          if (resp.status) {
            setCourseList(resp.data);
            setTotalPages(resp.totalPages);
            dispatch(loader(false));
          } else {
            setCourseList([]);
            toast.error(resp.message);
            dispatch(loader(false));
          }
        })
      );
    }
  }, [homeCatId, categoryData]);

  useEffect(() => {
    let data = {};

    if (
      localStorage.getItem("isLogin") == null ||
      localStorage.getItem("isLogin") == false
    ) {
      dispatch(isLogin(false));
    } else {
      dispatch(isLogin(true));
    }

    dispatch(
      userCartWishNameInfo((resp) => {
        if (resp.status) {
          if (courseList.length > 0) {
            const wishlistCourseIds = resp.data.favorite;
            const filteredCourses = courseList.filter((course) =>
              wishlistCourseIds.includes(course._id)
            );
            setIsAddedToWishlist(filteredCourses);
          } else {
            setIsAddedToWishlist([]); // Ensure isAddedToWishlist is reset to an empty array if courseList is empty
          }
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          // toast.error(resp.message);
        }
      })
    );

    dispatch(loader(true));
    dispatch(
      collegeList((resp) => {
        dispatch(loader(false));
        if (resp.status) {
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
    dispatch(
      userPersonalDetail((resp) => {
        if (resp?.status) {
          localStorage.setItem("UserCreatedDate", resp.data.createdAt);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
    dispatch(
      userAcademicDetail((resp) => {
        if (resp.status) {
          localStorage.setItem("AcademicInfo", collegeData.length);
          console.log(resp);
          setCollegeData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          //toast.error(resp.message);
        }
      })
    );
  }, [isLoginStatus, courseList, cartCountval]);

  const handleCategoryChange = (event) => {
    const categoryName = event.target.value;
    setSelectedCategory((prevSelectedCategories) => {
      let updatedCategories;
      if (prevSelectedCategories.includes(categoryName)) {
        // If the category is already selected, remove it
        updatedCategories = prevSelectedCategories.filter(
          (category) => category !== categoryName
        );
      } else {
        // Otherwise, add it to the selected categories
        updatedCategories = [...prevSelectedCategories, categoryName];
      }

      // Reset price and level filters to "All" when category selection changes
      setSelectedPrice("");
      setSelectedLevel("");

      // If updatedCategories is empty, fetch all courses
      if (updatedCategories.length === 0) {
        // Dispatch the API request to fetch all courses
        fetchCoursesByPage(0);
      } else {
        // Call the API to fetch courses for the updated categories
        const data = {
          selectedCategories: updatedCategories,
          userType: localStorage.getItem("userType") || "student",
          corporate: localStorage.getItem("corporateId"),
        };

        // Dispatch the API request to fetch courses for the updated categories
        dispatch(
          listOfActiveCourses(data, (resp) => {
            dispatch(loader(true));
            if (resp.status) {
              setCourseList(resp.data);
              setTotalPages(resp.totalPages);
              dispatch(loader(false));
            } else {
              setCourseList([]);
              // toast.error(resp.message);
              dispatch(loader(false));
            }
          })
        );
      }
      // Return the updated categories
      return updatedCategories;
    });
  };

  const handlePriceChange = (event) => {
    const selectedPriceOption = event.target.value;
    setSelectedPrice(selectedPriceOption);
    const filters = {
      price: selectedPriceOption,
      level: selectedLevel,
      low_to_high: selectedSort === "lowtohigh" ? 1 : undefined,
      high_to_low: selectedSort === "hightolow" ? 1 : undefined,
      newest_to_oldest: selectedSort === "newest" ? 1 : undefined,
      oldest_to_newest: selectedSort === "oldest" ? 1 : undefined,
    };
    SortFunc(filters);
  };

  const handleFilterByLevel = (event) => {
    const selectedLevelOption = event.target.value;
    // Create a filters object with the selected level
    setSelectedLevel(selectedLevelOption);
    const filters = {
      level: selectedLevelOption,
      price: selectedPrice, // Include other filter options if needed
      low_to_high: selectedSort === "lowtohigh" ? 1 : undefined,
      high_to_low: selectedSort === "hightolow" ? 1 : undefined,
      newest_to_oldest: selectedSort === "newest" ? 1 : undefined,
      oldest_to_newest: selectedSort === "oldest" ? 1 : undefined,
    };
    // Call the SortFunc with the filters
    SortFunc(filters);
  };
  const SortFunc = (filters) => {
    // Create an empty object for the API request data
    const data = {
      level: filters.level,
      price: filters.price,
      ...(selectedCategory.length > 0 && {
        selectedCategories: selectedCategory,
      }),
      low_to_high: filters.low_to_high,
      high_to_low: filters.high_to_low,
      newest_to_oldest: filters.newest_to_oldest,
      oldest_to_newest: filters.oldest_to_newest,
      userType: localStorage.getItem("userType") || "student",
      corporate: localStorage.getItem("corporateId"),
    };

    dispatch(
      listOfActiveCourses(data, (resp) => {
        dispatch(loader(true));
        if (resp.status) {
          setCourseList(resp.data);
          setTotalPages(resp.totalPages);
          dispatch(loader(false));
        } else {
          setCourseList([]);
          dispatch(loader(false));
        }
      })
    );
  };

  const handleSortChange = (event) => {
    const selectedSortOption = event.target.value;
    // Create a filters object with the selected sort option
    setSelectedSort(selectedSortOption);
    if (selectedSortOption === "atoz") {
      sortAtoZ();
    } else if (selectedSortOption === "ztoa") {
      sortZtoA();
    } else {
      const filters = {
        sortOption: selectedSortOption,
        level: selectedLevel, // Include other filter options if needed
        price: selectedPrice,
        low_to_high: selectedSortOption === "lowtohigh" ? 1 : undefined,
        high_to_low: selectedSortOption === "hightolow" ? 1 : undefined,
        newest_to_oldest: selectedSortOption === "newest" ? 1 : undefined,
        oldest_to_newest: selectedSortOption === "oldest" ? 1 : undefined,
      };
      SortFunc(filters);
    }
    // Call the SortFunc with the filters
  };
  const sortAtoZ = () => {
    setSelectedSort("atoz");

    const sortedCourses = courseList.slice().sort((a, b) => {
      return a.title.localeCompare(b.title);
    });

    setCourseList(sortedCourses);
  };

  const sortZtoA = () => {
    setSelectedSort("ztoa");

    const sortedCourses = courseList.slice().sort((a, b) => {
      return b.title.localeCompare(a.title);
    });

    setCourseList(sortedCourses);
  };

  // Define a function to fetch data from the API based on the page number
  const fetchCoursesByPage = (pageNumber) => {
    let data = {
      userType: localStorage.getItem("userType") || "student",
      corporate: localStorage.getItem("corporateId"),
    };
    data["pageNo"] = pageNumber + 1;
    setSelectedSort("Default");
    dispatch(
      listOfActiveCourses(data, (resp) => {
        dispatch(loader(true));
        if (resp.status) {
          setCourseList(resp.data);
          setTotalPages(resp.totalPages);
          dispatch(loader(false));
        } else {
          toast.error(resp.message);
          dispatch(loader(false));
        }
      })
    );
  };

  function convertExpiryToDate(expiryTimestamp) {
    const expiryDate = new Date(expiryTimestamp);
    return expiryDate;
  }

  function isDiscountValid(discountedPriceExpiryTimestamp) {
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime < discountedPriceExpiryTimestamp;
  }
  console.log(collegeData);
  function isCourseFree(item) {
    const currentTime = Math.floor(Date.now() / 1000);
    const userCreationDate = localStorage.getItem("UserCreatedDate");
    const expiryDate = convertExpiryToDate(userCreationDate);
    const collegeId1 = collegeData?.collegeId;
    const thirtyDaysInSeconds = 30 * 24 * 60 * 60; // 30 days in seconds
    console.log("collegeId1:", collegeId1);
    console.log("item.freeColleges:", item.freeColleges);
    console.log("item:", item);
    if (item.freeForEveryone) {
      return true; // Course is free for everyone
    } else if (
      item.freeForEnInLast30Days &&
      currentTime - expiryDate.getTime() <= thirtyDaysInSeconds
    ) {
      return true; // Course is free for users who registered in the last 30 days
    } else if (
      collegeData &&
      item.freeForbasedOnColleges &&
      item.freeColleges != null &&
      item.freeColleges.includes(collegeId1)
    ) {
      return true; // Course is free for users based on their college
    } else {
      return false; // Course is not free and discount has expired or not applicable
    }
  }
  useEffect(() => {
    dispatch(
      categoryList((resp) => {
        if (resp.status) {
          setCategoryData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [refresher]);

  const addCart = (id, index) => {
    let data = {};
    data["courseId"] = id;

    dispatch(
      addToCart(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          setRefresher(!refresher);
          navigate("/cart");
          dispatch(cartCount(!cartCountval));
        } else {
          toast.error(response.message);
        }
      })
    );
  };

  const addWishlist = (id) => {
    let data = {};
    data["courseId"] = id;

    dispatch(
      addOrRemoveFromWishList(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          dispatch(cartCount(!cartCountval));
        } else {
          toast.error(response.message);
        }
      })
    );
  };

  return (
    <div className="CoUrse">
      <div className="CouRSE PAGE">
        <div className="PageHEaders">
          <div className="container">
            <div className="row">
              <div className="col-7">
                <div className="PageTi pt-5">
                  <h2>Course</h2>
                  <p>
                    <Link to="/">Home</Link>{" "}
                    <i>
                      <FaAngleRight />
                    </i>
                    <Link to="/course">Course</Link>
                  </p>
                </div>
              </div>
              <div className="col-5 pt-5">
                <div className="SoRtForm">
                  <form>
                    <label htmlFor="sort">Sort by</label>
                    <br />
                    <select
                      id="sort"
                      value={selectedSort}
                      onChange={handleSortChange}
                    >
                      <option value="">Default</option>
                      <option value="atoz">A to Z</option>
                      <option value="ztoa">Z to A</option>
                      <option value="lowtohigh">Low to High</option>
                      <option value="hightolow">High to Low</option>
                      <option value="oldest">Oldest to Newest</option>
                      <option value="newest">Newest to Oldest</option>
                    </select>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="CORseFilter">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-12">
                <div className="FIlTcou">
                  <h4>Categories</h4>
                  <hr />
                  <form>
                    {categoryData
                      .filter((item) => item.isHidden)
                      .map((item, index) => (
                        <div key={item._id}>
                          <div className="FILFoRm d-flex">
                            <input
                              type="checkbox"
                              defaultValue={item._id}
                              onChange={handleCategoryChange}
                              checked={selectedCategory.includes(item._id)}
                            />
                            <label>{item.name}</label>
                            <br />
                          </div>
                        </div>
                      ))}
                  </form>

                  <h4>Price</h4>
                  <hr />
                  <form>
                    <div className="FILFoRm d-flex">
                      <input
                        type="checkbox"
                        value=""
                        onChange={handlePriceChange}
                        checked={selectedPrice === ""}
                      />
                      <label> All</label>
                      <br />
                    </div>
                    <div className="FILFoRm d-flex">
                      <input
                        type="checkbox"
                        value="Free"
                        onChange={handlePriceChange}
                        checked={selectedPrice === "Free"}
                      />
                      <label>Free</label>
                      <br />
                    </div>
                    <div className="FILFoRm d-flex">
                      <input
                        type="checkbox"
                        defaultValue="Paid"
                        onChange={handlePriceChange}
                        checked={selectedPrice === "Paid"}
                      />
                      <label> Paid</label>
                      <br />
                    </div>
                  </form>
                  <h4>Levels</h4>
                  <hr />
                  <form>
                    <div className="FILFoRm d-flex">
                      <input
                        type="checkbox"
                        value=""
                        onChange={handleFilterByLevel}
                        checked={selectedLevel === ""}
                      />
                      <label> All</label>
                      <br />
                    </div>
                    <div className="FILFoRm d-flex">
                      <input
                        type="checkbox"
                        value="Basic"
                        onChange={handleFilterByLevel}
                        checked={selectedLevel === "Basic"}
                      />
                      <label>Basic</label>
                      <br />
                    </div>
                    <div className="FILFoRm d-flex">
                      <input
                        type="checkbox"
                        value="Intermediate"
                        onChange={handleFilterByLevel}
                        checked={selectedLevel === "Intermediate"}
                      />
                      <label>Intermediate</label>
                      <br />
                    </div>
                    <div className="FILFoRm d-flex">
                      <input
                        type="checkbox"
                        value="Advance"
                        onChange={handleFilterByLevel}
                        checked={selectedLevel === "Advance"}
                      />
                      <label> Advance</label>
                      <br />
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-lg-9 col-md-8 col-sm-12">
                <div className="row">
                  {courseList?.length === 0 ? (
                    <div>No courses found</div>
                  ) : (
                    courseList?.map((item, index) => {
                      const fullStars = Math.floor(item.avgRating); // Number of full stars
                      const hasHalfStar = item.avgRating % 1 !== 0;
                      return (
                        <div
                          key={item._id}
                          className="col-xl-4 col-lg-6 col-md-10 col-sm-12"
                        >
                          <div className="FIlTcou RES">
                            <div className="MainCOurSEdIV CouR">
                              <div className="couRSeImg courseheight">
                                <img
                                  src={item.thumbnail}
                                  alt="Hepatology"
                                  className="img-fluid oragnsTop categriesImg"
                                />
                              </div>
                              <div className="COurseConTEnt Cour crse">
                                <h5>
                                  <Link
                                    to={`/course-detail/${
                                      item._id
                                    }/${item.title.replace(/ /g, "_")}`}
                                  >
                                    {item?.title}
                                  </Link>
                                </h5>
                                <span className="SAPnHr">
                                  <hr />
                                </span>

                                <div className="SCetionCourse d-flex CouR crs">
                                  <div className="RightCLcont d-flex crs striked">
                                    {isCourseFree(item) ? (
                                      <h4>FREE</h4>
                                    ) : (
                                      <>
                                        {item.discountedPrice > 0 &&
                                        isDiscountValid(
                                          item.discountedPriceExpiry
                                        ) ? (
                                          <>
                                            <div>
                                              <h4>₹{item.discountedPrice}</h4>
                                            </div>
                                            <div>
                                              <h5>
                                                <s>₹{item.price}</s>
                                              </h5>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div>
                                              <h4>₹{item.regularPrice}</h4>
                                            </div>
                                            <div>
                                              <h5>
                                                <s>₹{item.price}</s>
                                              </h5>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <h6>{item?.level}</h6>
                                  <h6 className="text-end">{item?.duration}</h6>
                                </div>

                                <span className="SAPnHr">
                                  <hr />
                                </span>
                                <div className="FinAlDIv d-flex CouR">
                                  {item.totalRatings > 0 ? (
                                    <>
                                      {[...Array(fullStars)].map(
                                        (star, starIndex) => (
                                          <FaStar
                                            key={starIndex}
                                            className="fa-star"
                                            style={{ color: "#ffc107" }}
                                          />
                                        )
                                      )}
                                      {hasHalfStar && (
                                        <FaStarHalf
                                          style={{ color: "#ffc107" }}
                                        />
                                      )}
                                      <div className="ratings">
                                        {item.avgRating} ({item.totalRatings})
                                      </div>
                                    </>
                                  ) : null}

                                  <Link
                                    to={`/course-detail/${
                                      item._id
                                    }/${item.title.replace(/ /g, "_")}`}
                                    onClick={() => window.scrollTo(0, 0)}
                                  >
                                    Learn More{" "}
                                    <i>
                                      <HiChevronDoubleRight />
                                    </i>
                                  </Link>
                                </div>
                                <span className="SAPnHr">
                                  <hr />
                                </span>

                                <div className="FinAlDIv d-flex CouR  SAP">
                                  <p>{"By " + item.educators}</p>
                                  <Link
                                    to="/"
                                    className="ADdcaRT"
                                    onClick={() => {
                                      addCart(item._id, index);
                                      window.scrollTo(0, 0);
                                    }}
                                  >
                                    {" "}
                                    <i className="ICONS pt-2">
                                      <AiOutlineShoppingCart />
                                    </i>{" "}
                                    Add To Cart{" "}
                                  </Link>
                                  <Link
                                    to="/"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      addWishlist(item._id);
                                      window.scrollTo(0, 0);
                                    }}
                                    style={{
                                      backgroundColor: isAddedToWishlist.some(
                                        (course) => course._id === item._id
                                      )
                                        ? "#107B38"
                                        : "transparent",
                                      display: "inline-block",
                                      padding: "5px 10px",
                                      borderRadius: "5px",
                                      textDecoration: "none",
                                      color: "#fff !important",
                                    }}
                                  >
                                    {isAddedToWishlist.some(
                                      (course) => course._id === item._id
                                    ) ? (
                                      <BsSuitHeart
                                        style={{
                                          fill: "#fff",
                                        }}
                                      />
                                    ) : (
                                      <BsSuitHeart
                                        style={{
                                          fill: "#107B38",
                                        }}
                                      />
                                    )}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>

                <div className="pagination-container">
                  <ReactPaginate
                    pageCount={totalPages}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    onPageChange={(selectedPage) =>
                      fetchCoursesByPage(selectedPage.selected)
                    }
                    containerClassName={"pagination-container"}
                    activeClassName={"active-page"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
