import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./JobListing.css";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { loader } from "../../redux/common";
import { useDispatch, useSelector } from "react-redux";
import { PiSuitcaseSimpleFill } from "react-icons/pi";
import { FaBuilding } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaClock } from "react-icons/fa";
import { FaCircleMinus } from "react-icons/fa6";
import { activeJobList, statesList } from "../../redux/subscription";
import moment from "moment";
const JobListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jobListData, setJobListData] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  let [refresher, setRefresher] = useState(true);
  const {
    register: register,
    formState: { errors: errors },
    reset: reset,
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });
  const [selectedFilters, setSelectedFilters] = useState({});

  useEffect(() => {
    fetchData(selectedFilters);
    fetchLocationOptions();
  }, [selectedFilters]);

  const fetchLocationOptions = () => {
    dispatch(loader(true));
    dispatch(
      statesList((resp) => {
        if (resp.status) {
          setLocationOptions(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const fetchData = (filters) => {
    const data = {};

    // Add selected filters to the data object
    if (selectedFilters.location !== "all") {
      data.location = selectedFilters.location;
    }
    if (selectedFilters.department !== "all") {
      data.department = selectedFilters.department;
    }
    if (selectedFilters.jobType !== "all") {
      data.jobType = selectedFilters.jobType;
    }
    dispatch(loader(true));
    dispatch(
      activeJobList(data, (resp) => {
        if (resp.status) {
          if (resp.data.length === 0) {
            toast.info("No jobs found");
          }
          setJobListData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          setJobListData([]);
        }
      })
    );
  };

  const handleLocationChange = (e) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      location: e.target.value,
    }));
  };

  const handleDepartmentChange = (e) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      department: e.target.value,
    }));
  };

  const handleJobTypeChange = (e) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      jobType: e.target.value,
    }));
  };

  const handleApplyClick = (jobId, title) => {
    navigate("/applyJob", { state: { jobId, title } });
  };

  const formatDate = (timestamp) => {
    const currentDate = new Date();
    const createdAtDate = new Date(timestamp);

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate.getTime() - createdAtDate.getTime();

    if (timeDifference < 0) {
      return "In the future";
    }

    const secondsDifference = Math.floor(timeDifference / 1000);
    const daysDifference = Math.floor(secondsDifference / (60 * 60 * 24));

    if (daysDifference === 0) {
      return "Today";
    } else if (daysDifference === 1) {
      return "1 day ago";
    } else {
      return `${daysDifference} days ago`;
    }
  };

  const expireFun = (date) => {
    const dateToCompare = moment(date);
    const currentDate = moment();

    if (dateToCompare.isSame(currentDate, "day")) {
      console.log(date);
      return "Expiring Today";
    } else if (moment(date).isBefore(moment())) {
      return "Expired";
    } else {
      return "";
    }
  };

  return (
    <div className="EDucaTorPage">
      <div className="job"></div>
      <div className="PageHeaders">
        <div className="container">
          <div className="row cnt">
            <div className="col-12">
              <h5>Career Opportunities</h5>
              <p>
                Explore our open roles for working totally remotely, from the
                office or <br />
                somewhere in between
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="pageJobList">
        <div className="container">
          <div className="filtersCnt  col-lg-12 col-md-12">
            <div className="fILTers d-flex">
              <div className="filtHead col-xl-2 col-lg-4 col-md-3 col-sm-2">
                <h5>Filter by</h5>
              </div>
              <div className="dropdown-container col-xl-7 col-lg-3 col-md-6 col-sm-7">
                <div className="dropdown-wrapper">
                  <select
                    id="location"
                    name="location"
                    onChange={handleLocationChange}
                  >
                    <hr />
                    <option value="all">All Locations</option>
                    {locationOptions.map((item) => (
                      <option>{item}</option>
                    ))}
                  </select>
                </div>
                <div className="dropdown-wrapper">
                  <select
                    id="department"
                    name="department"
                    onChange={handleDepartmentChange}
                  >
                    <option value="all">All Departments</option>
                    <option value="sales">Sales</option>
                    <option value="marketing">Marketing</option>
                    <option value="r&d">R & D</option>
                    <option value="manufacturing">Manufacturing</option>
                    <option value="medical">Medical</option>
                    <option value="admin">Admin</option>
                    <option value="hr">HR</option>
                    <option value="finance">Finance</option>
                    <option value="senior_management">Senior management</option>
                    <option value="others">Others</option>
                  </select>
                </div>
                <div className="dropdown-wrapper">
                  <select
                    id="jobType"
                    name="jobType"
                    onChange={handleJobTypeChange}
                  >
                    <option value="all">All Job Types</option>
                    <option value="full_time">Full Time</option>
                    <option value="part_time">Part Time</option>
                    <option value="internship">Internship</option>
                  </select>
                </div>
              </div>
              <div className="openPos col-xl-3 col-lg-3 col-md-3 col-sm-3">
                <p>{jobListData.length} Open Positions</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="jobContainer col-lg-12 col-md-12">
            {jobListData.length === 0 ? (
              <div className="noJobsFound">No Jobs Found</div>
            ) : (
              jobListData.map((item, index) => {
                const formatText = (text) => {
                  return text
                    .toLowerCase()
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ");
                };
                return (
                  <div className="jobListingCnt" key={index}>
                    <div className=" jobBox pt-5">
                      <div className="jobCntnt row">
                        <div className="col-10">
                          <div className="jobTitle row">
                            <h5 className="col-7">{item.title}</h5>
                            <span
                              className={` col-2 ${
                                item.jobType.length > 1
                                  ? "multiJobType"
                                  : "jobType"
                              }`}
                            >
                              {item.jobType.length > 1 ? (
                                <ul className="">
                                  {item.jobType.map((jt, jtIndex) => (
                                    <li key={jtIndex}>{formatText(jt)}</li>
                                  ))}
                                </ul>
                              ) : (
                                item.jobType.map((jt, jtIndex) =>
                                  formatText(jt)
                                )
                              )}
                            </span>
                          </div>
                          <div className="jobIcons">
                            <div className="listOne ">
                              <i>
                                <PiSuitcaseSimpleFill />
                              </i>
                              <span>
                                {item.jobType
                                  .map((jt, jtIndex) => formatText(jt))
                                  .join(", ")}
                              </span>
                              <i>
                                <FaBuilding />
                              </i>
                              <span>
                                {item.remote
                                  .map((rem, remIndex) => formatText(rem))
                                  .join(", ")}
                              </span>
                            </div>

                            <div className="listTwo ">
                              <i>
                                <FaLocationDot />
                              </i>
                              <span>
                                {item.location
                                  .map((loc, locIndex) => formatText(loc))
                                  .join(", ")}
                              </span>
                              <i>
                                <FaClock />
                              </i>{" "}
                              <span>{formatDate(item.createdAt)}</span>
                              {expireFun(item.lastDateForApply) !== "" && (
                                <Fragment>
                                  <i
                                    style={{
                                      color:
                                        expireFun(item.lastDateForApply) ===
                                        "Expired"
                                          ? "red"
                                          : "orange",
                                    }}
                                  >
                                    <FaCircleMinus />
                                  </i>{" "}
                                  <span
                                    style={{
                                      color:
                                        expireFun(item.lastDateForApply) ===
                                        "Expired"
                                          ? "red"
                                          : expireFun(item.lastDateForApply) ===
                                            "Expiring Today"
                                          ? "orange"
                                          : "",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {expireFun(item.lastDateForApply)}
                                  </span>
                                </Fragment>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-2 applyCnt">
                          <button
                            className="applyBtn"
                            onClick={() =>
                              handleApplyClick(item._id, item.title)
                            }
                            style={{
                              backgroundColor:
                                expireFun(item.lastDateForApply) === "Expired"
                                  ? "grey"
                                  : "",
                            }}
                            disabled={
                              expireFun(item.lastDateForApply) === "Expired"
                            }
                          >
                            Apply Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default JobListing;
