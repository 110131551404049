import React from "react";
import "./About.css";
import abt from "../../assets/images/abtpage.png";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import abtgirl from "../../assets/images/abtGirl.png";
import teacher from "../../assets/images/Integrity.png";
import plan from "../../assets/images/Innovation.png";
import industry from "../../assets/images/Collaboration.png";
import whyUs from "../../assets/images/Aboutus_img.png";
import exp from "../../assets/images/Adaptability.png";
import PSFW from "../../assets/images/PSFW.jpg";
import BCS from "../../assets/images/BCS.jpg";
import LC from "../../assets/images/LC.jpg";
import RAP from "../../assets/images/RAP.jpg";
import UPSKILL from "../../assets/images/UPSKILL.jpg";
import target1 from "../../assets/images/target1.png";
import target2 from "../../assets/images/target2.png";

const About = () => {
  return (
    <div className="AbouTPAge">
      <div className="PageHEaders">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PageTi pt-5">
                <h2>About Us</h2>
                <p>
                  <Link to="/">Home</Link>{" "}
                  <i>
                    <FaAngleRight />
                  </i>
                  <Link to="/about">About</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="AboutDIV pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 pt-3">
              <div className="PageHEader pt-4">
                <h3>Who are we</h3>
              </div>
              <div className="AbTContenT pt-2">
                <p>
                  KareerSity stands at the forefront of educational innovation
                  as a distinguished EdTech entity, uniquely positioned to
                  infuse real-world industry insights into pharmaceutical
                  education. Our genesis was driven by a singular aim, to
                  catalyze career trajectories by orchestrating an intellectual
                  symphony between the realms of academia and industry.
                </p>
                <p>
                  Our approach marks a radical departure from traditional
                  paradigms, pivoting from a supply-driven framework to a model
                  sculpted by the intricacies of demand. This transformative
                  shift ensures that as our students step into the professional
                  arena, they are not just participants but trailblazers, ready
                  and equipped from day one.
                </p>
                <p>
                  At the heart of KareerSity's ethos is empowerment. We don't
                  just educate; we elevate, channeling the potential of pharmacy
                  students through training programs crafted with precision and
                  depth. These initiatives are not mere courses but journeys,
                  meticulously designed to pave the way for a seamless and
                  triumphant immersion into the professional sphere.
                </p>
                <p>
                  Our mission transcends the boundaries of conventional
                  education. At KareerSity, we're not just shaping students;
                  we're molding future industry leaders. We're committed to
                  imbuing aspiring professionals with the essential skills,
                  knowledge, and confidence that don't just prepare them to face
                  the dynamic world of pharma but to redefine it.
                </p>
                <p>
                  Come and be a part of our vision to revolutionize
                  pharmaceutical education, where each student's journey is a
                  tale of triumph waiting to be told. Discover KareerSity, the
                  platform that kickstarts your journey in the pharmaceutical
                  industry.
                </p>
              </div>
            </div>
            <div className=" wrw_img col-lg-6 col-md-12">
              <img src={abt} className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="pillars mb-3">
        <div className="container">
          <div className="pillar_head mb-5">
            <div>
              Kareersity effortlessly addresses end-to-end business requirements
              by operating on
            </div>
            <span> FIVE IMPORTANT PILLARS:</span>
          </div>
          <div className=" pillar_container ">
            <div className="pillar_box ">
              <div>
                <img src={PSFW} className="img-fluid" />
              </div>
              <div className="pillarbox_head_1">
                Preparing students for the workforce
              </div>
              <p className="pillarbox_para_1">
                We focus on getting ready for the workforce by giving them the
                education and experience they need to succeed in the real world.
              </p>
            </div>
            <div className="pillar_box ">
              <div>
                <img src={UPSKILL} className="img-fluid" />
              </div>
              <div className="pillarbox_head_1">Upskilling</div>
              <p className="pillarbox_para_1">
                In order to keep up with the ever-changing demands of the
                sector, we are also committed to improving the skills of the
                people already working in the field of sales, marketing and
                leadership roles.
              </p>
            </div>
            <div className="pillar_box ">
              <div>
                <img src={RAP} className="img-fluid" />
              </div>
              <div className="pillarbox_head_1">Recruitment & Placement</div>
              <p className="pillarbox_para_1">
                Our extensive recruitment and placement services enable us to
                connect skilled individuals with fulfilling positions that match
                their abilities and goals.
              </p>
            </div>
            <div className="pillar_box ">
              <div>
                <img src={BCS} className="img-fluid" />
              </div>
              <div className="pillarbox_head_1">
                Business consulting Services
              </div>
              <p className="pillarbox_para_1">
                Alongside our training programs, we provide business consulting
                services to help clients with strategic planning and achieving
                success in today's competitive business environment.
              </p>
            </div>
            <div className="pillar_box ">
              <div>
                <img src={LC} className="img-fluid" />
              </div>
              <div className="pillarbox_head_1">Leadership coaching</div>
              <p className="pillarbox_para_1">
                Leadership Coaching: We offer comprehensive leadership coaching
                services to help individuals thrive in leadership positions. Our
                experienced coaches provide valuable guidance and support to
                help you excel in your role.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className=" why_us row d-flex">
        <div className="col-lg-6">
          <img src={whyUs} className="img-fluid" />
        </div>
        <div className=" whyus_cont col-lg-6">
          <h3>Why Us?</h3>
          <p>
            Choose KareerSity because we offer more than just an educational
            platform; we are dedicated to helping you achieve transformative
            success. We pride ourselves on our dedication to delivering
            exceptional results, pushing boundaries with creative solutions, and
            tailoring our services to meet your unique needs. We offer a
            distinctive combination of practical industry knowledge, innovative
            teaching methods, and a strong focus on our students. Our programs
            not only prepare students with the necessary skills for the
            workforce, but also cultivate a mindset of adaptability and
            leadership. We are proud of our comprehensive approach, which covers
            all aspects of educational and professional requirements. Whether
            you're a student looking to enter the workforce, a professional
            seeking to enhance your skills, or a business in need of expert
            guidance, KareerSity is here to be your trusted partner. We'll take
            you on a transformative journey towards a future filled with
            unparalleled success in the dynamic landscapes of industry and
            business. Experience the exceptional with KareerSity - your gateway
            to extraordinary possibilities.
          </p>
        </div>
      </div>
      <div className="WhatWeDO pt-5 pb-5">
        <div className="container cntBG">
          <div className="row">
            <div className="col-12 justify-content-center">
              <div className="PageHEader">
                <h3>Our Values</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3 Top-padding">
                  <div className="ABTimG_1">
                    <img src={plan} className="img-fluid" />
                  </div>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 Top-padding">
                  <div className="ABtcont pt-3">
                    <h5>Innovation</h5>
                    <p>
                      We prioritize fostering a culture of innovation. We are
                      constantly evolving our approaches to meet the
                      ever-changing demands of the industry and provide
                      effective solutions for professional growth.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row pt-5">
                <div className="col-lg-3 col-md-3 col-sm-3 paDiNg">
                  <div className="ABTimG">
                    <img src={industry} className="img-fluid" />
                  </div>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 paDiNg">
                  <div className="ABtcont pt-1">
                    <h5>Collaboration</h5>
                    <p>
                      We strongly believe in the importance of partnerships
                      between academia and industry, as well as between students
                      and educators, to create a well-rounded learning
                      experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <img src={abtgirl} className="img-fluid" />
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="row">
                <div className="col-lg-9 col-md-9 col-sm-9 Top-padding">
                  <div className="ABtcont rIght pt-1">
                    <h5>Integrity</h5>
                    <p>
                      Our commitment to ethical conduct is reflected in the way
                      we treat our students, partners, and stakeholders. We
                      prioritize respect and honesty in all our interactions,
                      fostering a culture of integrity.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 Top-padding">
                  <div className="ABTimG">
                    <img src={teacher} className="img-fluid" />
                  </div>
                </div>
              </div>

              <div className="row pt-5">
                <div className="col-lg-9 col-md-9 col-sm-9 paDiNg">
                  <div className="ABtcont rIght pt-1">
                    <h5>Adaptability</h5>
                    <p>
                      Understanding the dynamic nature of industries, we
                      prioritize equipping our students with the necessary
                      skills to adapt and excel in the evolving job market.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 paDiNg">
                  <div className="ABTimG">
                    <img src={exp} className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mission">
        <div className="mission_cont">
          <div className="mission_head">Our Mission</div>
          <div className="target_1">
            <img src={target1} />
          </div>

          <p className="mission_para">
            “We strive to create a dynamic learning environment that prepares
            students for the modern workforce and empowers professionals to
            advance their careers. We aim to create a talented, agile, and
            future-ready global workforce through innovative learning solutions
            and strategic collaborations."
          </p>
          <div className="target_2">
            <img src={target2} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
