import React, { useEffect, useState, useRef } from "react";
import "./Checkout.css";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { loader, isLogin, cartCount } from "../../redux/common";
import {
  listOfItemsInCart,
  BillingSummary,
  PlaceOrder,
} from "../../redux/courses";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
const Checkout = () => {
  const dispatch = useDispatch();
  const [itemsInCart, setItemsInCart] = useState([]);
  const [billingData, setBillingData] = useState({});
  const [subTotal, setSubTotal] = useState("");
  const [refreher, setRefreher] = useState(true);
  const [cartID, setCartID] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const promoCodeRef = useRef(null);
  const cartCountval = useSelector((state) => state.apiReducer.cartCount);
  useEffect(() => {
    if (
      localStorage.getItem("isLogin") == null ||
      localStorage.getItem("isLogin") == false
    ) {
      dispatch(isLogin(false));
    } else {
      dispatch(isLogin(true));
    }
    dispatch(loader(true));
    dispatch(
      listOfItemsInCart((resp) => {
        if (resp.status) {
          setItemsInCart(resp.data[0].items);
          setCartID(resp.data[0]._id);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
    let data = {};
    data["couponCode"] = couponCode;
    dispatch(
      BillingSummary(data, (resp) => {
        if (resp.status) {
          setBillingData(resp.data);
          const inputString = resp.data.total;
          const parts = inputString.split(/\s*\([^)]*\)\s*/);
          const extractedPart = parts[0].trim();
          setSubTotal(extractedPart);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [refreher, couponCode]);

  const checkoutSubmit = (e) => {
    e.preventDefault();
    let data = {};
    data["cartId"] = cartID;
    data["name"] = e.target.billingName.value;
    data["email"] = e.target.billingMail.value;
    data["mobileNumber"] = e.target.billingNumber.value;
    data["country"] = e.target.billingCountry.value;
    data["state"] = e.target.billingState.value;
    data["city"] = e.target.billingCity.value;

    if (couponCode) {
      data["couponCode"] = couponCode;
    }

    dispatch(loader(true));
    dispatch(
      PlaceOrder(data, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          dispatch(cartCount(!cartCountval));
          // setCouponCode("");
          dispatch(loader(false));
          window.location.href = resp.data;
        } else {
          dispatch(loader(false));
          // setCouponCode("");
          toast.error(resp.message);
        }
      })
    );
  };

  const applyPromoCode = (e) => {
    e.preventDefault();
    const enteredCouponCode = promoCodeRef.current.value.trim();

    if (enteredCouponCode) {
      // Update couponCode state variable only if a value is entered
      setCouponCode(enteredCouponCode);
    } else {
      // Reset couponCode to empty string if the field is empty
      setCouponCode("");
    }
  };

  function convertTimestampToDays(timestamp) {
    const currentTime = Math.floor(Date.now() / 1000); // Current Unix time in seconds
    const targetTime = timestamp; // Provided Unix timestamp in seconds
    console.log(currentTime, timestamp)
    return currentTime < targetTime;
  }
  function convertExpiryToDate(expiryTimestamp) {
    const expiryDate = new Date(expiryTimestamp);
    return expiryDate;
  }

  function isCourseFree(item) {
    const currentTime = Math.floor(Date.now() / 1000);
    const userCreationDate = localStorage.getItem("UserCreatedDate");
    const expiryDate = convertExpiryToDate(userCreationDate);
    const collegeId = localStorage.getItem("collegeId");
    const academicInfo = localStorage.getItem("AcademicInfo");
    const thirtyDaysInSeconds = 30 * 24 * 60 * 60; // 30 days in seconds

    if (item.freeForEveryone) {
      return true; // Course is free for everyone
    } else if (
      item.freeForEnInLast30Days &&
      currentTime - expiryDate.getTime() <= thirtyDaysInSeconds
    ) {
      return true; // Course is free for users who registered in the last 30 days
    } else if (
      academicInfo !== 0 &&
      item.freeForbasedOnColleges &&
      item.freeColleges.includes(collegeId)
    ) {
      return true; // Course is free for users based on their college
    } else {
      return false; // Course is not free and discount has expired or not applicable
    }
  }
  return (
    <div className="ChECkoutPage">
      <div className="PageHEaders">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PageTi pt-5">
                <h2>Check Out</h2>
                <p>
                  <Link to="/">Home</Link>{" "}
                  <i>
                    <FaAngleRight />
                  </i>
                  <Link to="">Check out</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={checkoutSubmit}>
        <div className="CheCkBox pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-12 col-sm-12">
                <div className="BillINg">
                  <h4>Billing details</h4>
                  <div className="BillForm mt-3 mb-4">
                    <div className="row">
                      <div className="col-4">
                        <input
                          className="FormBilling"
                          type="text"
                          placeholder="Name"
                          name="billingName"
                        />
                      </div>
                      <div className="col-4">
                        <input
                          className="FormBilling"
                          type="email"
                          placeholder="Email Address"
                          name="billingMail"
                        />
                      </div>
                      <div className="col-4">
                        <input
                          className="FormBilling"
                          type="text"
                          placeholder="Mobile Number"
                          name="billingNumber"
                        />
                      </div>
                      <div className="col-4">
                        <input
                          className="FormBilling"
                          type="text"
                          placeholder="Country"
                          name="billingCountry"
                        />
                      </div>
                      <div className="col-4">
                        <input
                          className="FormBilling"
                          type="text"
                          placeholder="State"
                          name="billingState"
                        />
                      </div>
                      <div className="col-4">
                        <input
                          className="FormBilling"
                          type="text"
                          placeholder="City"
                          name="billingCity"
                        />
                      </div>
                    </div>
                  </div>
                  <h4>Course</h4>
                  {itemsInCart &&
                    itemsInCart.map((item, index) => {
                      return (
                        <div className="BillCouRSe" key={item.courseDetail._id}>
                          <div className="CartBOxMain">
                            <div className="row">
                              <div className="col-lg-3">
                                <div className="BoXimG">
                                  <img
                                    src={item.courseDetail.thumbnail}
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-9">
                                <div className="ConteNt">
                                  <h4>{item?.courseDetail?.title}</h4>
                                  <div className="d-flex">
                                    <p>By {item?.courseDetail?.educators}</p>
                                    <p>{item?.courseDetail?.level}</p>
                                    <p>English</p>

                                    <p>{item?.courseDetail?.duration}</p>
                                  </div>
                                  <div className="Rate chk">
                                    {isCourseFree(item?.courseDetail) ? (
                                      <h4>FREE</h4>
                                    ) : (
                                      <>
                                        {item?.courseDetail?.discountedPrice >
                                          0 &&
                                        convertTimestampToDays(
                                          item?.courseDetail
                                            ?.discountedPriceExpiry
                                        ) ? (
                                          <>
                                            <h3>
                                              ₹
                                              {
                                                item?.courseDetail
                                                  ?.discountedPrice
                                              }
                                            </h3>
                                            <h5>
                                              <s>
                                                ₹{item?.courseDetail?.price}
                                              </s>
                                            </h5>
                                          </>
                                        ) : (
                                          <>
                                            <h3>
                                              ₹
                                              {item?.courseDetail?.regularPrice}
                                            </h3>
                                            <h5>
                                              <s>
                                                ₹{item?.courseDetail?.price}
                                              </s>
                                            </h5>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 pt-5">
                <div className="row  blUEBack">
                  <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                    <div className="CheckBillIng">
                      <h6>SubTotal</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-0">
                    <div className="CheckBillIng text-end">
                      <h6>{billingData?.subTotal}</h6>
                    </div>
                  </div>
                  <span className="Dummy">
                    <hr />
                  </span>
                  <div className="col-12">
                    <div className="CheckBillIng">
                      <h6>Promo Code</h6>
                    </div>
                    <InputGroup className="mb-3 inputGroup">
                      <Form.Control
                        ref={promoCodeRef}
                        placeholder="Recipient's username"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                      <InputGroup.Text
                        id="basic-addon2"
                        onClick={applyPromoCode}
                      >
                        Apply
                      </InputGroup.Text>
                    </InputGroup>
                  </div>

                  <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                    <div className="CheckBillIng">
                      <h6>Discount</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-0">
                    <div className="CheckBillIng text-end">
                      <h6> - {billingData?.couponDiscount}</h6>
                    </div>
                  </div>

                  <span className="Dummy">
                    <hr />
                  </span>
                  <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                    <div className="CheckBillIng">
                      <h6>Total</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-0">
                    <div className="CheckBillIng text-end">
                      <h6>{subTotal}</h6>
                    </div>
                  </div>
                  <span className="Dummy">
                    <hr />
                  </span>

                  <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                    <div className="CheckBillIng">
                      <h6>GST (18%)</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-0">
                    <div className="CheckBillIng text-end">
                      <h6>{billingData?.gst}</h6>
                    </div>
                  </div>

                  <span className="Dummy">
                    <hr />
                  </span>

                  <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                    <div className="CheckBillIng">
                      <h6>Grand Total</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-0">
                    <div className="CheckBillIng text-end">
                      <h6>{billingData?.grandTotal}</h6>
                    </div>
                  </div>
                </div>

                <div className="SidePURBTn mt-3">
                  <button className="grnBtn" type="submit">
                    Check Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default Checkout;
