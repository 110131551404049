import React, { useState, useEffect, useRef } from "react";
import Carousel from "react-bootstrap/Carousel";
import { IoCloseOutline } from "react-icons/io5";
import "./Home.css";
import { Link, useLocation } from "react-router-dom";
import { FaAngleRight, FaPlay } from "react-icons/fa";
import { AiFillStar } from "react-icons/ai";
import { HiChevronDoubleRight } from "react-icons/hi";
import { TiTick } from "react-icons/ti";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { activeBlogListCb } from "../../redux/user";
import { bannerList, planList } from "../../redux/subscription";
import { useNavigate } from "react-router-dom";
import abt from "../../assets/images/abt.png";
import educator from "../../assets/images/educator.png";
import abbottLaboratories from "../../assets/images/abbottLaboratories.png";
import ajantapharma from "../../assets/images/ajantapharma.png";
import alembicPharmaceuticalsLtd from "../../assets/images/alembicPharmaceuticalsLtd.png";
import alkem from "../../assets/images/alkem.png";
import astrazeneca from "../../assets/images/astrazeneca.png";
import aurobindoPharma from "../../assets/images/aurobindoPharma.png";
import biocon from "../../assets/images/biocon.png";
import cipla from "../../assets/images/cipla.png";
import divisLaboratories from "../../assets/images/divisLaboratories.png";
import drReddyLaboratories from "../../assets/images/drReddyLaboratories.png";
import glenmarkPharmaceuticals from "../../assets/images/glenmarkPharmaceuticals.png";
import intas from "../../assets/images/intas.png";
import ipcaLabs from "../../assets/images/ipcaLabs.png";
import lauruslabs from "../../assets/images/lauruslabs.png";
import lupin from "../../assets/images/lupin.png";
import mankind from "../../assets/images/mankind.png";
import marksans from "../../assets/images/marksans.png";
import natcoPharma from "../../assets/images/natcoPharma.png";
import piramal from "../../assets/images/piramal.png";
import sanofi from "../../assets/images/sanofi.png";
import sunpharma from "../../assets/images/sunpharma.png";
import wockhardt from "../../assets/images/wockhardt.png";
import zydus from "../../assets/images/zydus.png";
import quotes from "../../assets/images/quote.png";
import { useDispatch } from "react-redux";
import { loader } from "../../redux/common";
import { toast } from "react-toastify";
import {
  shortsList,
  CategoryList,
  testActiveCb,
  listOfFreeCourses,
  listOfPopularCourses,
  VideoTestimonialsList,
  subscriptionBannerList,
  liveBannersList,
} from "../../redux/shortsApi";
import { userPersonalDetail } from "../../redux/profile";

const Home = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetDiv = document.querySelector(hash);
      if (targetDiv) {
        targetDiv.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  const dispatch = useDispatch();
  const [blogData, setBlogData] = useState([]);
  const navigate = useNavigate();
  const [shortUrl, setShortUrl] = useState("");
  const [shortUrl1, setShortUrl1] = useState("");
  const [refresher, setRefresher] = useState(false);
  useEffect(() => {
    // Load the Instagram Embed script
    const script = document.createElement("script");
    script.src = "https://www.instagram.com/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Remove the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    dispatch(
      activeBlogListCb((resp) => {
        if (resp.status) {
          const filteredData = resp.data.filter(
            (item) => item.isActive === true
          );
          setBlogData(filteredData);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
  }, []);

  const blogViewCb = (data) => {
    navigate("/blogdetail/" + data._id);
    window.scrollTo(0, 0);
  };

  const handleClick = () => {
    navigate("/", { state: { scrollTo: "subsDivNew" } });
  };

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [shortList, setShortList] = useState([]);
  const [getURL, setURL] = useState([]);
  const [getURL1, setURL1] = useState([]);
  const [testimonialList, setTestimonialList] = useState([]);
  const [bannerListData, setBannerListData] = useState([]);
  const [freeCourses, setfreeCourses] = useState([]);
  const [popularCourses, setPopularCourses] = useState([]);
  const [videoTestimonialData, setVideoTestimonialData] = useState([]);
  const [subscritionBannerList, setSubscritionBannerList] = useState([]);
  const [LiveBannerList, setLiveBannerList] = useState([]);
  const [subscriptnPlan, setSubscriptnPlan] = useState([]);
  const [activeTab, setActiveTab] = useState("tab1");
  const [filteredSubscriptionPlans, setFilteredSubscriptionPlans] = useState(
    []
  );
  const [filteredShortList, setFilteredShortList] = useState([]);
  const [filteredBlogData, setFilteredBlogData] = useState([]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  function isDiscountValid(discountedPriceExpiryTimestamp) {
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime < discountedPriceExpiryTimestamp;
  }
  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      CategoryList((resp) => {
        if (resp.status) {
          setCategoryList(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
    dispatch(
      shortsList((resp) => {
        if (resp.status) {
          setURL(resp.data.map((v) => v.videoUrl));

          // Get user type and corporate ID
          const userType =
            localStorage.getItem("userType")?.toLowerCase() || "student";
          const corporateId = localStorage.getItem("corporateId");

          // Filter shorts based on user type and active status
          const filteredData = resp.data.filter((item) => {
            // First check if item is active
            if (!item.isActive) return false;

            // If shortsFor is not present, show to all users
            if (!item.shortsFor) return true;

            // For corporate users
            if (userType === "corporate") {
              return (
                item.shortsFor?.toLowerCase() === "corporate" &&
                (!item.corporate || item.corporate._id === corporateId)
              );
            }

            // For other user types - match shortsFor with userType
            // Note: Converting both to lowercase for case-insensitive comparison
            return item.shortsFor?.toLowerCase() === userType;
          });

          console.log("Filtered Shorts:", filteredData);
          setFilteredShortList(filteredData);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );

    dispatch(
      testActiveCb((resp) => {
        if (resp.status) {
          setTestimonialList(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );

    dispatch(
      bannerList((resp) => {
        if (resp.status) {
          setBannerListData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );

    dispatch(
      listOfFreeCourses((res) => {
        if (res.status) {
          setfreeCourses(res.data);
        }
        dispatch(loader(false));
      })
    );

    dispatch(
      listOfPopularCourses((res) => {
        if (res.status) {
          setPopularCourses(res.data);
        }
        dispatch(loader(false));
      })
    );
    dispatch(
      planList((res) => {
        if (res.status) {
          setSubscriptnPlan(res.data);
          const userType =
            localStorage.getItem("userType")?.toLowerCase() || "student";
          const CorporateId = localStorage.getItem("corporateId");

          const filtered = res.data.filter((item) => {
            if (userType === "corporate") {
              return (
                item?.corporate === CorporateId &&
                item.planFor?.toLowerCase().includes(userType)
              );
            } else {
              return item.planFor?.toLowerCase().includes(userType);
            }
          });

          setFilteredSubscriptionPlans(filtered);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );

    dispatch(
      VideoTestimonialsList((resp) => {
        if (resp.status) {
          setURL1(resp.data.map((v) => v.videoUrl));
          setVideoTestimonialData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );

    dispatch(
      subscriptionBannerList((resp) => {
        if (resp.status) {
          setSubscritionBannerList(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );

    dispatch(
      liveBannersList((resp) => {
        if (resp.status) {
          setLiveBannerList(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
    dispatch((resp) => {
      if (resp.status) {
        localStorage.setItem("UserCreatedDate", resp.data.createdAt);
        dispatch(loader(false));
      } else {
        dispatch(loader(false));
        // toast.error(resp.message);
      }
    });
  }, []);

  const scrollToTop = (id) => {
    window.location.href = `/subcart/${id}`;
    window.scrollTo(0, 0);
  };
  function scrollToSubscriptionPlans() {
    const subscriptionPlansSection = document.querySelector(".SubSrcibe");
    if (subscriptionPlansSection) {
      subscriptionPlansSection.scrollIntoView({ behavior: "smooth" });
    }
  }

  const openModal = (status, id) => {
    setModal(!modal);
    if (status == "open") {
      setShortUrl(getURL[id]);
    }
  };
  const openModal1 = (status, id) => {
    setModal1(!modal1);

    if (status == "open") {
      setShortUrl1(getURL1[id]);
    }
  };

  const spinner = () => {
    setVideoLoading(!videoLoading);
  };

  var course = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: freeCourses.length > 3 ? 3 : freeCourses.length,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: freeCourses.length > 3 ? 3 : freeCourses.length,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: freeCourses.length > 2 ? 2 : freeCourses.length,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: freeCourses.length > 2 ? 2 : freeCourses.length,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  var popular = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  var pharma = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    slidesToShow: 5,
    arrows: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  var experts = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    autoplay: false,
    slidesToShow: 3,
    arrows: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  var testimonial = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: false,
    slidesToShow: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  var videoTestimonial = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    slidesToShow: 3,
    arrows: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  var Image = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    slidesToShow: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const subsDivRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const { state } = location;
    if (state && state.scrollTo) {
      setTimeout(() => {
        const element = document.getElementById(state.scrollTo);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500); // Adjust the delay time in milliseconds as needed
    }
  }, [location]);

  // const bannerFor = ["Student", "Working", "Doctor", "Corporate"];

  const userType = localStorage.getItem("userType")?.toLowerCase() || "student";
  const corporateId = localStorage.getItem("corporateId");

  const filteredListData = bannerListData.filter((item) => {
    console.log(item?.corporate, corporateId);
    if (userType === "corporate") {
      if (item?.corporate === corporateId) {
        return item.bannerFor?.toLowerCase().includes(userType);
      }
    } else {
      return item.bannerFor?.toLowerCase().includes(userType);
    }
  });

  useEffect(() => {
    dispatch(
      activeBlogListCb((resp) => {
        if (resp.status) {
          // Get user type and corporate ID
          const userType =
            localStorage.getItem("userType")?.toLowerCase() || "student";
          const corporateId = localStorage.getItem("corporateId");

          // Filter blogs based on user type and active status
          const filteredData = resp.data.filter((item) => {
            // First check if item is active
            if (!item.isActive) return false;

            // If blogFor is not present, show to all users
            if (!item.blogFor) return true;

            // For corporate users
            if (userType === "corporate") {
              return (
                item.blogFor?.toLowerCase() === "corporate" &&
                (!item.corporate || item.corporate === corporateId)
              );
            }

            // For other user types - match blogFor with userType
            return item.blogFor?.toLowerCase() === userType;
          });

          console.log("Filtered Blog Data:", filteredData);
          setFilteredBlogData(filteredData);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
  }, []);

  const filteredTestimonialList = testimonialList.filter((item) => {
    // Get user type from localStorage
    const userType = localStorage.getItem("userType")?.toLowerCase();

    // Map frontend user types to backend testimonialFor values
    const userTypeMap = {
      student: "Student",
      workingprofessional: "workingProfessional",
      doctor: "Doctor",
      corporate: "Corporate",
    };

    // Return testimonials that match the user's type
    return item.testimonialFor === userTypeMap[userType];
  });

  const getHeadingText = () => {
    const userType = localStorage.getItem("userType")?.toLowerCase();
    switch (userType) {
      case "student":
        return "WHAT STUDENTS THINK AND SAY ABOUT KAREERSITY";
      case "workingprofessional":
        return "WHAT PROFESSIONALS THINK AND SAY ABOUT KAREERSITY";
      case "doctor":
        return "WHAT DOCTORS THINK AND SAY ABOUT KAREERSITY";
      case "corporate":
        return "WHAT CORPORATES THINK AND SAY ABOUT KAREERSITY";
      default:
        return "WHAT PEOPLE THINK AND SAY ABOUT KAREERSITY";
    }
  };

  return (
    <div className="HomEPage">
      <div className="SliderS">
        <Carousel>
          {filteredListData.map((item, i) =>
            item.isActive ? (
              <Carousel.Item key={i}>
                <Link to={item.videoUrl}>
                  <img
                    src={item.thumbnail}
                    className="img-fluid"
                    alt={`Banner ${i}`}
                  />
                </Link>
              </Carousel.Item>
            ) : null
          )}
        </Carousel>
      </div>

      {/* header banner end */}
      {freeCourses.length > 0 && (
        <div className="COurSe pt-5 ">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="PAgeTit pt-3 pb-3">
                  <h2>Free Courses</h2>
                </div>
              </div>
              <div className="col-12">
                <Slider {...course}>
                  {freeCourses.map((item, index) => (
                    <div key={item._id} className="MainCOurSEdIV">
                      <div className="couRSeImg">
                        <img
                          src={item.thumbnail}
                          alt="Hepatology"
                          className="img-fluid oragnsTop categriesImg"
                        />
                      </div>
                      <div className="COurseConTEnt">
                        <h5 onClick={() => window.scrollTo(0, 0)}>
                          <Link
                            to={`/course-detail/${
                              item._id
                            }/${item.title.replace(/ /g, "_")}`}
                          >
                            {item.title}
                          </Link>
                        </h5>
                        <span className="SAPnHr">
                          <hr />
                        </span>
                        <div className="SCetionCourse d-flex">
                          <h4>FREE</h4>
                          <h6>{item.level}</h6>
                          <h6 className="text-end">{item.duration}</h6>
                        </div>
                        <span className="SAPnHr">
                          <hr />
                        </span>
                        <div className="FinAlDIv d-flex">
                          <p>
                            <i>
                              <AiFillStar />
                            </i>{" "}
                            {item.avgRating} ({item.totalRatings})
                          </p>
                          <Link
                            to={`/course-detail/${
                              item._id
                            }/${item.title.replace(/ /g, "_")}`}
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            Learn More{" "}
                            <i>
                              <HiChevronDoubleRight />
                            </i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* free course end */}
      {/* subscription banner image Section */}

      <div className="IMAgeSession" onClick={scrollToSubscriptionPlans}>
        <div className="container">
          <div className="row">
            <div className="col-12 pt-3 pb-3">
              <Slider {...Image}>
                {subscritionBannerList.map((item, i) => (
                  <Link onClick={handleClick}>
                    <img
                      key={i}
                      src={item?.thumbnail}
                      className="img-fluid"
                      alt="aboutImg"
                    />
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      {/* image section end */}
      {/* Explore category */}
      <div className="ExplorCAtegory pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PAgeTit pt-3 pb-3">
                <h2>EXPLORE OUR CATEGORIES</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center pt-2 ">
            {categoryList
              .filter((item) => item.isHidden) // Filter categories where isHidden is true
              .map((item) => (
                <div
                  key={item._id}
                  className="col-lg-4 col-md-6 col-sm-6 col-6"
                  onClick={() => {
                    navigate("/course", { state: { categoryId: item._id } });
                    window.scrollTo(0, 0);
                  }}
                >
                  <div className="CAteGorey">
                    <h5>{item.name}</h5>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* end explore category */}

      {/* subcribe */}
      <div ref={subsDivRef} id="subsDivNew" className="SubSrcibe pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PAgeTit pt-3 pb-3">
                <h2>SUBSCRIPTION PLAN</h2>
                <p>
                  We provide subscription plans tailored to individual academic
                  needs
                </p>
              </div>
            </div>
          </div>

          <div className="row pb-5 subsDiv" style={{ alignItems: "center" }}>
            {filteredSubscriptionPlans.slice(0, 3).map((item, index) => (
              <div className="col-lg-4">
                {index === 2 && (
                  <div className="col-md-12 subPad">
                    <div className="BasIC">
                      <div className="BAsicHEad blue rb_rubh">
                        <h6>{item.planName}</h6>
                      </div>
                      <div className="BAsicContainer">
                        <h1>₹ {item.oneMonthPrice}</h1>
                        <span>Per Month</span>
                        <ol className="LiSTSUB pt-4 pb-4">
                          {item.features.map((feature, featureIndex) => (
                            <li key={featureIndex}>
                              <i>
                                <TiTick />
                              </i>
                              {feature}
                            </li>
                          ))}
                        </ol>
                        <div className="BAsicHEad blue acc">
                          <h6>ALSO ACCESS TO STANDARD COURSES</h6>
                        </div>
                        <div className="choosePlan blu">
                          <button
                            onClick={() => scrollToTop(item._id)}
                            className="PLanChse"
                          >
                            CHOOSE PLAN{" "}
                            <i>
                              <FaAngleRight />
                            </i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  {index === 1 && (
                    <div className="BasIC ylw">
                      <div className="BAsicHEad ylw rb_rubh corporate">
                        <h6
                          style={{
                            fontWeight: "bold",
                            fontSize: "24px",
                            color: "#000000",
                          }}
                        >
                          {item.planName}
                        </h6>
                      </div>
                      <div className="BAsicContainer ylw">
                        <h1>₹ {item.oneMonthPrice}</h1>
                        <span>Per Month</span>
                        <ul className="LiSTSUB pt-4 pb-4">
                          {item.features.map((feature, featureIndex) => (
                            <li key={featureIndex}>
                              <i>
                                <TiTick />
                              </i>
                              {feature}
                            </li>
                          ))}
                        </ul>
                        <div className="BAsicHEad ylw acc">
                          <h6>ALSO ACCESS TO STANDARD AND PRIME COURSES</h6>
                        </div>
                        <div className="choosePlan ylow">
                          <button
                            onClick={() => scrollToTop(item._id)}
                            className="PLanChse"
                          >
                            CHOOSE PLAN{" "}
                            <i>
                              <FaAngleRight />
                            </i>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-md-12 subPad">
                  {index === 0 && (
                    <div className="BasIC">
                      <div className="BAsicHEad grn rb_rubh">
                        <h6>{item.planName}</h6>
                      </div>
                      <div
                        className="BAsicContainer grn
                                "
                      >
                        <h1>₹ {item.oneMonthPrice}</h1>
                        <span>Per Month</span>
                        <ul className="LiSTSUB pt-4 pb-4">
                          {item.features.map((feature, featureIndex) => (
                            <li key={featureIndex}>
                              <i>
                                <TiTick />
                              </i>
                              {feature}
                            </li>
                          ))}
                        </ul>
                        <div className="choosePlan ylow">
                          <button
                            onClick={() => scrollToTop(item._id)}
                            className="PLanChse"
                          >
                            CHOOSE PLAN{" "}
                            <i>
                              <FaAngleRight />
                            </i>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* subscribe end */}
      {/* about session */}
      <div className="AbtSEssion pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12 whyus_cont">
              <div className="PAgeTit pt-5 pb-3">
                <h2>WHY US ?</h2>
              </div>
              <p className="">
                Choose KareerSity because we offer more than just an educational
                platform; we are dedicated to helping you achieve transformative
                success. We pride ourselves on our dedication to delivering
                exceptional results, pushing boundaries with creative solutions,
                and tailoring our services to meet your unique needs. We offer a
                distinctive combination of practical industry knowledge,
                innovative teaching methods, and a strong focus on our students.
                Our programs not only prepare students with the necessary skills
                for the workforce, but also cultivate a mindset of adaptability
                and leadership. We are proud of our comprehensive approach,
                which covers all aspects of educational and professional
                requirements. Whether you're a student looking to enter the
                workforce, a professional seeking to enhance your skills, or a
                business in need of expert guidance, KareerSity is here to be
                your trusted partner. We'll take you on a transformative journey
                towards a future filled with unparalleled success in the dynamic
                landscapes of industry and business. Experience the exceptional
                with KareerSity - your gateway to extraordinary possibilities.
              </p>
            </div>
            <div className="col-lg-5 col-md-12">
              <img src={abt} className="img-fluid" alt="aboutImg" />
            </div>
          </div>
        </div>
      </div>
      {/* about session end */}
      {/* Master class */}

      <div className="MASterClass">
        <div className="col-12">
          <Slider {...Image}>
            {LiveBannerList.map((item, i) => (
              <Link to={item?.videoUrl} target="_blank">
                <img
                  src={item?.thumbnail}
                  className="img-fluid"
                  alt="aboutImg"
                />
              </Link>
            ))}
          </Slider>
        </div>
      </div>

      {/* master class end */}
      {/* popular course */}
      <div className="COurSe pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PAgeTit pt-3 pb-3">
                <h2>Popular Courses</h2>
                <p>
                  KareerSity offers comprehensive programs for pharmacy students
                  and young professionals. Here are our most popular courses
                  tailored to your academic needs.
                </p>
              </div>
            </div>
            <div className="col-12">
              <Slider {...popular}>
                {popularCourses.map((x, i) => (
                  <div className="MainCOurSEdIV">
                    <div className="couRSeImg">
                      <img
                        src={x?.thumbnail}
                        alt="Hepatology"
                        className="img-fluid oragnsTop categriesImg"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="COurseConTEnt">
                      <Link
                        to={`/course-detail/${x._id}/${x.title.replace(
                          / /g,
                          "_"
                        )}`}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <h5 className="popTitle">{x.title}</h5>
                      </Link>
                      <span className="SAPnHr">
                        <hr />
                      </span>
                      <div className="SCetionCourse d-flex popHome">
                        <div className="RightCLcont d-flex crs striked">
                          {x.price === 0 ? (
                            <h4>FREE</h4>
                          ) : (
                            <>
                              {x.discountedPrice > 0 &&
                              isDiscountValid(x.discountedPriceExpiry) ? (
                                <>
                                  <div>
                                    <h4>₹{x.discountedPrice}</h4>
                                  </div>
                                  <div>
                                    <h5>
                                      <s>₹{x.price}</s>
                                    </h5>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div>
                                    <h4>₹{x.regularPrice}</h4>
                                  </div>
                                  <div>
                                    <h5>
                                      <s>₹{x.price}</s>
                                    </h5>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                        <h6>{x.level}</h6>
                        <h6 className="text-end">{x.duration}</h6>
                      </div>
                      <span className="SAPnHr">
                        <hr />
                      </span>
                      <div className="FinAlDIv d-flex">
                        <p>
                          <i>
                            <AiFillStar />
                          </i>{" "}
                          {x.avgRating} ({x.totalRatings}){/* 4.9 (250) */}
                        </p>
                        <Link
                          to={`/course-detail/${x._id}/${x.title.replace(
                            / /g,
                            "_"
                          )}`}
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          Learn More{" "}
                          <i>
                            <HiChevronDoubleRight />
                          </i>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      {/* popular course end */}
      {/* listen experts */}

      <div className="ExPertS pt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PAgeTit pt-3 pb-3">
                <h2>LISTEN TO EXPERTS</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              {filteredShortList.length > 0 ? (
                <Slider {...experts}>
                  {filteredShortList.map((item, i) => (
                    <div className="ExPErt" key={i}>
                      <div className="eXpImg">
                        <img
                          src={item.thumbnail}
                          className="img-fluid ExPert"
                          style={{ height: "100%" }}
                          alt="aboutImg"
                        />
                        <div className="ExPertCont">
                          <h6>{item.title}</h6>
                        </div>
                        <button
                          className="btn-primary PlY"
                          onClick={() => {
                            openModal("open", i);
                          }}
                        >
                          <FaPlay />
                        </button>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className="text-center">
                  <p>No expert videos available for your user type.</p>
                </div>
              )}

              {modal ? (
                <section className="modal__bg">
                  <div className="modal__align">
                    <div className="modal__content" modal={modal}>
                      <IoCloseOutline
                        className="modal__close"
                        arial-label="Close modal"
                        onClick={openModal}
                      />

                      <div className="modal__video-align">
                        {videoLoading ? (
                          <div className="modal__spinner"></div>
                        ) : null}

                        <iframe
                          className="modal__video-style"
                          id="instagram-embed-0"
                          onLoad={spinner}
                          loading="lazy"
                          width="800"
                          height="500"
                          src={shortUrl}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </section>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {/* listen experts end */}
      {/* testimonials */}

      <div className="TeStimonial pt-2 pb-5 ">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PAgeTit pt-3 pb-3">
                <h2>{getHeadingText()}</h2>
                <p>
                  Discover Testimonials​ Explore the valuable insights and
                  experiences shared by learners on our feedback page.
                </p>
              </div>
            </div>
          </div>

          <div className="tab-buttons">
            <button
              onClick={() => handleTabClick("tab1")}
              className={activeTab === "tab1" ? "active" : ""}
            >
              What{" "}
              {localStorage
                .getItem("userType")
                ?.split(/(?=[A-Z])/)
                .join(" ")}{" "}
              Say
            </button>
          </div>

          <div className="tab-content">
            {activeTab === "tab1" && (
              <div className="row">
                <div className="col-12">
                  {filteredTestimonialList.length > 0 ? (
                    <Slider {...testimonial} className="testmonial-slick">
                      {filteredTestimonialList.map((item, index) => (
                        <div key={index} className="TestimoniaLS">
                          <div className="QuoTEImg pb-3">
                            <img
                              src={quotes}
                              className="img-fluid fld"
                              alt="aboutImg"
                            />
                          </div>
                          <div className="TestiMoniAlSConTENT">
                            <p>{item?.feedback}</p>
                            <div className="DetILSTEsti">
                              <h5>{item?.name}</h5>
                              <h6>{item?.qualification}</h6>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <div className="text-center">
                      <p>No testimonials available for your user type.</p>
                    </div>
                  )}
                </div>
              </div>
            )}
            {activeTab === "tab2" && (
              <div className="row">
                <div className="col-12">
                  <div
                    className="slider-container"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <Slider {...videoTestimonial} className="videotestimonialh">
                      {videoTestimonialData.map((item, i) => {
                        return (
                          <div className="ExPErt">
                            <div className="eXpImg">
                              <img
                                src={item?.thumbnail}
                                className="img-fluid ExPert"
                                style={{
                                  height: "350px",
                                  borderRadius: "10px",
                                  width: "100%",
                                  // objectFit: "contain"
                                }}
                                alt="aboutImg"
                              />

                              <button
                                className="btn-primary PlY1"
                                onClick={() => {
                                  openModal1("open", i);
                                }}
                              >
                                <FaPlay />
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                    sdafdsdsdgasdasdasdasd
                  </div>

                  {modal1 ? (
                    <section className="modal__bg">
                      <div className="modal__align">
                        <div className="modal__content" modal={modal1}>
                          <IoCloseOutline
                            className="modal__close"
                            arial-label="Close modal"
                            onClick={openModal1}
                          />

                          <div className="modal__video-align">
                            {videoLoading ? (
                              <div className="modal__spinner"></div>
                            ) : null}

                            <iframe
                              className="modal__video-style"
                              onLoad={spinner}
                              loading="lazy"
                              width="800"
                              height="500"
                              src={shortUrl1}
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </section>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* testimonial end */}

      {/* blogs */}
      <div className="BLOgs pt-5 pb-5 ">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PAgeTit pt-3 pb-3">
                <h2>OUR BLOG</h2>
                <p>
                  Step into the heart of our community where we explore the
                  stories behind our brand, industry trends, and valuable tips.
                  Our blog is the canvas where we share our passions,
                  experiences, and the latest happenings. Join us on a journey
                  of discovery, stay ahead in the world of Pharmaceuticals, and
                  be part of the conversation that shapes our collective
                  journey.
                </p>
              </div>
            </div>
            {filteredBlogData.length > 0 ? (
              <div className="row pt-3 pb-3">
                <div className="col-lg-7 col-md-12">
                  {filteredBlogData
                    .filter((item, i) => i > 0 && i <= 3)
                    .map((filBlog, index) => (
                      <React.Fragment key={filBlog._id}>
                        <div className="row">
                          <div
                            className="col-lg-4 col-md-4 col-sm-6 col-6"
                            onClick={() => blogViewCb(filBlog)}
                          >
                            <div className="blogIMG">
                              <img
                                src={filBlog?.thumbnail}
                                className="img-fluid"
                                alt="aboutImg"
                              />
                            </div>
                          </div>
                          <div className="col-lg-8 col-md-8 col-sm-6 col-6">
                            <div
                              className="BLogContent"
                              onClick={() => blogViewCb(filBlog)}
                            >
                              <h6>
                                {new Date(
                                  filBlog?.updatedAt
                                ).toLocaleDateString("en-US", {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                })}
                              </h6>
                              <h5>{filBlog?.title}</h5>
                              <p>{filBlog?.sDesc}</p>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </React.Fragment>
                    ))}
                </div>

                {filteredBlogData.length > 0 && (
                  <div
                    className="col-lg-5 col-md-12"
                    onClick={() => blogViewCb(filteredBlogData[0])}
                  >
                    <div className="BLOGImg">
                      <img
                        src={filteredBlogData[0]?.thumbnail}
                        className="img-fluid"
                        alt="aboutImg"
                      />
                    </div>
                    <div className="BLogContent larGE">
                      <h6>
                        {new Date(
                          filteredBlogData[0]?.updatedAt
                        ).toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        })}
                      </h6>
                      <h5>{filteredBlogData[0]?.title}</h5>
                      <p>{filteredBlogData[0]?.sDesc}</p>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-center pt-3">
                <p>No blogs available for your user type.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* blogs end */}

      {/* become educator */}
      <div className="EDucatoR">
        <div className="EduCatorImg ">
          <img src={educator} className="img-fluid" alt="aboutImg"></img>
        </div>
        <div className="EDuCtORCOntEnt">
          <h3>BECOME AN EDUCATOR & SPREAD YOUR KNOWLEDGE</h3>
          <p>
            Embrace the opportunity to educate and share your expertise by
            joining one of the world's largest online learning marketplaces. We
            have a dedicated team ready to support instructors like you, and our
            Teaching Center offers a wide range of valuable resources. Join our
            community and be a part of an exciting platform that encourages
            collaboration, knowledge-sharing, and professional development.
          </p>
          <Link to="/educator" className="MstwhiTE mt-3">
            {" "}
            GEt started
            <i>
              <FaAngleRight />
            </i>
          </Link>
        </div>
      </div>
      {/* educator end */}

      {/* course selection end */}
      {/* pharma industry */}
      <div className="PharmaCARo pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PAgeTit pt-3 pb-3">
                <h2>POTENTIAL EMPLOYERS</h2>
              </div>
            </div>
          </div>
          <div className="row pb-3">
            <div className="col-12">
              <Slider {...pharma}>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img
                      src={abbottLaboratories}
                      className="img-fluid"
                      alt="aboutImg"
                    />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img
                      src={ajantapharma}
                      className="img-fluid"
                      alt="aboutImg"
                    />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img
                      src={alembicPharmaceuticalsLtd}
                      className="img-fluid"
                      alt="aboutImg"
                    />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img src={alkem} className="img-fluid" alt="aboutImg" />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img
                      src={astrazeneca}
                      className="img-fluid"
                      alt="aboutImg"
                    />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img
                      src={aurobindoPharma}
                      className="img-fluid"
                      alt="aboutImg"
                    />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img src={biocon} className="img-fluid" alt="aboutImg" />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img src={cipla} className="img-fluid" alt="aboutImg" />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img
                      src={divisLaboratories}
                      className="img-fluid"
                      alt="aboutImg"
                    />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img
                      src={drReddyLaboratories}
                      className="img-fluid"
                      alt="aboutImg"
                    />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img
                      src={glenmarkPharmaceuticals}
                      className="img-fluid"
                      alt="aboutImg"
                    />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img src={intas} className="img-fluid" alt="aboutImg" />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img src={ipcaLabs} className="img-fluid" alt="aboutImg" />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img
                      src={lauruslabs}
                      className="img-fluid"
                      alt="aboutImg"
                    />
                  </div>
                </div>

                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img src={lupin} className="img-fluid" alt="aboutImg" />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img src={mankind} className="img-fluid" alt="aboutImg" />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img src={marksans} className="img-fluid" alt="aboutImg" />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img
                      src={natcoPharma}
                      className="img-fluid"
                      alt="aboutImg"
                    />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img src={piramal} className="img-fluid" alt="aboutImg" />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img src={sanofi} className="img-fluid" alt="aboutImg" />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img src={sunpharma} className="img-fluid" alt="aboutImg" />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img src={wockhardt} className="img-fluid" alt="aboutImg" />
                  </div>
                </div>
                <div className="phaRMa">
                  <div className="couRSeImg">
                    <img src={zydus} className="img-fluid" alt="aboutImg" />
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
      {/* pharama end */}
    </div>
  );
};
export default Home;
