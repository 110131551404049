import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Cart.css";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import courseimg from "../../assets/images/coursebanner.png";
import { loader } from "../../redux/common";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  CheckoutForDirecTraining,
  TrainingBillingSummary,
  removeInstallFromCart,
} from "../../redux/courses";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const TrainingCart = () => {
  const dispatch = useDispatch();

  const [trainingData, setTrainingData] = useState([]);
  const [chkData, setChkData] = useState([]);
  const [refresher, setRefresher] = useState(true);

  const params = useParams();
  useEffect(() => {
    let datas = {};

    datas["traineeId"] = params.id;
    dispatch(loader(true));
    dispatch(
      TrainingBillingSummary(datas, (result) => {
        if (result.status) {
          setTrainingData(result.data.selectedInstallments);
          setChkData(result.data);
          toast.success(result.message);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(result.message);
        }
      })
    );
  }, [refresher]);

  const removeItem1 = (title) => {
    let data = {};
    data["traineeId"] = params.id;
    data["installmentTitle"] = title;
    dispatch(loader(true));
    dispatch(
      removeInstallFromCart(data, (resp) => {
        if (resp.status) {
          setRefresher(!refresher);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const checkoutSubmit = () => {
    let data = {};
    data["traineeId"] = params.id;

    dispatch(
      CheckoutForDirecTraining(data, (response) => {
        if (response.status) {
          window.location.href = response.data;
          sessionStorage.removeItem("formData");
        } else {
          toast.error(response.message);
        }
      })
    );
  };
  const getOrdinalSuffix = (n) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0];
  };
  return (
    <div className="CArtPAge">
      <div className="CarTBOX pt-5 pb-5">
        <div className="container">
          <div className="row justify-content-center ">
            <div className="CartTablE instTbl col-xl-8 col-lg-8">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Installments</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {trainingData &&
                    trainingData?.map((item) => (
                      <tr>
                        <td className="MainCartBox">
                          <div className="CartBOxMain">
                            <div className="ConteNt InstTitle">
                              <button
                                className="BtnSub"
                                onClick={() => removeItem1(item.title)}
                              >
                                <i>
                                  <FaTimes />
                                </i>
                              </button>

                              <h4>
                                {" "}
                                {/* {item.title === "1st"
                                  ? "1st Month"
                                  : `${getOrdinalSuffix(item.title)} Month`} */}
                                  {`${item.title}${getOrdinalSuffix(
                              item.title
                            )} Month`}
                              </h4>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p>
                            <h4> ₹ {item.amount}</h4>
                          </p>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 pt-3">
              <div className="row  blUEBack">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                  <div className="CheckBillIng">
                    <h6>SubTotal</h6>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-0">
                  <div className="CheckBillIng text-end">
                    <h6>{chkData?.subTotal}</h6>
                  </div>
                </div>
                <span className="Dummy">
                  <hr />
                </span>

                <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                  <div className="CheckBillIng">
                    <h6>GST (18%)</h6>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-0">
                  <div className="CheckBillIng text-end">
                    <h6>{chkData?.gst}</h6>
                  </div>
                </div>
                <span className="Dummy">
                  <hr />
                </span>
                <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                  <div className="CheckBillIng">
                    <h6>Grand Total</h6>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-4 p-0">
                  <div className="CheckBillIng text-end">
                    <h6>{chkData?.grandTotal}</h6>
                  </div>
                </div>
              </div>
              <div className="SidePURBTn mt-3">
                <button
                  className="grnBtn"
                  type="submit"
                  onClick={checkoutSubmit}
                >
                  Check Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="IMAgeSession pb-5 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 pt-3 pb-3">
              <Link to="/">
                <img
                  src={courseimg}
                  className="img-fluid"
                  alt="Course Banner"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingCart;
