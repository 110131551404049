import React from "react";
import "./Activation.css";
import dislike from "../../assets/images/dislike.png";

const PaymentFailed = () => {
  return (
    <div className="ProFILE">
      <div className="ProfileTabs pt-5 pb-5 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="SuceSSBoxCouRs activation mb-3">
                <div className="SuccImg">
                  <img src={dislike} className="img-fluid" />
                </div>
                <h4>SORRY!!!</h4>
                <h5>Your payment failed</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentFailed;
