import React, { useEffect, useState, useRef } from "react";
import "./Checkout.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { loader, isLogin } from "../../redux/common";
import {
  activePlanListwithBundle,
  subscribeAPlan,
  subscribedPlanInfo,
} from "../../redux/courses";
import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { SubBillingSummary } from "../../redux/subscription";

const SubCheckout = () => {
  const Sprice = localStorage.getItem("selectedPrice");

  const dispatch = useDispatch();
  const subscriptionPlanId = useParams();

  const [billingData, setBillingData] = useState({});
  const [subTotal, setSubTotal] = useState("");
  const [refreher, setRefreher] = useState(true);
  const [showData, setShowData] = useState();
  const [couponCode, setCouponCode] = useState("");
  const [courseList, setCourseList] = useState([]);
  const [upgradePlan, setUpgradeplan] = useState(0);

  const promoCodeRef = useRef(null);
  useEffect(() => {
    dispatch(
      subscribedPlanInfo((resp) => {
        if (resp.status) {
          if (resp.data.isActive === true) {
            setUpgradeplan(1);
          }
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("isLogin") == null ||
      localStorage.getItem("isLogin") == false
    ) {
      dispatch(isLogin(false));
    } else {
      dispatch(isLogin(true));
    }

    let data = {};
    data["subscriptionPlanId"] = subscriptionPlanId.id;
    dispatch(loader(true));

    dispatch(
      activePlanListwithBundle(data, (resp) => {
        if (resp.status) {
          setCourseList(resp.data.courseBundles);
          setShowData(resp.data.courseBundles.length);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );

    let reqdata = {};
    reqdata["planId"] = subscriptionPlanId.id;
    if (couponCode) {
      reqdata["couponCode"] = couponCode;
    }

    reqdata["selectedPPrice"] = parseInt(Sprice);
    dispatch(loader(true));
    dispatch(
      SubBillingSummary(reqdata, (resp) => {
        if (resp.status) {
          setBillingData(resp.data);
          const inputString = resp.data.total;
          const parts = inputString.split(/\s*\([^)]*\)\s*/);
          const extractedPart = parts[0].trim();
          setSubTotal(extractedPart);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [refreher, couponCode]);

  const checkoutSubmit = () => {
    let data = {};
    data["planId"] = subscriptionPlanId.id;
    data["upgrade"] = 1;
    data["selectedPPrice"] = parseInt(Sprice);
    if(couponCode){
      data["couponCode"] = couponCode
    }
    
    dispatch(
      subscribeAPlan(data, (response) => {
        if (response.status) {
          window.location.href = response.data;
        } else {
          toast.error(response.message);
        }
      })
    );
  };

  const applyPromoCode = (e) => {
    e.preventDefault();
    const enteredCouponCode = promoCodeRef.current.value.trim();

    if (enteredCouponCode) {
      // Update couponCode state variable only if a value is entered
      setCouponCode(enteredCouponCode);
    } else {
      // Reset couponCode to empty string if the field is empty
      setCouponCode("");
    }
  };

  return (
    <div className="ChECkoutPage">
      <div className="PageHEaders">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PageTi pt-5">
                <h2>Check Out</h2>
                <p>
                  <Link to="/">Home</Link>{" "}
                  <i>
                    <FaAngleRight />
                  </i>
                  <Link to="">Subscription Plan Check out</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="CheCkBox pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-9 col-lg-9 col-md-12"
                style={{
                  display: showData == 0 ? "none" : "flex",
                }}
              >
                <div className="CartTablE">
                  <Table striped bordered hover>
                    <thead className="cardhEAD">
                      <tr>
                        <th
                          style={{
                            display: showData == 0 ? "none" : "flex",
                          }}
                        >
                          Courses
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {courseList &&
                        courseList.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="MainCartBox">
                                <div className="CartBOxMain">
                                  <div className="row">
                                    <div className="col-lg-3">
                                      <div className="BoXimG">
                                        <img
                                          src={item.thumbnail}
                                          className="img-fluid imGBox"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-9">
                                      <div className="ConteNt">
                                        <h4>{item.title}</h4>
                                        <div className="d-flex contntCART">
                                          <p>{item.level}</p>
                                          <p>English</p>
                                          <p>{item.duration}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 pt-5">
                <div className="row  blUEBack">
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                    <div className="CheckBillIng">
                      <h6>SubTotal</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 p-0">
                    <div className="CheckBillIng text-end">
                      <h6>{billingData?.subTotal}</h6>
                    </div>
                  </div>
                  <span className="Dummy">
                    <hr />
                  </span>
                  <div className="col-12">
                    <div className="CheckBillIng">
                      <h6>Promo Code</h6>
                    </div>
                    <InputGroup className="mb-3 inputGroup">
                      <Form.Control
                        ref={promoCodeRef}
                        placeholder="Recipient's username"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                      <InputGroup.Text
                        id="basic-addon2"
                        onClick={applyPromoCode}
                      >
                        Apply
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-8">
                    <div className="CheckBillIng">
                      <h6>Discount</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 p-0">
                    <div className="CheckBillIng text-end">
                      <h6> - {billingData?.couponDiscount}</h6>
                    </div>
                  </div>
                  <span className="Dummy">
                    <hr />
                  </span>
                  <div className="col-lg-8 col-md-8 col-sm-8">
                    <div className="CheckBillIng">
                      <h6>Total</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 p-0">
                    <div className="CheckBillIng text-end">
                      <h6>{subTotal}</h6>
                    </div>
                  </div>
                  <span className="Dummy">
                    <hr />
                  </span>
                  <div className="col-lg-8 col-md-8 col-sm-8">
                    <div className="CheckBillIng">
                      <h6>GST (18%)</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 p-0">
                    <div className="CheckBillIng text-end">
                      <h6>{billingData?.gst}</h6>
                    </div>
                  </div>
                  <span className="Dummy">
                    <hr />
                  </span>
                  <div className="col-lg-8 col-md-8 col-sm-8">
                    <div className="CheckBillIng">
                      <h6>Grand Total</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 p-0">
                    <div className="CheckBillIng text-end">
                      <h6>{billingData?.grandTotal}</h6>
                    </div>
                  </div>
                </div>
                <div className="SidePURBTn mt-3">
                  <button
                    className="grnBtn"
                    type="submit"
                    onClick={checkoutSubmit}
                  >
                    Check Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubCheckout;
