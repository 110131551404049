import React, { useEffect, useState } from "react";
import "./Profile.css";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import Nav from "react-bootstrap/Nav";
import c1 from "../../assets/images/course.png";
import { AiFillStar } from "react-icons/ai";
import ProgressBar from "react-bootstrap/ProgressBar";
import { listOfCourses, DownloadCertificate } from "../../redux/courses";
import { isLogin, loader } from "../../redux/common";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { userPersonalDetail } from "../../redux/profile";

const Mycourse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [courseData, setCourseData] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [personalData, setPersonalData] = useState([])

  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      listOfCourses((resp) => {
        if (resp.status) {
          setCourseData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
    dispatch(
      userPersonalDetail((resp) => {
        if (resp.status) {
          setPersonalData(resp.data)
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [dispatch]);

  const downloadcertificate = (id) => {
    let data = {};
    data["courseId"] = id;
    dispatch(
      DownloadCertificate(data, (resp) => {
        if (resp.status) {
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = resp.data;
          link.download = "Certificate.pdf";

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const redirectToViewMyCourse = (purchasedCourseId) => {
    navigate("/view_my_course/" + purchasedCourseId);
  };

  setInterval(() => {
    autoLogOut();
  }, 1000);

  const autoLogOut = () => {
    if (localStorage.getItem("oAuth")) {
      const jwt_Token_decoded = jwt_decode(localStorage.getItem("oAuth"));
      if (jwt_Token_decoded.exp * 1000 < Date.now()) {
        localStorage.removeItem("oAuth");
        localStorage.clear();
        navigate("/");
        dispatch(isLogin(false));
        toast.error("Session expired.Please login to proceed");
      }
    }
  };
  const handleLogOut = () => {
    localStorage.removeItem("oAuth");
    localStorage.removeItem("isLogin");
    localStorage.removeItem("UserCreatedDate");
    localStorage.removeItem("collegeId");
    navigate("/");
    dispatch(isLogin(false));
    toast.success("You have logged out successfully");
  };

  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    // Get the current URL path
    const currentPath = window.location.pathname;
    setActiveTab(currentPath);
  }, []);
  return (
    <div className="ProFILE">
      <div className="PageHEaders">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PageTi pt-5">
                <h2>My Course</h2>
                <p>
                  <Link to="/">Home</Link>{" "}
                  <i>
                    <FaAngleRight />
                  </i>
                  <Link to="/mycourse">My Course</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ProfileTabs pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-5">
              {!personalData.isExUser && personalData.isEnrolledInDirectTraining ?
                <div className="SIdeBar">
                  <Nav defaultActiveKey="/dashboard" className="flex-column">
                    <Nav.Link
                      className={activeTab === "/profile" ? "Active_tab" : ""} as={Link}
                    >
                      <Link to="/profile">My Profile</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/wishlist" ? "Active_tab" : ""}
                    >
                      <Link to="/wishlist">Wishlist</Link>
                    </Nav.Link>
                    {personalData.isEnrolledInDirectTraining
                      &&
                      <Nav.Link
                        className={
                          activeTab === "/training_history" ? "Active_tab" : ""
                        }
                      >
                        <Link to="/training_history">Direct Training Orders</Link>{" "}
                      </Nav.Link>}
                    <Nav.Link className={activeTab === "/" ? "Active_tab" : ""}>
                      {" "}
                      <Link to="/" onClick={handleLogOut}>
                        Log Out
                      </Link>
                    </Nav.Link>
                  </Nav>
                </div> :
                <div className="SIdeBar">
                  <Nav defaultActiveKey="/dashboard" className="flex-column">
                    <Nav.Link
                      className={activeTab === "/dashboard" ? "Active_tab" : ""}
                    >
                      {" "}
                      <Link to="/dashboard">Dashboard</Link>{" "}
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/subscribe" ? "Active_tab" : ""}
                    >
                      <Link to="/subscribe">My Subscription</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/mycourse" ? "Active_tab" : ""}
                    >
                      {" "}
                      <Link to="/mycourse">My Course</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={
                        activeTab === "/orderhistory" ? "Active_tab" : ""
                      }
                    >
                      <Link to="/orderhistory">Order History</Link>{" "}
                    </Nav.Link>
                    {personalData.isEnrolledInDirectTraining
                      &&
                      <Nav.Link
                        className={
                          activeTab === "/training_history" ? "Active_tab" : ""
                        }
                      >
                        <Link to="/training_history">Direct Training Orders</Link>{" "}
                      </Nav.Link>}
                    <Nav.Link
                      className={activeTab === "/wishlist" ? "Active_tab" : ""}
                    >
                      <Link to="/wishlist">Wishlist</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/profile" ? "Active_tab" : ""}
                    >
                      {" "}
                      <Link style={{ width: "100%" }} to="/profile">My Profile</Link>
                    </Nav.Link>
                    <Nav.Link className={activeTab === "/" ? "Active_tab" : ""}>
                      {" "}
                      <Link to="/" onClick={handleLogOut}>
                        Log Out
                      </Link>
                    </Nav.Link>
                  </Nav>
                </div>
              }
            </div>
            <div className="col-lg-9 col-md-8 col-sm-7">
              <div className="row">
                {courseData.length > 0 && courseData ? courseData.map((item, i) => {
                  const percentage = item?.courseCompleted?.split(" ")[0];

                  // Calculate the difference in days between validTill and today
                  const today = new Date();
                  const validTillDate = new Date(item?.validTill);
                  const timeDifference = validTillDate - today;
                  const daysDifference = Math.ceil(
                    timeDifference / (1000 * 60 * 60 * 24)
                  );

                  // Convert courseDuration from seconds to hours and minutes
                  const courseDurationInSeconds = item?.courseDuration;
                  const formattedDuration = formatDuration(
                    courseDurationInSeconds
                  );

                  return (
                    <div
                      className="col-xl-4 col-lg-6 col-md-12 col-sm-12"
                      key={i._id}
                    >
                      <div className="MainCOurSEdIV CouR Comp">
                        <div
                          className="couRSeImg"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => redirectToViewMyCourse(item?._id)}
                        >
                          <img
                            src={item.thumbnail}
                            alt="Hepatology"
                            className="img-fluid oragnsTop categriesImg"
                          />
                        </div>
                        <div className="COurseConTEnt Cour">
                          <h5
                            style={{
                              cursor: "pointer",
                              padding: "0px",
                            }}
                            onClick={() => redirectToViewMyCourse(item?._id)}
                          >
                            {item?.courseName}
                          </h5>
                          <span className="SAPnHr">
                            <hr />
                          </span>
                          <div className="SCetionCourse d-flex CouR">
                            <p>
                              <i>
                                <AiFillStar />
                              </i>{" "}
                              {item.avgRating} ({item.totalRatings})
                              {/* 4.9 (250) */}
                            </p>
                            <h6>{item?.courseLevel}</h6>
                            <h6 className="text-end">
                              ( {formattedDuration} )
                            </h6>
                          </div>
                          <div className="statUS mt-2 mb-2">
                            <div className="d-flex">
                              <h6>Completed</h6>
                              <h6 className="Stastu">
                                {item?.courseCompleted}
                              </h6>
                            </div>
                            <ProgressBar now={parseFloat(percentage)} />
                          </div>
                          Course validity :{" "}
                          <span
                            style={{
                              color: daysDifference < 0 ? "red" : "inherit",
                            }}
                          >
                            {daysDifference < 0
                              ? "Expired"
                              : `${daysDifference} days left`}
                          </span>
                        </div>
                      </div>

                      <div className="ButtONComp d-flex justify-content-between mb-3">
                        <button
                          className="BTNCerti one BTNCertihover"
                          onClick={() => redirectToViewMyCourse(item?._id)}
                          style={{ padding: "0 20px", marginRight: "10px" }}
                        >
                          View Course
                        </button>
                        {item && item?.isCourseCompleted === true ? (
                          <button
                            className="BTNCerti BTNCertihover"
                            onClick={() => downloadcertificate(item?.courseId)}
                          >
                            Download Certificate
                          </button>
                        ) : (
                          <button
                            className="BTNCerti BTNCertihoverDisabled"
                            disabled
                          >
                            Download Certificate
                          </button>
                        )}
                      </div>
                    </div>
                  );
                }) : <div className="SideContEnt sub text-center">
                  <h6 className="mb-5">No course purchased</h6>

                  <Link to="/course">
                    Go to Courses
                  </Link>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Mycourse;
