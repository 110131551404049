import React, { useState } from "react";
import "./Contact.css";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import courseimg from "../../assets/images/coursebanner.png";
import { useDispatch } from "react-redux";
import { ContactUs } from "../../redux/user";
import { toast } from "react-toastify";
import { loader } from "../../redux/common";

const Contact = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSendButton = (e) => {
    e.preventDefault();
    dispatch(loader(true));
    dispatch(
      ContactUs(formData, (res) => {
        if (res.status) {
          toast.success(res.message);
          setFormData({
            name: "",
            email: "",
            mobile: "",
            message: "",
          });
          dispatch(loader(false));
        } else {
          toast.error(res.message);
          dispatch(loader(false));
        }
      })
    );
  };
  return (
    <div className="ConTActpage">
      <div className="PageHEaders">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PageTi pt-5">
                <p>
                  <Link to="/">Home</Link>{" "}
                  <i>
                    <FaAngleRight />
                  </i>
                  <Link to="/contact">Contact</Link>
                </p>
                <h2>Contact Us</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ContactFORm pt-5 pb-3">
        <div className="container">
          <div className="row text-align-center">
            <div className="row col-lg-12 col-md-12 col-sm-12">
              <div className="col-md-6 col-sm-6 ">
                <div className="col-12">
                  <div className="cAll">
                    <h6>Call Us</h6>
                    <h5>
                      <Link to="tel:+919004214077">+91 90042 14077</Link>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 ">
                <div className="col-12">
                  <div className="cAll">
                    <h6>Mail Us</h6>
                    <h5>
                      <Link to="mailto:askus@kareersity.com">
                        askus@kareersity.com
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="cAll COONtactForm">
                <h6>Write US</h6>
                <form onSubmit={handleSendButton}>
                  <div className="row pt-2 justify-content-center">
                    <div className="col-lg-4 col-md-12 col-sm-12">
                      <input
                        className="FORmConT"
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                      <input
                        className="FORmConT"
                        type="email"
                        placeholder="Email Address"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                      <input
                        className="FORmConT"
                        type="text"
                        placeholder="Mobile Number"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-lg-12">
                      <textarea
                        className="FORmConT"
                        rows="5"
                        cols="50"
                        placeholder="Message"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-lg-2">
                      <button type="submit">Send</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* image Section */}
      <div className="IMAgeSession pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 pt-3 pb-3">
              <Link to="/">
                <img src={courseimg} className="img-fluid" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* image section end */}
    </div>
  );
};
export default Contact;
