import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Cart.css";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import courseimg from "../../assets/images/coursebanner.png";
import { loader, isLogin } from "../../redux/common";
import { activePlanListwithBundle } from "../../redux/courses";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const SubCart = () => {
  const dispatch = useDispatch();
  const subscriptionPlanId = useParams();
  const [plansInCart, setPlansInCart] = useState({});
  const [showData, setShowData] = useState();
  const [courseList, setCourseList] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("One Month");
  const [selectedMonthPrice, setSelectedMonthPrice] = useState(0);
  const cartCountval = useSelector((state) => state.apiReducer.cartCount);

  useEffect(() => {
    if (
      localStorage.getItem("isLogin") == null ||
      localStorage.getItem("isLogin") == false
    ) {
      dispatch(isLogin(false));
    } else {
      dispatch(isLogin(true));
    }
    if (!localStorage.getItem("selectedPrice")) {
      setSelectedMonthPrice(plansInCart.oneMonthPrice || 0);
      localStorage.setItem("selectedPrice", plansInCart.oneMonthPrice || 0);
    } else {
      setSelectedMonthPrice(parseFloat(localStorage.getItem("selectedPrice")));
    }
    let data = {};
    data["subscriptionPlanId"] = subscriptionPlanId.id;
    dispatch(loader(true));

    dispatch(
      activePlanListwithBundle(data, (resp) => {
        if (resp.status) {
          localStorage.setItem("selectedPrice", resp.data.oneMonthPrice);
          let defaultPrice = localStorage.getItem("selectedPrice");
          setPlansInCart(resp.data);
          setCourseList(resp.data.courseBundles);
          setShowData(resp.data.courseBundles.length);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, []);

  const handleMonthSelect = (event) => {
    setSelectedMonth(event.target.value);
    let selectedMonthPrice1 = 0;

    switch (event.target.value) {
      case "One Month":
        selectedMonthPrice1 = plansInCart.oneMonthPrice;
        break;
      case "Three Months":
        selectedMonthPrice1 = plansInCart.threeMonthPrice;
        break;
      case "Six Months":
        selectedMonthPrice1 = plansInCart.sixMonthPrice;
        break;
      case "Twelve Months":
        selectedMonthPrice1 = plansInCart.twelveMonthPrice;
        break;
      default:
        selectedMonthPrice1 = 0;
    }

    localStorage.setItem("selectedPrice", selectedMonthPrice1);
    setSelectedMonthPrice(
      event.target.value === "One Month"
        ? plansInCart.oneMonthPrice
        : event.target.value === "Three Months"
        ? plansInCart.threeMonthPrice
        : event.target.value === "Six Months"
        ? plansInCart.sixMonthPrice
        : event.target.value === "Twelve Months"
        ? plansInCart.twelveMonthPrice
        : 0
    );
  };

  return (
    <div className="CArtPAge">
      <div className="CarTBOX pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="CartTablE col-xl-9">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Plan</th>
                    <th>Month</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(plansInCart).length !== 0 ? (
                    <tr>
                      <td className="MainCartBox">
                        <div className="CartBOxMain">
                          <div className="ConteNt">
                            <h4>{plansInCart.planName}</h4>
                          </div>
                        </div>
                      </td>
                      <td>
                        <select
                          value={selectedMonth}
                          onChange={handleMonthSelect}
                        >
                          <option value="One Month">1 Month</option>
                          <option value="Three Months">3 Months</option>
                          <option value="Six Months">6 Months</option>
                          <option value="Twelve Months">12 Months</option>
                        </select>
                      </td>
                      <td>
                        <p>
                          ₹{" "}
                          {selectedMonth === "One Month"
                            ? plansInCart.oneMonthPrice
                            : selectedMonth === "Three Months"
                            ? plansInCart.threeMonthPrice
                            : selectedMonth === "Six Months"
                            ? plansInCart.sixMonthPrice
                            : selectedMonth === "Twelve Months"
                            ? plansInCart.twelveMonthPrice
                            : ""}
                        </p>
                      </td>
                    </tr>
                  ) : (
                    <p>No items in the cart</p>
                  )}
                </tbody>
              </Table>
            </div>
            <div className="CarTBOX pt-5 pb-5">
              <div className="container">
                <div className="row">
                  <div
                    className="col-xl-9 col-lg-9 col-md-12"
                    style={{
                      display: showData == 0 ? "none" : "flex",
                    }}
                  >
                    <div className="CartTablE">
                      <Table striped bordered hover>
                        <thead className="cardhEAD">
                          <tr>
                            <th
                              style={{
                                display: showData == 0 ? "none" : "flex",
                              }}
                            >
                              Courses included in this subscription
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {courseList &&
                            courseList.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className="MainCartBox">
                                    <div className="CartBOxMain">
                                      <div className="row">
                                        <div className="col-lg-3">
                                          <div className="BoXimG">
                                            <img
                                              src={item.thumbnail}
                                              className="img-fluid imGBox"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-9">
                                          <div className="ConteNt">
                                            <h4>{item.title}</h4>
                                            <div className="d-flex contntCART">
                                              <p>{item.level}</p>
                                              <p>English</p>
                                              <p>{item.duration}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-12 pt-5">
                    <div className="row  ">
                      <div className="SidePURBTn d-flex flex-column text-align-center">
                        <button
                          className="BluEBtn"
                          style={{
                            display: showData == 0 ? "none" : "flex",
                          }}
                        >
                          Total{" "}
                          <span>
                            ₹{" "}
                            {selectedMonth === "One Month"
                              ? plansInCart.oneMonthPrice
                              : selectedMonth === "Three Months"
                              ? plansInCart.threeMonthPrice
                              : selectedMonth === "Six Months"
                              ? plansInCart.sixMonthPrice
                              : selectedMonth === "Twelve Months"
                              ? plansInCart.twelveMonthPrice
                              : ""}
                          </span>{" "}
                        </button>
                      </div>
                    </div>
                    <br />
                    <div className="SidePURBTn d-flex flex-column text-align-center">
                      <Link
                        to={`/subcheckout/${subscriptionPlanId.id}`}
                        className="grnBtn text-center"
                        style={{ display: "block" }}
                      >
                        Check Out
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="IMAgeSession pb-5 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 pt-3 pb-3">
              <Link to="/">
                <img src={courseimg} className="img-fluid" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubCart;
