import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { loader } from "../../redux/common";
import { useDispatch } from "react-redux";
import { directTrainingPayment } from "../../redux/subscription";
import { useLocation } from "react-router-dom";
import "./training.css";

const Training = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [installments, setInstallments] = useState(["1"]);
  const [showSubQualification, setShowSubQualification] = useState(false);
  const [showSubQualification1, setShowSubQualification1] = useState(false);
  const [showCompany, setShowCompany] = useState(false);
  const [showExp, setShowExp] = useState(false);

  // useEffect(() => {
  //   const storedData = JSON.parse(sessionStorage.getItem("formData"));
  //   if (storedData) {
  //     reset(storedData);
  //     setInstallments(storedData.selectedInstallments);
  //     setShowCompany(storedData?.designation);
  //   }
  //   window.addEventListener("beforeunload", handleInputChange);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleInputChange);
  //   };
  // }, []);

  const {
    register: register,
    setValue,
    getValues,
    formState: { errors: errors },
    reset: reset,
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur", defaultValues: {} });

  const handleInstallmentChange = (e) => {
    const { name, checked } = e.target;
    const monthNumber = parseInt(name.replace(/\D/g, "")); // Extract the month number from the name

    if (checked) {
      // If the checked month is greater than the last selected month, select all months in between
      const newInstallments = [];
      for (let i = 1; i <= monthNumber; i++) {
        newInstallments.push(`${i}`);
      }
      setInstallments(newInstallments);
    } else {
      // If unchecking a month, remove it from the list of selected installments
      // and also remove larger installments if any
      setInstallments((prevInstallments) =>
        prevInstallments.filter((item) => {
          const itemMonthNumber = parseInt(item);
          return itemMonthNumber < monthNumber;
        })
      );
    }
  };

  const signUpFn = (data) => {
    delete data.sameascurrentaddress;
    delete data.permanentAddress;
    delete data.permanentAddress2;
    const formData = { ...data, selectedInstallments: installments };
    formData.experience = parseInt(formData.experience);

    const requiredFields = [
      "fullName",
      "mobileNumber",
      "email",
      "designation",
      "qualification",
      "role",
      "headQuarter",
      "currentAddress",
      "currentAddress2",
      "city",
      "pincode",
      "state",
      "pcurrentAddress",
      "pcurrentAddress2",
      "pcity",
      "ppincode",
      "pstate",
      "purpose",
      "selectedCentre",
    ];
    const missingFields = requiredFields.filter((field) => !data[field]);

    if (missingFields.length > 0) {
      const errorMessage = `Please fill in the following fields: ${missingFields.join(
        ", "
      )}`;
      toast.error(errorMessage);
      return;
    }

    if (!data.termsAndConditions) {
      toast.error("Please select terms and conditions.");
      return;
    }

    if (installments.length === 0) {
      toast.error("Please select at least one month.");
      return;
    }

    dispatch(loader(true));
    dispatch(
      directTrainingPayment(formData, (result) => {
        dispatch(loader(false));
        if (result.status) {
          navigate(`/training_cart/${result.data.traineeId}`);
          localStorage.setItem("TraineeData", JSON.stringify(result.data));
          toast.success(result.message);
        } else {
          toast.error(result.message);
          dispatch(loader(false));
        }
      })
    );
  };

  const handleInputChange = () => {
    const formData = { ...getValues(), selectedInstallments: installments };
    sessionStorage.setItem("formData", JSON.stringify(formData));
  };

  const getOrdinal = (n) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };

  const getOrdinalSuffix = (n) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0];
  };

  const handleSameAsCurrentAddressChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      const currentAddressValues = getValues([
        "currentAddress",
        "currentAddress2",
        "city",
        "pincode",
        "state",
      ]);
      setValue("pcurrentAddress", currentAddressValues[0]);
      setValue("pcurrentAddress2", currentAddressValues[1]);
      setValue("pcity", currentAddressValues[2]);
      setValue("ppincode", currentAddressValues[3]);
      setValue("pstate", currentAddressValues[4]);
    } else {
      setValue("pcurrentAddress", "");
      setValue("pcurrentAddress2", "");
      setValue("pcity", "");
      setValue("ppincode", "");
      setValue("pstate", "");
    }
  };

  return (
    <div className="EDucaTorPage">
      <div className="job"></div>
      <div className="PageHEaders applyJobHdr pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 applyTitle">
              <h5>Enroll for Direct Training</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="EduCatorFrom pt-5 pb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="eDUcAfo">
                <form onSubmit={handleSubmit(signUpFn)}>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Full Name</label>
                        <input
                          type="text"
                          className="inputFormF"
                          placeholder="Full Name"
                          name="name"
                          id="name"
                          {...register("fullName", {
                            required: "Name is required",
                            pattern: {
                              value: /^[A-Za-z\s]+$/, // Accepts letters and spaces
                              message:
                                "Invalid format. User name accepts only characters and spaces",
                            },
                          })}
                          onChange={handleInputChange}
                        />
                        {errors.fullName && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.fullName.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Email Id</label>
                        <input
                          type="email"
                          className="inputFormF"
                          placeholder="Email Address"
                          name="email"
                          id="email"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value:
                                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
                              message: "Invalid Format",
                            },
                          })}
                          onChange={handleInputChange}
                        />
                        {errors.email && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.email.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Phone Number</label>
                        <input
                          type="number"
                          className="inputFormF"
                          placeholder="Phone Number"
                          name="mobileNumber"
                          id="mobileNumber"
                          {...register("mobileNumber", {
                            required: "Mobile Number is required",
                            pattern: {
                              value:
                                /^(?:\(\d{3}\)\s?|\d{3}[-.\s]?)?\d{3}[-.\s]?\d{4}$/,
                              message:
                                "Invalid Format.Please enter 10 digit Mobile number",
                            },
                          })}
                          onChange={handleInputChange}
                        />
                        {errors.mobileNumber && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.mobileNumber.message}
                          </small>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Qualification</label>
                        <select
                          name="qualification"
                          {...register("qualification", {
                            required: "Qualification is required",
                            pattern: {
                              value: /^(?!.*test).*$/,
                              message: "select one option",
                            },
                          })}
                          onChange={(e) => {
                            handleInputChange(e);
                            setShowSubQualification(
                              e.target.value === "Graduate"
                            );
                            setShowSubQualification1(
                              e.target.value === "Post Graduate"
                            );
                          }}
                        >
                          <option value="test">Select option</option>
                          <option value="Under Graduate">Under Graduate</option>
                          <option value="Graduate">Graduate</option>
                          <option value="Post Graduate">Post Graduate</option>
                        </select>
                        {errors.qualification && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.qualification.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {showSubQualification && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="EduCf">
                          <label>Sub-Qualification</label>
                          <select
                            name="subQualification"
                            {...register("subQualification", {
                              required: "Sub-Qualification is required",
                            })}
                            onChange={handleInputChange}
                          >
                            <option value="">Select sub-qualification</option>
                            <option value="Graduate-Science">Science</option>
                            <option value="Graduate-Non Science">
                              Non Science
                            </option>
                            <option value="Graduate-Pharmacy">Pharmacy</option>
                          </select>
                          {errors.subQualification && (
                            <small style={{ color: "red", float: "left" }}>
                              {errors.subQualification.message}
                            </small>
                          )}
                        </div>
                      </div>
                    )}
                    {showSubQualification1 && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="EduCf">
                          <label>Sub-Qualification</label>
                          <select
                            name="subQualification"
                            {...register("subQualification", {
                              required: "Sub-Qualification is required",
                            })}
                            onChange={handleInputChange}
                          >
                            <option value="">Select sub-qualification</option>
                            <option value="M-Pharma">M-Pharma</option>
                            <option value="MBA">MBA</option>
                            <option value="PGDPM">PGDPM</option>
                          </select>
                          {errors.subQualification && (
                            <small style={{ color: "red", float: "left" }}>
                              {errors.subQualification.message}
                            </small>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Designation</label>
                        <select
                          name="source"
                          {...register("designation", {
                            required: "Designation is required",
                            pattern: {
                              value: /^(?!.*test).*$/,
                              message: "select one option",
                            },
                          })}
                          onChange={(e) => {
                            handleInputChange(e);
                            setShowCompany(e.target.value);
                            setShowExp(e.target.value);
                          }}
                        >
                          <option value="test">Select option</option>
                          <option value="Fresher">Fresher/Job aspirant</option>
                          <option value="MR">MR</option>
                          <option value="ABM">ABM</option>
                          <option value="RBM">RBM</option>
                          <option value="ZBM">ZBM</option>
                          <option value="Others">Others</option>
                        </select>

                        {errors.designation && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.designation.message}
                          </small>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Role</label>

                        <select
                          name="source"
                          {...register("role", {
                            required: "Role is required",
                            pattern: {
                              value: /^(?!.*test).*$/,
                              message: "select one option",
                            },
                          })}
                          onChange={handleInputChange}
                        >
                          <option value="test">Select option</option>
                          <option value="Fresher">Fresher</option>
                          <option value="Level 1 - (MR)">Level 1 - (MR)</option>
                          <option value="Level 2 - (ABM)">
                            Level 2 - (ABM)
                          </option>
                          <option value="Level 3 - (RBM)">
                            Level 3 - (RBM)
                          </option>
                          <option value="Level 4 - (ZBM)">
                            Level 4 - (ZBM)
                          </option>
                          <option value="Level 5 - (NSM)">
                            Level 5 - (NSM)
                          </option>
                        </select>
                        {errors.role && (
                          <p style={{ color: "red" }}> {errors.role.message}</p>
                        )}
                      </div>
                    </div>
                    {showCompany !== "Fresher" && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="EduCf">
                          <label>Company Name</label>
                          <input
                            type="text"
                            className="inputFormF"
                            placeholder="Company Name"
                            name="name"
                            id="name"
                            {...register("companyName", {
                              required: "Company Name is required",
                              pattern: {
                                value: /^[A-Za-z\s]+$/, // Accepts letters and spaces
                                message:
                                  "Invalid format. User name accepts only characters and spaces",
                              },
                            })}
                            onChange={handleInputChange}
                          />
                          {errors.companyName && (
                            <small style={{ color: "red", float: "left" }}>
                              {errors.companyName.message}
                            </small>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="row">
                    {showCompany !== "Fresher" && (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="EduCf">
                          <label>Experience</label>

                          <select
                            name="experience"
                            {...register("experience", {
                              required: "Experience is required",
                              pattern: {
                                value: /^(?!.*test).*$/,
                                message: "select one option",
                              },
                            })}
                            onChange={handleInputChange}
                          >
                            <option value="">Select option</option>
                            {Array.from({ length: 15 }, (_, index) => (
                              <option key={index + 1} value={`${index + 1}`}>
                                {index + 1}
                              </option>
                            ))}
                            {/* {source.map((item) => (
                            <option value={item.value}>{item.label}</option>
                          ))} */}
                          </select>
                          {errors.experience && (
                            <p style={{ color: "red" }}>
                              {errors.experience.message}
                            </p>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Head Quarter/City </label>
                        <input
                          type="text"
                          className="inputFormF"
                          placeholder="Head Quarter"
                          name="lastEmployer"
                          id="lastEmployer"
                          {...register("headQuarter", {
                            required: "Head Quarter is required",
                            // pattern: {
                            //     value: /^[A-Za-z\s]+$/, // Accepts letters and spaces
                            //     message: "Invalid format. User name accepts only characters and spaces",
                            //   },
                          })}
                          onChange={handleInputChange}
                        />
                        {errors.headQuarter && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.headQuarter.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <div className="address-line">
                          <label>Current Address</label>
                          <label>Address Line 1</label>
                        </div>

                        <input
                          style={{ height: "80px" }}
                          type="text"
                          className="inputFormF"
                          placeholder="Address Line 1"
                          name="currentAddress" // Changed name to addressLine1
                          id="currentAddress"
                          {...register("currentAddress", {
                            required: "Current Address is required",
                          })}
                          onChange={handleInputChange}
                        />

                        {errors.currentAddress && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.currentAddress.message}
                          </small>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf ">
                        <label className="height"> Address Line 2</label>
                        <input
                          style={{ height: "80px" }}
                          type="text"
                          className="inputFormF"
                          placeholder="Address Line 2"
                          name="currentAddress2"
                          id="currentAddress2"
                          {...register("currentAddress2", {
                            required: "Address Line 2 is required",
                          })}
                          onChange={handleInputChange}
                        />
                        {errors.currentAddressLine2 && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.currentAddressLine2.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <div className="EduCfff ">
                          <label>City</label>
                          <input
                            type="text"
                            className="inputFormF"
                            placeholder="City name"
                            name="city"
                            id="city"
                            {...register("city", {
                              required: "City is required",
                              pattern: {
                                value: /^[A-Za-z\s]+$/, // Accepts letters and spaces
                                message:
                                  "Invalid format. City name accepts only characters and spaces",
                              },
                            })}
                            onChange={handleInputChange}
                          />
                        </div>

                        {errors.city && (
                          <small className="error-message">
                            {errors.city.message}
                          </small>
                        )}
                      </div>
                      <div className="EduCf">
                        <label>State</label>
                        <select
                          name="state"
                          {...register("state", {
                            required: "State is required",
                          })}
                          onChange={handleInputChange}
                        >
                          <option value="">Select State</option>
                          <option value="Andhra Pradesh">Andhra Pradesh</option>
                          <option value="Arunachal Pradesh">
                            Arunachal Pradesh
                          </option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          <option value="Goa">Goa</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">
                            Himachal Pradesh
                          </option>
                          <option value="Jharkhand">Jharkhand</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Madhya Pradesh">Madhya Pradesh</option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>
                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Odisha">Odisha</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>
                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                          <option value="Uttarakhand">Uttarakhand</option>
                          <option value="West Bengal">West Bengal</option>
                          <option value="Andaman and Nicobar Islands">
                            Andaman and Nicobar Islands
                          </option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Dadra and Nagar Haveli and Daman and Diu">
                            Dadra and Nagar Haveli and Daman and Diu
                          </option>
                          <option value="Delhi">Delhi</option>
                          <option value="Lakshadweep">Lakshadweep</option>
                          <option value="Puducherry">Puducherry</option>
                        </select>
                        {errors.state && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.state.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf ">
                        <label>Pincode</label>
                        <div className="EduCfff ">
                          <input
                            type="text"
                            className="inputFormF"
                            placeholder="Pincode"
                            name="pincode"
                            id="pincode"
                            {...register("pincode", {
                              required: "pincode is required",
                              pattern: {
                                value: /^[0-9]+$/, // Accepts letters and spaces
                                message:
                                  "Invalid format. City name accepts only characters and spaces",
                              },
                            })}
                            onChange={handleInputChange}
                          />
                        </div>

                        {errors.pincode && (
                          <small className="error-message">
                            {errors.pincode.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="sameascurrentaddress">
                      <input
                        type="checkbox"
                        id="sameascurrentaddress"
                        name="sameascurrentaddress"
                        {...register("sameascurrentaddress")}
                        onChange={handleSameAsCurrentAddressChange}
                      />

                      <label
                        style={{
                          marginLeft: "5px",
                          marginTop: "10px",
                          marginBottom: "5px",
                        }}
                      >
                        Same as current Address
                      </label>
                      {errors.termsAndConditions && (
                        <p style={{ color: "red" }}>
                          {errors.termsAndConditions.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <div className="address-line">
                          <label>Permanent Address</label>
                          <label>Address Line 1</label>
                        </div>
                        <input
                          style={{ height: "80px" }}
                          type="text"
                          className="inputFormF"
                          placeholder="Address Line 1"
                          name="currentAddress" // Changed name to addressLine1
                          id="currentAddress"
                          {...register("pcurrentAddress", {
                            required: "Current Address is required",
                          })}
                          onChange={handleInputChange}
                        />
                        {errors.permanentAddress && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.permanentAddress.message}
                          </small>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf ">
                        <label className="height">Address Line 2</label>
                        <input
                          style={{ height: "80px" }}
                          type="text"
                          className="inputFormF"
                          placeholder="Address Line 2"
                          name="currentAddress2"
                          id="currentAddress2"
                          {...register("pcurrentAddress2", {
                            required: "Address Line 2 is required",
                          })}
                          onChange={handleInputChange}
                        />
                        {errors.permanentAddressLine2 && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.permanentAddressLine2.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <div className="Educfff">
                          <label>City</label>
                          <input
                            type="text"
                            className="inputFormF"
                            placeholder="City name"
                            name="city"
                            id="city"
                            {...register("pcity", {
                              required: "City is required",
                              pattern: {
                                value: /^[A-Za-z\s]+$/, // Accepts letters and spaces
                                message:
                                  "Invalid format. City name accepts only characters and spaces",
                              },
                            })}
                            onChange={handleInputChange}
                          />
                          {errors.city && (
                            <small className="error-message">
                              {errors.city.message}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="EduCf">
                        <label>State</label>
                        <select
                          name="state"
                          {...register("pstate", {
                            required: "State is required",
                          })}
                          onChange={handleInputChange}
                        >
                          <option value="">Select State</option>
                          <option value="Andhra Pradesh">Andhra Pradesh</option>
                          <option value="Arunachal Pradesh">
                            Arunachal Pradesh
                          </option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          <option value="Goa">Goa</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">
                            Himachal Pradesh
                          </option>
                          <option value="Jharkhand">Jharkhand</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Madhya Pradesh">Madhya Pradesh</option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>
                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Odisha">Odisha</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>
                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                          <option value="Uttarakhand">Uttarakhand</option>
                          <option value="West Bengal">West Bengal</option>
                          <option value="Andaman and Nicobar Islands">
                            Andaman and Nicobar Islands
                          </option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Dadra and Nagar Haveli and Daman and Diu">
                            Dadra and Nagar Haveli and Daman and Diu
                          </option>
                          <option value="Delhi">Delhi</option>
                          <option value="Lakshadweep">Lakshadweep</option>
                          <option value="Puducherry">Puducherry</option>
                        </select>
                        {errors.state && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.state.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Pincode</label>
                        <input
                          type="text"
                          className="inputFormF"
                          placeholder="Pincode"
                          name="pincode"
                          id="pincode"
                          {...register("ppincode", {
                            required: "pincode is required",
                            pattern: {
                              value: /^[0-9]+$/, // Accepts letters and spaces
                              message:
                                "Invalid format. City name accepts only characters and spaces",
                            },
                          })}
                          onChange={handleInputChange}
                        />
                        {errors.pincode && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.pincode.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Purpose</label>

                        <select
                          name="purpose"
                          {...register("purpose", {
                            required: "Purpose is required",
                            pattern: {
                              value: /^(?!.*test).*$/,
                              message: "select one option",
                            },
                          })}
                          onChange={handleInputChange}
                        >
                          <option value="test">Select option</option>
                          <option value="Knowledge Upgrade">
                            Knowledge Upgrade
                          </option>
                          <option value="Promotion">Promotion</option>
                          <option value="Choosing New Career Path">
                            Choosing New Career Path
                          </option>
                        </select>
                        {errors.purpose && (
                          <p style={{ color: "red" }}>
                            {errors.purpose.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="EduCf">
                        <label>Choose Center</label>

                        <select
                          name="choose_center"
                          {...register("selectedCentre", {
                            required: "Choose Center is required",
                            pattern: {
                              value: /^(?!.*test).*$/,
                              message: "select one option",
                            },
                          })}
                        >
                          <option value="test">Select option</option>
                          {/* <option value="chennai">Chennai</option>
                          <option value="bangalore">Bangalore</option>
                          <option value="pune">Pune</option>
                          <option value="mumbai">Mumbai</option> */}
                          <option value="KOTA">KOTA</option>
                        </select>
                        {errors.selectedCentre && (
                          <p style={{ color: "red" }}>
                            {" "}
                            {errors.selectedCentre.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="Install">
                      <div className="EduCf col-2" style={{ margin: "0px" }}>
                        <label>Installments</label>
                      </div>
                      <div className="InstallInput">
                        {[1, 2, 3, 4, 5, 6].map((month, index) => (
                          <div
                            key={month}
                            className={month === 1 ? "disabled-checkbox" : ""}
                          >
                            <input
                              type="checkbox"
                              id={`installment${month}`}
                              name={`${month}${getOrdinalSuffix(month)}`}
                              onChange={handleInstallmentChange}
                              disabled={month === 1} // Disable the checkbox for the first month
                              checked={installments.includes(`${month}`)}
                            />
                            <label
                              htmlFor={`installment${month}`}
                            >{`${month}${getOrdinalSuffix(
                              month
                            )} Month`}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="termsCndt">
                      <input
                        type="checkbox"
                        id="termsAndConditions"
                        name="termsAndConditions"
                        {...register("termsAndConditions")}
                        onChange={handleInputChange}
                      />

                      <label style={{ textDecoration: "underline" }}>
                        I Agree to the Terms and Conditions
                      </label>
                      {errors.termsAndConditions && (
                        <p style={{ color: "red" }}>
                          {" "}
                          {errors.termsAndConditions.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <div className="SIGBtn">
                        <div className="EduCf payMEnt">
                          <button type="submit">Proceed To Pay</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Training;
