import React, { useState, useEffect } from 'react';
import './Blog.css';

import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import blog from "../../assets/images/blog.png"

import { useParams } from "react-router-dom";
import { blogViewCb, blogListCb, activeBlogListCb } from "../../redux/user";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";


const Blogdetail = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [blogData, setBlogData] = useState([]);
    const [allBlogData, setAllBlogData] = useState([]);
    const [flag, setFlag] = useState(true);


    const navigate = useNavigate();

    useEffect(() => {
        console.log(id, "id")
        var data = {
            "blogId": id
        }
        dispatch(blogViewCb(data, (resp) => {
            if (resp.status) {
                setBlogData([resp.data])
            }
        }))
        dispatch(activeBlogListCb((resp) => {
            if (resp.status) {
                setAllBlogData(resp.data)
            }
        }))

    }, [flag])
    return (
        <div className='BlogDEtails'>

            <div className='PageHEaders'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='PageTi pt-5'>
                                <p><Link to="/">Home</Link> <i><FaAngleRight /></i> <Link to="/blog">Blogs</Link><i> <FaAngleRight /></i> <Link to="/blog">{blogData[0]?.title}</Link></p>
                                <h2>Blogs</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='blogdeTialContEnt pt-5 pb-5'>
                <div className='container'>
                    <div className='row'>

                        {blogData
                            .map((filBlog) => {
                                return (
                                    <>
                                        <div className='col-lg-7 col-md-8 col-sm-12'>
                                            <div className='blogIMG'>
                                                <img src={filBlog?.thumbnail} className='img-fluid' />
                                            </div>
                                            <div className='BLogContent blogDetail pt-3'>
                                                {/* <h6 className='pb-3'>{new Date(filBlog?.updatedAt).toLocaleString()}</h6> */}
                                                <h6>
                                                {new Date(filBlog?.updatedAt).toLocaleDateString('en-US', {
                                                                day: 'numeric',
                                                                month: 'long',
                                                                year: 'numeric'
                                                            })}
                                                </h6>
                                                <h4>{filBlog?.title}</h4>
                                                {/* <p>{filBlog?.sDesc}</p> */}
                                                <p dangerouslySetInnerHTML={{ __html: filBlog?.dDesc }}></p>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}

                        <div className='col-lg-5 col-md-4 col-sm-12'>
                            <h4>Related Blog</h4>
                            {allBlogData.filter((item, i) => item.isActive === true && item._id != id)
                                .filter((items, i) => i == 0 || i == 1 || i == 2)
                                .map((filBlog) => {

                                    return (
                                        <>
                                            <div className='row pt-3' onClick={() => { navigate("/blogdetail/" + filBlog?._id); setFlag(!flag) ;  window.scrollTo(0, 0)}}>
                                                <div className='col-lg-5'>
                                                    <div className='blogIMG leftside'>
                                                        <img src={filBlog?.thumbnail} className='img-fluid' />
                                                    </div>
                                                </div>
                                                <div className='col-lg-7 pt-2'>
                                                    <div className='BLogContent detail'>
                                                        {/* <h6>{new Date(filBlog?.updatedAt).toLocaleString()}</h6> */}
                                                        <h6>
                                                            {new Date(filBlog?.updatedAt).toLocaleDateString('en-US', {
                                                                day: 'numeric',
                                                                month: 'long',
                                                                year: 'numeric'
                                                            })}
                                                        </h6>
                                                        <h5><Link>{filBlog?.title}</Link></h5>
                                                        <p>{filBlog?.sDesc}</p>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>
                                        </>
                                    )
                                })}

                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}
export default Blogdetail