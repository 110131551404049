import React, { useEffect, useState } from "react";
import "./Profile.css";

import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import Nav from "react-bootstrap/Nav";
import c1 from "../../assets/images/course.png";
import { AiFillStar, AiOutlineShoppingCart } from "react-icons/ai";
import { HiChevronDoubleRight } from "react-icons/hi";
import { isLogin, cartCount, loader } from "../../redux/common";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import {
  addOrRemoveFromWishList,
  addToCart,
  listOfItemsInWishlist,
} from "../../redux/courses";
import { BsFillSuitHeartFill, BsSuitHeart } from "react-icons/bs";
import { userAcademicDetail, userPersonalDetail } from "../../redux/profile";

const Wishlist = () => {
  const cartCountval = useSelector((state) => state.apiReducer.cartCount);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [itemsInWishlist, setItemsInWishlist] = useState([]);
  const [refresher, setRefresher] = useState(true);
  const [academicData, setAcademicData] = useState({})
  const [existingUser, setExistingUser] = useState({});
  const [personalData, setPersonalData] = useState({})


  useEffect(() => {
    dispatch(
      listOfItemsInWishlist((resp) => {
        if (resp.status) {
          setItemsInWishlist(resp.data);
        } else {
          toast.error(resp.message);
        }
      })
    );
    dispatch(
      userAcademicDetail((resp) => {
        console.log(resp.data)
        dispatch(loader(true))
        if (resp.status) {
          console.log(resp.data)
          setAcademicData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
    dispatch(
      userPersonalDetail((resp) => {
        if (resp.status) {
          setPersonalData(resp.data)
          setExistingUser(resp.data.isExUser);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [refresher]);
  console.log(academicData)
  const addCart = (id, index) => {
    let data = {};
    data["courseId"] = id;

    dispatch(
      addToCart(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          setRefresher(!refresher);
          navigate("/cart");
          dispatch(cartCount(!cartCountval));
        } else {
          toast.error(response.message);
        }
      })
    );
  };
  function convertExpiryToDate(expiryTimestamp) {
    const expiryDate = new Date(expiryTimestamp);
    return expiryDate;
  }

  function isDiscountValid(discountedPriceExpiryTimestamp) {
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime < discountedPriceExpiryTimestamp;
  }

  function isCourseFree(item) {
    const currentTime = Math.floor(Date.now() / 1000);
    const userCreationDate = localStorage.getItem("UserCreatedDate");
    const expiryDate = convertExpiryToDate(userCreationDate);
    const collegeId = academicData?.collegeId

    const thirtyDaysInSeconds = 30 * 24 * 60 * 60; // 30 days in seconds

    if (item.freeForEveryone) {
      return true; // Course is free for everyone
    } else if (
      item.freeForEnInLast30Days &&
      currentTime - expiryDate.getTime() <= thirtyDaysInSeconds
    ) {
      return true; // Course is free for users who registered in the last 30 days
    } else if (
      academicData &&
      item.freeForbasedOnColleges && item.freeColleges &&
      item.freeColleges.includes(collegeId)
    ) {
      return true; // Course is free for users based on their college
    } else {
      return false; // Course is not free and discount has expired or not applicable
    }
  }
  const addWishlist = (id, index) => {
    let data = {};
    data["courseId"] = id;

    dispatch(
      addOrRemoveFromWishList(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          setRefresher(!refresher);
          navigate("/wishlist");
          dispatch(cartCount(!cartCountval));
          // You may choose to update the refresher or other state here if needed
        } else {
          toast.error(response.message);
        }
      })
    );
  };

  setInterval(() => {
    autoLogOut();
  }, 1000);

  const autoLogOut = () => {
    if (localStorage.getItem("oAuth")) {
      const jwt_Token_decoded = jwt_decode(localStorage.getItem("oAuth"));
      if (jwt_Token_decoded.exp * 1000 < Date.now()) {
        localStorage.removeItem("oAuth");
        localStorage.clear();
        navigate("/");
        dispatch(isLogin(false));
        toast.error("Session expired.Please login to proceed");
      }
    }
  };
  const handleLogOut = () => {
    localStorage.removeItem("oAuth");
    localStorage.removeItem("isLogin");
    localStorage.removeItem("UserCreatedDate");
    localStorage.removeItem("collegeId");
    navigate("/");
    dispatch(isLogin(false));
    toast.success("You have logged out successfully");
  };

  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    // Get the current URL path
    const currentPath = window.location.pathname;
    setActiveTab(currentPath);
  }, []);
  console.log(personalData)

  return (
    <div className="ProFILE">
      <div className="PageHEaders">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="PageTi pt-5">
                <h2>Wishlist</h2>
                <p>
                  <Link to="/">Home</Link>{" "}
                  <i>
                    <FaAngleRight />
                  </i>
                  <Link to="/wishlist">Wishlist</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ProfileTabs pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-12">
              {!personalData.isExUser && personalData.isEnrolledInDirectTraining ?
                <div className="SIdeBar">
                  <Nav defaultActiveKey="/dashboard" className="flex-column">
                    <Nav.Link
                      className={activeTab === "/profile" ? "Active_tab" : ""} as={Link}
                    >
                      <Link to="/profile">My Profile</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/wishlist" ? "Active_tab" : ""}
                    >
                      <Link to="/wishlist">Wishlist</Link>
                    </Nav.Link>
                    {personalData.isEnrolledInDirectTraining
                      &&
                      <Nav.Link
                        className={
                          activeTab === "/training_history" ? "Active_tab" : ""
                        }
                      >
                        <Link to="/training_history">Direct Training Orders</Link>{" "}
                      </Nav.Link>}
                    <Nav.Link className={activeTab === "/" ? "Active_tab" : ""}>
                      {" "}
                      <Link to="/" onClick={handleLogOut}>
                        Log Out
                      </Link>
                    </Nav.Link>
                  </Nav>
                </div> :
                <div className="SIdeBar">
                  <Nav defaultActiveKey="/dashboard" className="flex-column">
                    <Nav.Link
                      className={activeTab === "/dashboard" ? "Active_tab" : ""}
                    >
                      {" "}
                      <Link to="/dashboard">Dashboard</Link>{" "}
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/subscribe" ? "Active_tab" : ""}
                    >
                      <Link to="/subscribe">My Subscription</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/mycourse" ? "Active_tab" : ""}
                    >
                      {" "}
                      <Link to="/mycourse">My Course</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={
                        activeTab === "/orderhistory" ? "Active_tab" : ""
                      }
                    >
                      <Link to="/orderhistory">Order History</Link>{" "}
                    </Nav.Link>
                    {personalData.isEnrolledInDirectTraining
                      &&
                      <Nav.Link
                        className={
                          activeTab === "/training_history" ? "Active_tab" : ""
                        }
                      >
                        <Link to="/training_history">Direct Training Orders</Link>{" "}
                      </Nav.Link>}
                    <Nav.Link
                      className={activeTab === "/wishlist" ? "Active_tab" : ""}
                    >
                      <Link to="/wishlist">Wishlist</Link>
                    </Nav.Link>
                    <Nav.Link
                      className={activeTab === "/profile" ? "Active_tab" : ""}
                    >
                      {" "}
                      <Link style={{ width: "100%" }} to="/profile">My Profile</Link>
                    </Nav.Link>
                    <Nav.Link className={activeTab === "/" ? "Active_tab" : ""}>
                      {" "}
                      <Link to="/" onClick={handleLogOut}>
                        Log Out
                      </Link>
                    </Nav.Link>
                  </Nav>
                </div>
              }
            </div>
            <div className="col-lg-9 col-md-8 col-sm-12">
              <div className="row">
                {itemsInWishlist.length > 0 ? itemsInWishlist?.map((item, index) => {
                  return (
                    <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                      <div className="MainCOurSEdIV CouR wishLst">
                        <div className="couRSeImg wish">
                          <img
                            src={item?.thumbnail}
                            alt="Hepatology"
                            className="img-fluid oragnsTop categriesImg"
                          />
                        </div>
                        <div className="COurseConTEnt Cour crse">
                          <h5> <Link
                                    to={`/course-detail/${item._id
                                      }/${item.title.replace(/ /g, "_")}`}
                                  >
                                    {item?.title}
                                  </Link></h5>
                          <span className="SAPnHr">
                            <hr />
                          </span>
                          <div className="SCetionCourse d-flex CouR WISh">
                            <div className="RightCLcont d-flex crs striked">
                              {isCourseFree(item) ? (
                                <h4>FREE</h4>
                              ) : (
                                <>
                                  {item.discountedPrice > 0 &&
                                    isDiscountValid(
                                      item.discountedPriceExpiry
                                    ) ? (
                                    <>
                                      <div>
                                        <h4>₹{item.discountedPrice}</h4>
                                      </div>
                                      <div>
                                        <h5>
                                          <s>₹{item.price}</s>
                                        </h5>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div>
                                        <h4>₹{item.regularPrice}</h4>
                                      </div>
                                      <div>
                                        <h5>
                                          <s>₹{item.price}</s>
                                        </h5>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                            <h6>{item?.level}</h6>
                            <h6 className="text-end">{item?.duration}</h6>
                          </div>
                          <span className="SAPnHr">
                            <hr />
                          </span>
                          <div className="FinAlDIv d-flex CouR wIsHlST">
                            <p>
                              <i>
                                <AiFillStar />
                              </i>{" "}
                              4.9 (250)
                            </p>
                            <Link to={`/course-detail/${item._id
                              }/${item.title.replace(/ /g, "_")}`}>
                              Learn More{" "}
                              <i>
                                <HiChevronDoubleRight />
                              </i>
                            </Link>
                          </div>
                          <span className="SAPnHr">
                            <hr />
                          </span>

                          <div className="FinAlDIv d-flex CouR SAP wishLIST">
                            <p>By {item?.educators?.name}</p>
                            <Link
                              to="/"
                              className="ADdcaRT"
                              onClick={() => addCart(item._id, index)}
                            >
                              {" "}
                              <i className="ICONS pt-2">
                                <AiOutlineShoppingCart />
                              </i>{" "}
                              Add To Cart{" "}
                            </Link>
                            <Link
                              to=""
                              onClick={() => addWishlist(item._id, index)}
                              style={{
                                backgroundColor: "#107B38",
                                display: "inline-block",
                                padding: "5px 10px",
                                borderRadius: "5px",
                                textDecoration: "none",
                                color: "#fff !important",
                              }}
                            >
                              {" "}
                              <i>
                                <BsSuitHeart
                                  style={{ fill: "#fff ", marginTop: " 5px" }}
                                />
                              </i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }) : <div className="SideContEnt sub text-center">
                  <h6 className="mb-5">0 Items are in wishlist</h6>

                  <Link to="/course">
                    Go to Courses
                    </Link>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Wishlist;
